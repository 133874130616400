import {configureStore} from '@reduxjs/toolkit';
import {serverApi} from 'api';
import auth from './slices/auth';
import miniSite from './slices/miniSite';
import homePageLayout from './slices/homePageLayout';

export const store = configureStore({
  reducer: {
    [serverApi.reducerPath]: serverApi.reducer,
    auth,
    miniSite,
    homePageLayout,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(serverApi.middleware),
});

export default store;
