import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Skeleton,
  TableFooter,
  TablePagination,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import {useGetCompanyProductsQuery} from 'api';
import EditProductIcon from './EditProductIcon';
import CreateProductModal from './CreateProductModal';
import DeleteProductModal from './DeleteProductIcon';

export function CompanyProducts() {
  const {id} = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const {data, isFetching, isError, isSuccess} = useGetCompanyProductsQuery(id);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="Companies Table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>Products</TableCell>
              <TableCell align="right">
                <CreateProductModal />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetching &&
              isSuccess &&
              Boolean(data?.data?.length) &&
              data?.data?.map(row => (
                <TableRow key={row._id}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{cursor: 'pointer'}}
                    onClick={() => {
                      navigator.clipboard.writeText(row._id);
                      enqueueSnackbar('Copied To Clipboard');
                    }}
                  >
                    {row._id}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.category.name}</TableCell>
                  <TableCell align="center">
                    {row?.priceRange?.[0]?.price || row.price}({row.currencyDetails?.title})
                  </TableCell>
                  <TableCell align="center">
                    {row.images?.length ? (
                      <Box
                        component="img"
                        sx={{width: 40, height: 40, objectFit: 'contain'}}
                        src={`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${row.images[0]}/public`}
                        alt={row.name}
                      />
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <EditProductIcon product={row} />
                    <DeleteProductModal productId={row._id} />
                  </TableCell>
                </TableRow>
              ))}
            {Boolean(isSuccess && !data?.data?.length) && (
              <TableRow>
                <TableCell colSpan={7}>No Product Found</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography color="error">Failed To Fetch Products.</Typography>
                </TableCell>
              </TableRow>
            )}
            {isFetching && (
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={100}
                page={0}
                onPageChange={() => {
                  // console.log('onPageChange', rest);
                }}
                rowsPerPage={10}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default CompanyProducts;
