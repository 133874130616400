import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {Dialog, DialogTitle, DialogContent} from '@mui/material';
import {getImageURL} from '../../../utils/imageUrl';

export default function ViewerModel({open, onClose, image, alt}) {
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        {alt}
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardMedia component="img" height="100%" image={getImageURL(image)} alt="green iguana" />
        </Card>
      </DialogContent>
    </Dialog>
  );
}
