import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplaceFeaturedCategoryMutation, useGetCategoriesQuery} from 'api';
import FeaturedCateForm from './FeaturedCateForm';

function CategoryEditIconButton({open, onClose, category}) {
  const {data: categories} = useGetCategoriesQuery();
  const [replaceCategory] = useReplaceFeaturedCategoryMutation();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle>Update Category</DialogTitle>
        <DialogContent dividers>
          <FeaturedCateForm
            categories={categories}
            initialValues={{
              _id: category._id,
              categoryId: category.categoryId,
              name: category.name,
              icon: category.icon,
              productsIds: category.productsIds,
              banners: category.banners,
              isActive: category.isActive,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const newObj = {
                  ...values,
                };
                await replaceCategory(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('Category updated successfully', {
                  variant: 'success',
                });
                onClose();
              } catch (e) {
                const message = e.data.status;
                enqueueSnackbar(message || 'Failed to update Category', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CategoryEditIconButton;
