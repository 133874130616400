import {GridActionsCellItem} from '@mui/x-data-grid';
import React from 'react';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ProductsViewModal from './ProductsViewModal';

function ViewProductCellItem({row}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    <>
      <ProductsViewModal
        productsList={row}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
      />
      <GridActionsCellItem
        icon={<ImageSearchIcon color="success" />}
        onClick={() => {
          setIsModalOpen(true);
        }}
        label="Update"
      />
    </>
  );
}

export default ViewProductCellItem;
