import {Stack, Typography} from '@mui/material';
import React from 'react';
import {useGetFeaturedCategoriesQuery} from 'api';
import PaperDataGrid from 'components/DataGrid/PaperDataGrid';
import EditCategoryCellItem from './EditCategoryCellItem';
import DeleteCategoryCellItem from './DeleteCategoryCellItem';
import CreateCategoryButton from './CreateCategoryButton';
import SearchBox from './SearchBox';
import ViewProductCellItem from './ViewProductCellItem';
import ViewBannersCellItem from './ViewBannersCellItem';

function CategoriesTable({addButton = true, isActive = true}) {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [rowCount, setRowCount] = React.useState(0);
  const {
    data: categoriesList,
    isLoading,
    isFetching,
    isError,
  } = useGetFeaturedCategoriesQuery({page, limit, search, isActive});

  React.useEffect(() => {
    if (categoriesList?.totalCount) {
      setRowCount(categoriesList?.totalCount);
    }
  }, [categoriesList?.totalCount]);

  const onPageChange = React.useCallback(
    newPage => {
      setPage(newPage);
    },
    [setPage]
  );

  const onLimitChange = React.useCallback(
    newLimit => {
      setLimit(newLimit);
    },
    [setLimit]
  );

  const getRowActions = React.useCallback(row => [
    <EditCategoryCellItem row={row} key="edit" />,
    <DeleteCategoryCellItem row={row} key="delete" />,
    <ViewProductCellItem key="products" row={row} />,
    <ViewBannersCellItem key="banners" row={row} />,
  ]);

  const categoriesTableColumns = React.useMemo(
    () => [
      {field: '_id', headerName: 'ID', flex: 1, minWidth: 150, maxWidth: 300},
      {field: 'name', headerName: 'Name', flex: 1, minWidth: 250},
      {
        field: 'actions',
        type: 'actions',
        getActions: ({row}) => getRowActions(row),
        headerName: 'Actions',
        minWidth: 200,
      },
    ],
    [getRowActions]
  );

  const noData = categoriesList?.totalCount === 0 || categoriesList?.data === 404;

  if (isError) {
    return (
      <Typography
        sx={{
          textAlign: 'center',
          color: theme => theme.palette.error.main,
        }}
        variant="body1"
      >
        Error loading categories
      </Typography>
    );
  }

  const onSearchChange = React.useCallback(newSearch => {
    setSearch(newSearch);
  });

  return (
    <>
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          'justify-content': 'space-between',
        }}
        pb={1}
      >
        <SearchBox value={search} onSearchChange={onSearchChange} />
        {addButton && <CreateCategoryButton />}
      </Stack>
      <PaperDataGrid
        pagination
        paginationMode="server"
        page={page}
        onPageChange={onPageChange}
        pageSize={limit}
        onPageSizeChange={onLimitChange}
        rowCount={noData ? 0 : rowCount}
        loading={isLoading || isFetching}
        disableSelectionOnClick
        getRowId={row => row._id}
        rowsPerPageOptions={[10, 20, 50, 100]}
        columns={categoriesTableColumns}
        rows={categoriesList?.data.length ? categoriesList?.data : []}
        autoHeight
        sx={{
          '& .MuiToolbar-root p': {
            mb: 0,
          },
        }}
      />
    </>
  );
}

export default CategoriesTable;
