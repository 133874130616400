import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {getImageURL} from 'utils/imageUrl';

export default function ProductsModal({imagesList = [], selectedId, onClick}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Select Banner
      </Button>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle>Select Image</DialogTitle>
        <DialogContent>
          <Box>
            <ImageList variant="masonry" cols={3} gap={8}>
              {imagesList?.map(item => (
                <ImageListItem
                  sx={{
                    border: selectedId === item._id && '2px solid blue',
                  }}
                  key={getImageURL(item?.image)}
                  onClick={() => {
                    onClick(item);
                  }}
                >
                  <img
                    src={`${getImageURL(item?.image)}?w=248&fit=crop&auto=format`}
                    srcSet={`${getImageURL(item?.image)}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item?.alt}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
