import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useGetCategoriesQuery, useAddFeaturedCategoryMutation} from 'api';
import FeaturedCateForm from './FeaturedCateForm';

function CreateCategoryModal({open, onClose}) {
  const {data: categories, isLoading, isError} = useGetCategoriesQuery();
  const formRef = React.useRef();
  const [addCategory] = useAddFeaturedCategoryMutation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle variant="h6">Create Category</DialogTitle>
      <DialogContent>
        <Divider />
        <FeaturedCateForm
          categories={categories}
          disabled={isLoading || isError}
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const newObj = {
                ...values,
              };
              await addCategory(newObj).unwrap();
              actions.resetForm();
              enqueueSnackbar('Category created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message = e?.data?.status;
              enqueueSnackbar(message || 'Failed to create Category', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCategoryModal;
