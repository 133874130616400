import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import {useReplaceMiniSiteCategoryMutation, useGetCompanyProductsQuery} from 'api';
import MiniSiteCategoryForm from './MiniSiteCategoryForm';

function MiniSiteCategoryEditIconButton({MiniSiteCategory}) {
  const {id} = useParams();
  const {data} = useGetCompanyProductsQuery(id);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceMiniSiteCategory] = useReplaceMiniSiteCategoryMutation();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isModalOpen}>
        <DialogTitle>Update Category</DialogTitle>
        <DialogContent dividers>
          <MiniSiteCategoryForm
            initialValues={{
              _id: MiniSiteCategory._id,
              title: MiniSiteCategory.title,
              productsIds: MiniSiteCategory.productsIds.map(product => product.productId),
            }}
            products={data?.data}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const newObj = {
                  ...values,
                  companyId: id,
                };
                await replaceMiniSiteCategory(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('MiniSiteCategory updated successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.MiniSiteCategoryResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to update MiniSiteCategory', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default MiniSiteCategoryEditIconButton;
