import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {useSnackbar} from 'notistack';
import {useAddOrganizationMutation, useGetOrganizationsQuery} from 'api';
import OrganizationForm from './OrganizationForm';

function CreateOrganizationModal({open, onClose}) {
  const {isLoading, isError} = useGetOrganizationsQuery();
  const formRef = React.useRef();
  const [addOrganization] = useAddOrganizationMutation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        Create Organization
      </DialogTitle>
      <DialogContent>
        <OrganizationForm
          disabled={isLoading || isError}
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const newObj = {
                ...values,
              };
              await addOrganization(newObj).unwrap();
              actions.resetForm();
              enqueueSnackbar('Organization created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message =
                e.status === 400 ? e.organizationResponse?.details?.[0]?.message : undefined;
              enqueueSnackbar(message || 'Failed to create Organization', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateOrganizationModal;
