import React from 'react';
import {useDropzone} from 'react-dropzone';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {Typography, styled, Stack, Box, IconButton} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {ReactSortable} from 'react-sortablejs';

const Dropzone = styled('div')({
  border: ' 2px dashed rgb(219, 219, 219)',
  padding: '1rem',
  transition: 'background-color 0.3s',
  cursor: 'pointer',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'rgb(243, 243, 243)',
  },
});

export default function DropZoneMultiple({
  handleChangeSort,
  onDropAccepted,
  previewSrc,
  onDropRejected,
  disabled = false,
  delImage,
  setSortImageArray,
}) {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    maxSize: 5 * 1024 * 1024, // in bytes
    multiple: true,
    onDropAccepted: files => {
      if (onDropAccepted) {
        onDropAccepted(files);
      }
    },
    onDropRejected: files => {
      if (onDropRejected) {
        onDropRejected(files[0].file, files[0].errors);
      }
    },
    disabled,
  });
  return (
    <Stack direction="column" spacing={1}>
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography sx={{mb: 0}} align="center" paragraph>
          <AddAPhotoIcon />
        </Typography>
        {isDragActive ? (
          <Typography align="center" paragraph>
            Drop the icon here ...
          </Typography>
        ) : (
          <Typography align="center" paragraph>
            Drag n Drop the image here, or click to select.
          </Typography>
        )}
      </Dropzone>

      <Stack
        sx={{
          '& > *': {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
          },
        }}
      >
        <ReactSortable
          list={JSON.parse(JSON.stringify([...previewSrc]))}
          setList={newOrder =>
            handleChangeSort ? handleChangeSort(newOrder) : setSortImageArray(newOrder)
          }
        >
          {previewSrc?.length > 0 && (
            <>
              {previewSrc?.map((e, index) => (
                <Box
                  key={`${index + 1}`}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      padding: '4px',
                      width: 100,
                      height: 100,
                      objectFit: 'cover',
                    }}
                    component="img"
                    src={e.preview || e}
                    alt="thumbnail"
                  />
                  <Box>
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      }}
                      color="error"
                      aria-label="remove image"
                      onClick={() => delImage(e)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </>
          )}
        </ReactSortable>
      </Stack>
    </Stack>
  );
}
