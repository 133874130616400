import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    removeAccessToken: state => {
      state.accessToken = null;
    },
  },
});

export const {updateAccessToken, removeAccessToken} = authSlice.actions;

export default authSlice.reducer;
