import React from 'react';
import {Box, MenuItem, MenuList, Paper, Grid} from '@mui/material';
// import NavLink from 'components/Link/NavLink';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from 'components/Link/Link';
import NestedMenuItem from 'components/Menu/NestedMenuItem';

function InnerLayout({children, links, backLink}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <MenuList sx={{width: '100%'}} component={Paper}>
          {backLink && (
            <MenuItem
              component={Link}
              sx={{
                typography: 'subtitle2',
                mb: 2,
                fontWeight: 'bold',
              }}
              to={backLink.to}
            >
              <ArrowBackIcon sx={{mr: 1}} />
              {backLink.title}
            </MenuItem>
          )}
          {links.map(({to, title, subLinks}) => (
            <NestedMenuItem key={to} to={to} title={title} subLinks={subLinks} />
          ))}
        </MenuList>
      </Grid>
      <Grid item xs={12} md={9}>
        <Box>{children}</Box>
      </Grid>
    </Grid>
  );
}
export default InnerLayout;
