import {FormControl, InputLabel, Select, MenuItem, Stack, Button, Skeleton} from '@mui/material';
import React from 'react';

function Type({handleClick, typeValue, buttonClick, isLoading}) {
  return (
    <>
      {isLoading ? (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
          }}
        >
          <Skeleton
            variant="h1"
            sx={{
              minWidth: '15%',
              maxWidth: '20%',
            }}
          />{' '}
          <Skeleton
            variant="h1"
            sx={{
              minWidth: '7%',
              maxWidth: '10%',
            }}
          />
        </Stack>
      ) : (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
          }}
        >
          <FormControl size="small" fullWidth sx={{minWidth: '15%', maxWidth: '20%'}}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeValue}
              label="Type"
              onChange={handleClick}
            >
              <MenuItem value="Slider">Slider</MenuItem>
              <MenuItem value="RichText">RichText</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={buttonClick} variant="contained">
            Add
          </Button>
        </Stack>
      )}
    </>
  );
}

export default Type;
