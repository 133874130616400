import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import React from 'react';
import RichEditor from '../../../../components/RichEditor/RichEditor';

function RichTextModal({open, onClose, formik, index, tab}) {
  return (
    <Dialog fullScreen open={open} onClose={onClose} disableEnforceFocus>
      <DialogContent dividers>
        <RichEditor
          value={formik.values[tab][index]?.text}
          onChange={html => {
            formik.setFieldValue(`${tab}[${index}].text`, html);
            formik.setFieldTouched(`${tab}[${index}].text`, true);
          }}
          onClose={onClose}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RichTextModal;
