import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useAddCityMutation, useGetCountriesQuery, useGetProvincesQuery} from 'api';
import CityForm from './CityForm';

function CreateCityModal({open, onClose}) {
  const {data: countriesList, isLoading, isError} = useGetCountriesQuery();
  const {data: provincesList} = useGetProvincesQuery();

  const formRef = React.useRef();
  const [addCity] = useAddCityMutation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        Create City
      </DialogTitle>
      <DialogContent>
        <CityForm
          disabled={isLoading || isError}
          countriesList={countriesList || []}
          provincesList={provincesList || []}
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);

              const newObj = {
                ...values,
              };
              await addCity(newObj).unwrap();
              actions.resetForm();
              enqueueSnackbar('City created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message = e.status === 400 ? e.cityResponse?.details?.[0]?.message : undefined;
              enqueueSnackbar(message || 'Failed to create City', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCityModal;
