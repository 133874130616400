import {TextField, Box, FormHelperText} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import CountryFlagDropzone from './CountryFlagDropzone';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  flag: Yup.mixed()
    .required('Flag is required')
    .test('icon-valid', (value, {createError, path}) => {
      if (value?.error) {
        return createError({
          path,
          message: value.error,
        });
      }
      return true;
    }),
});

function CountryForm({initialValues = {}, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      name: '',
      flag: null,
      existingFlagId: null,
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="name"
        name="name"
        label="Country Name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        sx={{mb: 2}}
      />
      <Box>
        <CountryFlagDropzone
          previewSrc={formik.values.flag}
          onDropAccepted={file => {
            formik.setFieldValue('flag', {file});
          }}
          onDropRejected={(file, errors) => {
            formik
              .setFieldValue('flag', {file, error: errors?.[0]?.message || 'flag is invalid'})
              .then(() => {
                formik.setFieldTouched('flag', true);
              });
          }}
        />
        <FormHelperText sx={{mt: 1, ml: 1}} error>
          {formik.touched.flag && formik.errors.flag}
        </FormHelperText>
      </Box>
    </form>
  );
}

const ForwardedRefCountryForm = React.forwardRef(CountryForm);

export default ForwardedRefCountryForm;
