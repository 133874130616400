export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const {REACT_APP_URL} = process.env;

if (!AUTH0_CLIENT_ID) {
  throw new Error('AUTH0_CLIENT_ID is not defined');
}

if (!AUTH0_DOMAIN) {
  throw new Error('AUTH0_DOMAIN is not defined');
}

if (!AUTH0_AUDIENCE) {
  throw new Error('AUTH0_AUDIENCE is not defined');
}

if (!SERVER_URL) {
  throw new Error('SERVER_URL is not defined');
}

if (!REACT_APP_URL) {
  throw new Error('REACT_APP_URL is not defined');
}
