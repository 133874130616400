import {FormControl, MenuItem, Stack, Button, Select, Skeleton, InputLabel} from '@mui/material';
import React from 'react';

function ComponentsList({addComponent, componentValue, isLoading, changeDropDown}) {
  const handleChange = event => {
    changeDropDown(event.target.value);
  };
  return (
    <>
      {isLoading ? (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
          }}
        >
          <Skeleton
            variant="h1"
            sx={{
              minWidth: '15%',
              maxWidth: '20%',
            }}
          />{' '}
          <Skeleton
            variant="h1"
            sx={{
              minWidth: '7%',
              maxWidth: '10%',
            }}
          />
        </Stack>
      ) : (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
          }}
        >
          <FormControl size="small" fullWidth sx={{minWidth: '15%', maxWidth: '30%'}}>
            <InputLabel id="Layout-Components">Layout Components</InputLabel>
            <Select
              labelId="Layout-Components"
              id="Layout-Components"
              label="Layout Components"
              value={componentValue}
              onChange={handleChange}
            >
              <MenuItem value="FPList">Featured Products List</MenuItem>
              <MenuItem value="Banners">Banners</MenuItem>
              <MenuItem value="Companies">Companies</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={addComponent} variant="contained">
            Add
          </Button>
        </Stack>
      )}
    </>
  );
}

export default ComponentsList;
