import {styled} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';

const PaperDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
    fontSize: '15px',
    hideRightSeparator: {
      '& > .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
      },
    },
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  //   background: theme.palette.background.paper,
  //   boxShadow: theme.shadows[theme.components.MuiPaper.defaultProps.elevation || 2],
}));

export default PaperDataGrid;
