import React from 'react';
import {Routes, Route} from 'react-router-dom';
// import Authorization from 'pages/Authorization';
import Companies from 'pages/Companies';
import Marketing from 'pages/Marketing';
import Finance from 'pages/Finance';
import TradrSettings from 'pages/TradrSettings';
import Support from 'pages/Support';
import Company from 'pages/Company';
import {useAuth0} from '@auth0/auth0-react';
import {CircularProgress, Paper, Stack, Typography, Box} from '@mui/material';
import Blog from 'pages/Blog';
import LoginButton from 'components/Button/LoginButton';
import Layout from 'components/Layout';
import Home from 'pages/Home';
import {useGetMyTradrMembershipQuery} from 'api';
import {useDispatch, useSelector} from 'react-redux';
import {removeAccessToken, updateAccessToken} from 'redux/slices/auth';
import tradrLogo from 'assets/images/Tradr-logo.png';

function AuthenticatedApp() {
  const {data, error, isLoading, isSuccess} = useGetMyTradrMembershipQuery();
  if (isLoading) {
    return (
      <Stack sx={{height: '100vh'}} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  const isTradrMember = isSuccess ? data?.role?.group === 'TRON' : false;

  if (!isTradrMember || error)
    return (
      <Box sx={{p: 2}}>
        <Typography color="error" variant="subtitle2">
          Not a Tron Member
        </Typography>
      </Box>
    );

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/authorization/*" element={<Authorization />} /> */}
        <Route path="/companies" element={<Companies />} />
        <Route path="/companies/:id/*" element={<Company />} />
        <Route path="/marketing/*" element={<Marketing />} />
        <Route path="/finance/*" element={<Finance />} />
        <Route path="/tradr-settings/*" element={<TradrSettings />} />
        <Route path="/blog/*" element={<Blog />} />
        <Route path="/support/*" element={<Support />} />
      </Routes>
    </Layout>
  );
}

function UnauthenticatedApp() {
  return (
    <Stack sx={{height: '100vh'}} justifyContent="center" alignItems="center">
      <Box
        component="img"
        src={tradrLogo}
        alt="Boost CX Logo"
        sx={{width: '100%', maxWidth: '100px'}}
      />
      <Stack component={Paper} alignItems="center" justifyContent="center" sx={{p: 2}} spacing={2}>
        <Typography variant="h3">Welcome to Tradr Admin</Typography>
        <Typography variant="subtitle2">Please sign in to continue</Typography>
        <LoginButton>Sign in</LoginButton>
      </Stack>
    </Stack>
  );
}

function App() {
  const {isLoading, getAccessTokenSilently, isAuthenticated} = useAuth0();
  const accessToken = useSelector(state => state.auth.accessToken);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      dispatch(removeAccessToken());
      return;
    }

    getAccessTokenSilently()
      .then(token => {
        dispatch(updateAccessToken(token));
      })
      .catch(() => {
        dispatch(removeAccessToken());
      });
  }, [
    isLoading,
    getAccessTokenSilently,
    isAuthenticated,
    removeAccessToken,
    updateAccessToken,
    dispatch,
  ]);

  if (isLoading) {
    return (
      <Stack sx={{height: '100vh'}} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (!isAuthenticated || !accessToken) {
    return <UnauthenticatedApp />;
  }

  return <AuthenticatedApp />;
}

export default App;
