import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Box,
} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {slugify} from 'utils/slugify';
import IndustrialProcessIconDropzone from './IndustrialProcessIconDropzone';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  slug: Yup.string()
    .matches(
      /^(([a-z])+|([a-z]+-[a-z]+)+)$/,
      'Slug should only contain lowercase letters (a-z) and - in between words'
    )
    .required('Slug is required'),
  parent: Yup.string()
    .matches(
      /^((\/)|(\/(([a-z])+|([a-z]+-[a-z]+)+))+)$/,
      'Parent should only contain lowercase letters (a-z), /, and -'
    )
    .required('Parent is required'),
  icon: Yup.mixed().test('icon-valid', (value, {createError, path}) => {
    if (value?.error) {
      return createError({
        path,
        message: value.error,
      });
    }
    return true;
  }),
});

function IndustrialProcessForm({initialValues = {}, onSubmit, parentsList, disabled}, ref) {
  const formik = useFormik({
    initialValues: {
      name: '',
      slug: '',
      parent: '/',
      icon: null,
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="name"
        name="name"
        label="Process Name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        sx={{mb: 2}}
      />
      <TextField
        fullWidth
        id="slug"
        name="slug"
        label="Slug"
        value={formik.values.slug}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip component="div" title="Generate Slug">
                <IconButton
                  onClick={() => {
                    const slug = slugify(formik.values.name);
                    formik.setFieldValue('slug', slug).then(() => {
                      formik.setFieldTouched('slug', true);
                    });
                  }}
                  aria-label="Generate Slug"
                >
                  <AutorenewIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        onChange={formik.handleChange}
        error={formik.touched.slug && Boolean(formik.errors.slug)}
        helperText={formik.touched.slug && formik.errors.slug}
        sx={{mb: 2}}
      />
      <FormControl sx={{mb: 2}} disabled={disabled} fullWidth>
        <InputLabel id="parent-industrialProcess-label">Parent</InputLabel>
        <Select
          labelId="parent-industrialProcess-label"
          id="parent"
          name="parent"
          placeholder="Select Parent Process"
          value={formik.values.parent}
          label="Group"
          onChange={formik.handleChange}
          error={formik.touched.parent && Boolean(formik.errors.parent)}
        >
          <MenuItem value="/">Root (/)</MenuItem>
          {parentsList.sort().map(industrialProcess => (
            <MenuItem key={industrialProcess} value={industrialProcess}>
              {industrialProcess.substr(industrialProcess.indexOf('/') + 1)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{formik.touched.parent && formik.errors.parent}</FormHelperText>
      </FormControl>
      <Box>
        <IndustrialProcessIconDropzone
          previewSrc={`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${formik.values.icon}/public`}
          onDropAccepted={file => {
            formik.setFieldValue('icon', {file});
          }}
          onDropRejected={(file, errors) => {
            formik
              .setFieldValue('icon', {file, error: errors?.[0]?.message || 'Icon is invalid'})
              .then(() => {
                formik.setFieldTouched('icon', true);
              });
          }}
        />
        <FormHelperText sx={{mt: 1, ml: 1}} error>
          {formik.touched.icon && formik.errors.icon}
        </FormHelperText>
      </Box>
    </form>
  );
}

const ForwardedRefIndustrialProcessForm = React.forwardRef(IndustrialProcessForm);

export default ForwardedRefIndustrialProcessForm;
