import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Skeleton,
  TableFooter,
  TablePagination,
  Button,
} from '@mui/material';
import React from 'react';
import {useGetIndustrialProcessesQuery} from 'api';
import AddIcon from '@mui/icons-material/Add';
import IndustrialProcessDeleteIconButton from './IndustrialProcessDeleteIconButton';
import IndustrialProcessEditIconButton from './IndustrialProcessEditButton';
import CreateIndustrialProcessModal from './CreateIndustrialProcessModal';

function IndustrialProcessesTable() {
  const {data, isLoading, isError, isSuccess} = useGetIndustrialProcessesQuery();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <CreateIndustrialProcessModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="IndustrialProcess Table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>Industrial Processes</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Parent</TableCell>
              <TableCell align="right">Full Process</TableCell>
              <TableCell align="right">Icon</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              Boolean(data?.length) &&
              data.map(row => (
                <TableRow key={row._id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">{row.parent}</TableCell>
                  <TableCell align="right">{row.process}</TableCell>
                  <TableCell align="right">
                    {row.icon ? (
                      <Box
                        component="img"
                        sx={{width: 40, height: 40, objectFit: 'contain'}}
                        src={`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${row.icon}/public`}
                        alt={row.name}
                      />
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IndustrialProcessEditIconButton industrialProcess={row} />
                    <IndustrialProcessDeleteIconButton industrialProcessId={row._id} />
                  </TableCell>
                </TableRow>
              ))}
            {Boolean(isSuccess && !data?.length) && (
              <TableRow>
                <TableCell colSpan={6}>No IndustrialProcess found</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography color="error">Failed to fetch IndustrialProcesses.</Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={100}
                page={0}
                onPageChange={() => {
                  // console.log('onPageChange', rest);
                }}
                rowsPerPage={10}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default IndustrialProcessesTable;
