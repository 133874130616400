import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplaceRoleMutation} from 'api';
import RoleForm from './RoleForm';

function RoleEditIconButton({role}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceRole, replaceRoleResult] = useReplaceRoleMutation();
  const formRef = React.useRef();
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Dialog open={isModalOpen}>
        <DialogTitle>Update Role</DialogTitle>
        <DialogContent dividers>
          <RoleForm
            initialValues={{
              _id: role._id,
              name: role.name,
              group: role.group,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              try {
                const data = await replaceRole(values).unwrap();
                actions.resetForm();
                enqueueSnackbar(data.successMessage || 'Role updated', {
                  variant: 'success',
                });
                handleClose();
              } catch (error) {
                const message = error.data?.details?.[0]?.message || 'Error updating role';
                enqueueSnackbar(message, {
                  variant: 'error',
                });
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            disabled={replaceRoleResult.isLoading}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={replaceRoleResult.isLoading}
            color="success"
            endIcon={replaceRoleResult.isLoading ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        disabled={replaceRoleResult.isLoading}
        onClick={handleOpen}
        aria-label="edit"
        color="primary"
      >
        <EditIcon />
      </IconButton>
    </>
  );
}

export default RoleEditIconButton;
