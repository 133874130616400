import {useAuth0} from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import React from 'react';

function LoginButton({children, onClick, ...props}) {
  const {loginWithRedirect} = useAuth0();
  return (
    <Button
      onClick={(...rest) => {
        loginWithRedirect();
        onClick?.(...rest);
      }}
      size="small"
      {...props}
    >
      {children}
    </Button>
  );
}

export default LoginButton;
