import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {useGetCategoriesByParentQuery} from 'api';
import BreadCrumbs from 'components/BreadCrumbs';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import CategoriesTable from './CategoriesTable';

export default function CategoryModal({
  formik,
  icon = false,
  activeBreadCrumbs = true,
  setParentVal = true,
  setCategoryVal = true,
  productSpecs = false,
  setValue,
  watch,
}) {
  const parentId = watch ? watch('parentId') : formik?.values?.parentId;
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState(
    watch ? watch('category') : formik?.values?.category
  );
  const [parent, setParent] = React.useState(parentId || null);
  const [categoryId, setNewCategoryId] = React.useState(
    watch ? watch('categoryId') : formik?.values?.categoryId
  );
  const [newParentsList, setNewParentsList] = React.useState([]);
  const {data, isLoading} = useGetCategoriesByParentQuery({
    page: 0,
    limit: 10,
    parent: parentId,
    search: '',
  });

  React.useEffect(() => {
    setNewParentsList(data?.parents);
  }, [parentId, isLoading, parent]);

  const handleClickOpen = () => {
    setOpen(true);
    setParent(parentId);
  };

  const handleClose = () => {
    setOpen(false);
    setParent(parentId);
    setNewCategoryId(categoryId);
    setCategory(category);
  };
  const handleParent = id => {
    setParent(id);
  };
  const handleCategory = row => {
    setNewCategoryId(row._id);
    setCategory(row);
  };

  const handleNewParentList = list => {
    setNewParentsList(list);
  };

  const handleSelect = () => {
    if (icon) {
      setValue('icon', category.icon, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('name', category.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('categoryId', category._id, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    if (setParentVal) {
      setValue('parentId', parent, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    if (setCategoryVal) {
      setValue('categoryId', categoryId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    if (productSpecs) {
      const attributesArray = [];
      category?.attributes?.forEach(attribute => {
        attributesArray.push({
          attribute,
          values: [],
        });
      });
      setValue('productSpecs', attributesArray, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setOpen(false);
  };

  return (
    <div>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
        }}
      >
        {activeBreadCrumbs && <BreadCrumbs parents={newParentsList || []} category={category} />}
        <Button onClick={handleClickOpen}>Please Select Category</Button>
      </Stack>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <Stack
          direction="row"
          p={1}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Select Category
          </Typography>
          <IconButton color="error" onClick={handleClose} component="label">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <CategoriesTable
            handleNewParentList={handleNewParentList}
            handleParent={handleParent}
            handleCategory={handleCategory}
            parent={parent}
            categoryId={categoryId}
          />
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={categoryId}
            onClick={handleSelect}
            variant="contained"
            endIcon={<DoneAllIcon />}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
