import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
  Grid,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {
  useAddProductMutation,
  useGetUOMsQuery,
  useGetTradeTermsQuery,
  useGetSTypesQuery,
  useGetCurrenciesQuery,
  useGetPaymentMethodsQuery,
  useGetCertificationsQuery,
  useGetShippingMethodsQuery,
} from 'api';
import AddIcon from '@mui/icons-material/Add';
import {yupResolver} from '@hookform/resolvers/yup';
import ProductForm from './ProductForm';
import {productInitialValues, productValidationSchema} from '../utils';

function CreateProductModal() {
  const [isOpen, setIsOpen] = React.useState(false);

  const [addProduct] = useAddProductMutation();
  const {enqueueSnackbar} = useSnackbar();
  const {data: unitsList} = useGetUOMsQuery();
  const {data: tradeTermsList} = useGetTradeTermsQuery();
  const {data: sourcingTypeList} = useGetSTypesQuery();
  const {data: currenciesList} = useGetCurrenciesQuery();
  const {data: paymentMethodsList} = useGetPaymentMethodsQuery();
  const {data: certificationsList} = useGetCertificationsQuery();
  const {data: shippingMethodsList} = useGetShippingMethodsQuery();
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: {errors, isSubmitting},
  } = useForm({
    resolver: yupResolver(productValidationSchema),
    defaultValues: productInitialValues,
  });
  const onSubmit = async values => {
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('slug', values.slug);
      formData.append('categoryId', values.categoryId);
      formData.append('description', values.description);
      formData.append('companyId', values.companyId);
      formData.append('unitId', values.unitId);
      formData.append('tradeTermId', values.tradeTermId);
      formData.append('sourcingTypeId', values.sourcingTypeId);
      formData.append('description', values.description);
      formData.append('quantity', values.quantity);
      formData.append('minOrderQuantity', values.minOrderQuantity);
      formData.append('leadTime', values.leadTime);
      formData.append('parentId', values.parentId);
      formData.append('subSourcingTypeId', values.subSourcingTypeId);
      formData.append('tags', JSON.stringify(values.tags));
      formData.append('productSpecs', JSON.stringify(values.productSpecs));
      formData.append('priceType', values.priceType);
      formData.append('paymentId', values.paymentId);
      formData.append('shippingId', values.shippingId);
      formData.append('certificates', JSON.stringify(values.certificates));
      formData.append('currencyId', values.currencyId);
      if (values.priceType === 'fixed') {
        formData.append('price', values.price);
        formData.append('priceRange', JSON.stringify([]));
      } else {
        formData.append('priceRange', JSON.stringify(values.priceRange));
        formData.append('price', 0);
      }
      values.imageFiles.forEach(image => {
        formData.append('images', image);
      });
      await addProduct(formData).unwrap();
      enqueueSnackbar('Product created successfully', {
        variant: 'success',
      });
      setIsOpen(false);
      reset();
    } catch (e) {
      const message = e?.data?.data?.details?.[0].message || e?.data?.data || undefined;
      enqueueSnackbar(message || 'Failed to create product', {
        variant: 'error',
      });
    }
  };
  const onClose = () => {
    reset();
    setIsOpen(false);
  };
  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        disableElevation
        variant="contained"
        endIcon={<AddIcon />}
      >
        Add
      </Button>
      <Dialog fullScreen open={isOpen} onClose={onClose} disableEnforceFocus>
        <DialogTitle variant="h6">Create Product</DialogTitle>
        <DialogContent dividers>
          <Grid spacing>
            <ProductForm
              unitsList={unitsList}
              tradeTermsList={tradeTermsList}
              sourcingTypeList={sourcingTypeList}
              currenciesList={currenciesList}
              paymentMethodsList={paymentMethodsList}
              certificationsList={certificationsList}
              shippingMethodsList={shippingMethodsList}
              onSubmit={handleSubmit(onSubmit)}
              control={control}
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
            disabled={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateProductModal;
