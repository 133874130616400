import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  useReplaceCertificateMutation,
  useLazyGetUploadUrlQuery,
  useGetOrganizationsQuery,
} from 'api';
import {getImageURL} from '../../../utils/imageUrl';
import CertificateForm from './CertificateForm';

function CertificateEditIconButton({certificate}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {data, isLoading, isError} = useGetOrganizationsQuery();
  const [replaceCertificate] = useReplaceCertificateMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Dialog open={isModalOpen}>
        <DialogTitle>Update Certificate</DialogTitle>
        <DialogContent dividers>
          <CertificateForm
            disabled={isLoading || isError}
            organizationList={data}
            initialValues={{
              _id: certificate._id,
              shortName: certificate.shortName,
              fullTitle: certificate.fullTitle,
              description: certificate.description,
              icon: getImageURL(certificate.icon),
              organizationId: certificate.organizationId,
              companies: certificate.companies,
              products: certificate.products,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                let uploadResult = certificate.icon;
                if (values.icon.file) {
                  const formData = new FormData();
                  formData.append(`file`, values.icon.file);
                  const {url} = await getUploadUrl().unwrap();
                  const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                  });
                  if (!response.ok) {
                    throw new Error('Failed to upload icon.');
                  }
                  const imageObj = await response.json();
                  uploadResult = imageObj.result.id;
                }
                const newObj = {
                  ...values,
                  icon: uploadResult,
                };
                await replaceCertificate(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('certificate created successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.certificateResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to create certificate', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default CertificateEditIconButton;
