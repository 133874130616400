import React from 'react';
import NavLink from './NavLink';

function MenuLink(props) {
  return (
    <NavLink
      {...props}
      sx={{
        typography: 'subtitle2',
        color: 'text.primary',
        '&:hover': {
          color: 'text.secondary',
        },
        '&.active': {
          color: 'primary.main',
        },
      }}
    />
  );
}

export default MenuLink;
