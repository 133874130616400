import {
  Stack,
  FormLabel,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Box,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {slugify} from 'utils/slugify';
import CompanyLogoDropzone from './CompanyLogoDropzone';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  slug: Yup.string()
    .matches(
      /^[a-z0-9_-]+$/,
      'URL should only contain alphanumeric letters (a-z 0-9), - or _ in between words'
    )
    .required('URL is required'),
  businessType: Yup.string().required('Business Type is required'),
  totalEmployees: Yup.number('Invalid number'),
  certificates: Yup.array().of(Yup.string().required('Certificate is required')),
  totalAnnualRevenue: Yup.object({
    amount: Yup.number(),
    currency: Yup.string().when('amount', {
      is: amount => !!amount,
      then: Yup.string().required('Currency is required'),
    }),
  }),
  logo: Yup.mixed().required('Logo is required'),
  // featuredBanner: Yup.mixed().required('Featured Banner is required'),
  mainAddress: Yup.object({
    addressLine1: Yup.string().required('Address Line 1 is required'),
    city: Yup.string().required('City is required'),
    province: Yup.string().required('Province is required'),
    country: Yup.string().required('Country is required'),
  }),
  yearEstablished: Yup.number('Invalid number')
    .min(1200, 'Year must be greater than 1200')
    .max(new Date().getFullYear(), 'Year must be less than current year')
    .required(),
});

function CompanyForm(
  {
    initialValues = {},
    onSubmit,
    disabled,
    countriesList,
    companyTypesList,
    provincesList,
    currenciesList,
    citiesList,
    certificationsList,
  },
  ref
) {
  const formik = useFormik({
    initialValues: {
      name: '',
      slug: '',
      totalEmployees: 0,
      businessType: '',
      logo: null,
      logoUrl: null,
      certificates: [],
      // featuredBanner: null,
      yearEstablished: '',
      totalAnnualRevenue: {currency: '', amount: 0},
      mainAddress: {
        addressLine1: '',
        city: '',
        province: '',
        country: '',
      },
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });
  const currentCountryId = formik.values.mainAddress.country;
  const filteredProvinces = React.useMemo(() => {
    if (!currentCountryId) {
      return [];
    }
    return provincesList?.filter(p => p.countryId === currentCountryId);
  }, [currentCountryId, provincesList]);

  const currentProvinceId = formik.values.mainAddress.province;
  const filteredCities = React.useMemo(() => {
    if (!currentProvinceId) {
      return [];
    }
    return citiesList?.filter(c => c.provinceId === currentProvinceId);
  }, [currentProvinceId, citiesList]);
  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <FormLabel sx={{mb: -1}}>Company Details</FormLabel>
        <TextField
          size="small"
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && formik.errors.name}
          helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
        />
        <TextField
          fullWidth
          size="small"
          id="slug"
          name="slug"
          label="Minisite URL Text"
          value={formik.values.slug}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip component="div" title="Generate URL">
                  <IconButton
                    onClick={() => {
                      const slug = slugify(formik.values.name);
                      formik.setFieldValue('slug', slug).then(() => {
                        formik.setFieldTouched('slug', true);
                      });
                    }}
                    aria-label="Generate URL"
                  >
                    <AutorenewIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          onChange={formik.handleChange}
          error={formik.touched.slug && Boolean(formik.errors.slug)}
          helperText={formik.touched.slug && formik.errors.slug}
          sx={{mb: 2}}
        />
        <FormControl size="small" sx={{mb: 2}} disabled={disabled} fullWidth>
          <InputLabel>Business Type</InputLabel>
          <Select
            name="businessType"
            placeholder="Select Business Type"
            value={formik.values.businessType}
            label="Business Type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.businessType && Boolean(formik.errors.businessType)}
          >
            {companyTypesList?.map(companyType => (
              <MenuItem key={companyType._id} value={companyType._id}>
                {companyType.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {formik.touched.businessType && formik.errors.businessType}
          </FormHelperText>
        </FormControl>

        <TextField
          size="small"
          type="number"
          label="Total Employees"
          name="totalEmployees"
          InputProps={{
            inputProps: {
              type: 'number',
              min: 0,
              max: 25,
            },
          }}
          value={formik.values.totalEmployees}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.totalEmployees && formik.errors.totalEmployees}
          helperText={
            formik.touched.totalEmployees && formik.errors.totalEmployees
              ? formik.errors.totalEmployees
              : ''
          }
        />
        <TextField
          size="small"
          type="number"
          label="Year Established"
          name="yearEstablished"
          max={new Date().getFullYear()}
          value={formik.values.yearEstablished}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.yearEstablished && formik.errors.yearEstablished}
          helperText={formik.touched.yearEstablished && formik.errors.yearEstablished}
        />
        <FormControl sx={{m: 1}} disabled={disabled} fullWidth>
          <InputLabel id="certificates">Company Certifications</InputLabel>
          <Select
            labelId="certificates"
            multiple
            id="certificates"
            name="certificates"
            placeholder="Select Certifications"
            value={formik.values.certificates}
            label="Product Certifications"
            onChange={formik.handleChange}
            error={formik.touched.certificates && Boolean(formik.errors.certificates)}
          >
            {certificationsList
              ?.filter(certification => certification.companies === true)
              .map(certificate => (
                <MenuItem key={certificate._id} value={certificate._id}>
                  {`${certificate.fullTitle} (${certificate.organization.name})`}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText error>
            {formik.touched.shippingId && formik.errors.shippingId}
          </FormHelperText>
        </FormControl>
        <Box>
          <FormLabel sx={{display: 'inline-block', mb: 1}}>Total Annual Revenue</FormLabel>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                type="number"
                label="Amount"
                name="totalAnnualRevenue.amount"
                inputProps={{min: 1}}
                value={formik.values.totalAnnualRevenue.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.totalAnnualRevenue?.amount &&
                  formik.errors.totalAnnualRevenue?.amount
                }
                helperText={
                  formik.touched.totalAnnualRevenue?.amount &&
                  formik.errors.totalAnnualRevenue?.amount
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl disabled={disabled} fullWidth>
                <InputLabel size="small" id="currency">
                  Currency
                </InputLabel>
                <Select
                  labelId="currency"
                  id="currency"
                  size="small"
                  name="totalAnnualRevenue.currency"
                  disabled={!formik.values.totalAnnualRevenue.amount}
                  placeholder="Select Currency"
                  value={formik.values.totalAnnualRevenue?.currency}
                  label="Currency"
                  onChange={formik.handleChange}
                  error={
                    formik.touched?.totalAnnualRevenue?.currency &&
                    Boolean(formik.errors?.totalAnnualRevenue?.currency)
                  }
                >
                  {currenciesList?.map(currency => (
                    <MenuItem key={currency._id} value={currency._id}>
                      {currency.title}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formik.touched?.totalAnnualRevenue?.currency &&
                    formik.errors?.totalAnnualRevenue?.currency}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <FormLabel sx={{display: 'inline-block', mb: 1}}>Address</FormLabel>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Address Line 1"
                name="mainAddress.addressLine1"
                value={formik.values.mainAddress.addressLine1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.mainAddress?.addressLine1 &&
                  formik.errors.mainAddress?.addressLine1
                }
                helperText={
                  formik.touched.mainAddress?.addressLine1 &&
                  formik.errors.mainAddress?.addressLine1
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" sx={{mb: 2}} disabled={disabled} fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  name="mainAddress.country"
                  placeholder="Select Country"
                  value={formik.values.mainAddress?.country}
                  label="Country"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mainAddress?.country &&
                    Boolean(formik.errors.mainAddress?.country)
                  }
                >
                  {countriesList?.map(country => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formik.touched.mainAddress?.country && formik.errors.mainAddress?.country}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" sx={{mb: 2}} disabled={disabled} fullWidth>
                <InputLabel>Province</InputLabel>
                <Select
                  label="Province"
                  name="mainAddress.province"
                  placeholder="Select Province"
                  value={formik.values.mainAddress?.province}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mainAddress?.province &&
                    Boolean(formik.errors.mainAddress?.province)
                  }
                >
                  {filteredProvinces?.map(province => (
                    <MenuItem key={province._id} value={province._id}>
                      {province.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formik.touched.mainAddress?.province && formik.errors.mainAddress?.province}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl size="small" sx={{mb: 2}} disabled={disabled} fullWidth>
                <InputLabel>City</InputLabel>
                <Select
                  label="City"
                  name="mainAddress.city"
                  placeholder="Select City"
                  value={formik.values.mainAddress?.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mainAddress?.city && Boolean(formik.errors.mainAddress?.city)
                  }
                >
                  {filteredCities?.map(city => (
                    <MenuItem key={city._id} value={city._id}>
                      {city.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formik.touched.mainAddress?.city && formik.errors.mainAddress?.city}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <CompanyLogoDropzone
            previewSrc={formik.values.logo}
            onDropAccepted={file => {
              formik.setFieldValue('logo', {file});
            }}
            onDropRejected={(file, errors) => {
              formik
                .setFieldValue('logo', {file, error: errors?.[0]?.message || 'logo is invalid'})
                .then(() => {
                  formik.setFieldTouched('logo', true);
                });
            }}
          />
          <FormHelperText sx={{mt: 1, ml: 1}} error>
            {formik.touched.logo && formik.errors.logo}
          </FormHelperText>
        </Box>
      </Stack>
    </form>
  );
}

const ForwardedRefCompanyForm = React.forwardRef(CompanyForm);

export default ForwardedRefCompanyForm;
