import React from 'react';
import {useDropzone} from 'react-dropzone';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {Typography, styled, Stack, Box} from '@mui/material';

const Dropzone = styled('div')({
  border: ' 2px dashed rgb(219, 219, 219)',
  padding: '1rem',
  transition: 'background-color 0.3s',
  cursor: 'pointer',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'rgb(243, 243, 243)',
  },
});

export default function CertificateIconDropzone({
  onDropAccepted,
  onDropRejected,
  previewSrc,
  disabled = false,
}) {
  const [thumbnail, setThumbnail] = React.useState();
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    maxSize: 5000000, // in bytes
    multiple: false,
    onDropAccepted: files => {
      // console.log('accept', files);
      setThumbnail(URL.createObjectURL(files[0]));

      if (onDropAccepted) {
        onDropAccepted(files[0]);
      }
    },
    onDropRejected: files => {
      setThumbnail(null);
      // console.log('reject', files);
      if (onDropRejected) {
        onDropRejected(files[0].file, files[0].errors);
      }
    },
    disabled,
  });

  React.useEffect(
    () => () => {
      URL.revokeObjectURL(thumbnail);
    },
    [thumbnail]
  );

  return (
    <Stack direction="row" spacing={1}>
      {(thumbnail || previewSrc) && (
        <Box
          sx={{
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '4px',
            width: 150,
          }}
          component="img"
          src={thumbnail || previewSrc}
          alt="thumbnail"
        />
      )}
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography sx={{mb: 0}} align="center" paragraph>
          <AddAPhotoIcon />
        </Typography>
        {isDragActive ? (
          <Typography align="center" paragraph>
            Drop the icon here ...
          </Typography>
        ) : (
          <Typography align="center" paragraph>
            Drag n Drop the icon here, or click to select.
          </Typography>
        )}
      </Dropzone>
    </Stack>
  );
}
