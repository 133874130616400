import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {
  useAddCompanyMutation,
  useLazyGetUploadUrlQuery,
  useGetCountriesQuery,
  useGetCompanyTypesQuery,
  useGetProvincesQuery,
  useGetCitiesQuery,
  useGetCertificationsQuery,
  useGetCurrenciesQuery,
} from 'api';
import CompanyForm from './CompanyForm';

function CreateCompanyModal({open, onClose}) {
  const {data: countriesList, isLoading, isError} = useGetCountriesQuery();
  const {data: companyTypesList} = useGetCompanyTypesQuery();
  const {data: provincesList} = useGetProvincesQuery();
  const {data: currenciesList} = useGetCurrenciesQuery();
  const {data: certificationsList} = useGetCertificationsQuery();
  const {data: citiesList} = useGetCitiesQuery();
  const formRef = React.useRef();
  const [addCompany] = useAddCompanyMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        Create Company
      </DialogTitle>
      <DialogContent>
        <CompanyForm
          disabled={isLoading || isError}
          companyTypesList={companyTypesList}
          countriesList={countriesList}
          provincesList={provincesList}
          currenciesList={currenciesList || []}
          citiesList={citiesList}
          certificationsList={certificationsList?.data || []}
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const formData = new FormData();
              formData.append(`file`, values.logo.file);
              const {url} = await getUploadUrl().unwrap();
              const response = await fetch(url, {
                method: 'POST',
                body: formData,
              });
              if (!response.ok) {
                throw new Error('Failed to upload logo.');
              }
              const uploadResult = await response.json();
              const newObj = {
                ...values,
                logo: uploadResult.result.id,
              };
              delete newObj.logoUrl;
              await addCompany(newObj).unwrap();
              actions.resetForm();
              enqueueSnackbar('Company created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message = e.status === 400 ? e.data.data : undefined;
              enqueueSnackbar(message || 'Failed to create Company', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCompanyModal;
