import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useAddImageIntoGalleryMutation} from 'api';
import ImageForm from './ImageForm';

function ImageModal({open, onClose, companyId}) {
  const formRef = React.useRef();
  const [addImage] = useAddImageIntoGalleryMutation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  return (
    <>
      <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle mb={2} variant="h6">
          Add Image
        </DialogTitle>
        <DialogContent>
          <ImageForm
            ref={formRef}
            onSubmit={async (values, actions) => {
              try {
                setIsSubmitting(true);
                const formData = new FormData();
                formData.append('alt', values.alt);
                formData.append('notes', values.notes);
                formData.append('companyId', companyId);
                formData.append('tags', JSON.stringify(values.tags));
                values.imageFiles.forEach(image => {
                  formData.append('images', image);
                });
                await addImage(formData).unwrap();
                actions.resetForm();
                enqueueSnackbar('Image Add successfully', {
                  variant: 'success',
                });
                onClose();
              } catch (e) {
                const message =
                  e.status === 400 ? e.bannerResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to Add Image', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
            disabled={isSubmitting}
            color="primary"
            variant="contained"
            onClick={() => formRef.current?.submit?.()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImageModal;
