import {
  FormHelperText,
  TextField,
  Box,
  Autocomplete,
  Chip,
  FormControl,
  Stack,
} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import {useSnackbar} from 'notistack';
import * as Yup from 'yup';
import DropZoneMultiple from '../../../../components/DropZoneMultiple';

const validationSchema = Yup.object({
  imageFiles: Yup.array(
    Yup.mixed()
      .required('images is required')
      .test('images-valid', (value, {createError, path}) => {
        if (value?.error) {
          return createError({
            path,
            message: value.error,
          });
        }
        return true;
      })
  ).min(1, 'images is required'),
  alt: Yup.string(),
  notes: Yup.string(),
  tags: Yup.array(Yup.string().required('tags is required')),
});

function BannerForm({initialValues = {}, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      alt: '',
      notes: '',
      imageFiles: [],
      tags: [],
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });
  const {enqueueSnackbar} = useSnackbar();

  const filePreviews = React.useMemo(() =>
    formik.values.imageFiles?.map((file, index) => ({
      preview: URL.createObjectURL(file),
      index,
      edit: false,
    }))
  );
  const delImage = obj => {
    formik.setFieldValue(
      'imageFiles',
      formik.values.imageFiles.filter((_, ind) => ind !== obj.index)
    );
  };
  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2} mt={1}>
        <Box>
          <FormControl fullWidth>
            <DropZoneMultiple
              previewSrc={[...filePreviews]}
              onDropAccepted={files => {
                formik.setFieldValue(
                  'imageFiles',
                  Array.from(files).concat(Array.from(formik.values.imageFiles))
                );
              }}
              delImage={delImage}
              onDropRejected={(file, error) => {
                enqueueSnackbar('rejected', {file, error});
              }}
            />
            <FormHelperText error>
              {formik.touched.imageFiles && formik.errors.imageFiles}
            </FormHelperText>
          </FormControl>
        </Box>
        <TextField
          fullWidth
          id="alt"
          name="alt"
          label="Text"
          value={formik.values.alt}
          onChange={formik.handleChange}
          error={formik.touched.alt && Boolean(formik.errors.alt)}
          helperText={formik.touched.alt && formik.errors.alt}
        />
        <FormControl fullWidth>
          <Autocomplete
            onChange={(e, values) => {
              formik.setFieldValue('tags', values);
            }}
            onBlur={formik.handleBlur}
            fullWidth
            multiple
            freeSolo
            name="tags"
            defaultValue={formik.values.tags}
            options={[]}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={option}
                  size="small"
                  {...getTagProps({index})}
                />
              ))
            }
            getOptionLabel={option => option.title}
            filterSelectedOptions
            renderInput={params => (
              <TextField {...params} label="Tags" placeholder="Type tags here ..." />
            )}
          />
        </FormControl>

        <TextField
          multiline
          fullWidth
          id="notes"
          name="notes"
          label="Notes"
          value={formik.values.notes}
          onChange={formik.handleChange}
          error={formik.touched.notes && Boolean(formik.errors.notes)}
          helperText={formik.touched.notes && formik.errors.notes}
          rows={4}
        />
      </Stack>
    </form>
  );
}

const ForwardedRefBannerForm = React.forwardRef(BannerForm);

export default ForwardedRefBannerForm;
