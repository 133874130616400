import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {useAddNewsFeedMutation, useLazyGetUploadUrlQuery} from 'api';
import NewsFeedForm from './NewsFeedForm';
import {newsFeedInitialValues, newsFeedValidationSchema} from '../utils';

function CreateNewsFeedModal({parent, open, onClose}) {
  const [addNewsFeed] = useAddNewsFeedMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const {enqueueSnackbar} = useSnackbar();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: {errors, isSubmitting},
  } = useForm({
    resolver: yupResolver(newsFeedValidationSchema),
    defaultValues: newsFeedInitialValues,
  });

  const onSubmit = async values => {
    try {
      const formData = new FormData();
      formData.append(`file`, values.icon.file);
      const {url} = await getUploadUrl().unwrap();
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to upload icon.');
      }
      const uploadResult = await response.json();
      const newObj = {
        ...values,
        icon: uploadResult.result.id,
      };
      await addNewsFeed(newObj).unwrap();
      reset();
      enqueueSnackbar('NewFeed created successfully', {
        variant: 'success',
      });
      onClose();
    } catch (e) {
      const message = e.data.status || undefined;
      enqueueSnackbar(message || 'Failed to create NewsFeed', {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={open}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <DialogTitle variant="h6">Create NewFeed</DialogTitle>
      <DialogContent dividers>
        <NewsFeedForm
          setValue={setValue}
          control={control}
          watch={watch}
          parent={parent}
          onSubmit={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          errors={errors}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          color="inherit"
          variant="outlined"
          onClick={() => {
            reset();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewsFeedModal;
