import {Box, FormHelperText, Skeleton} from '@mui/material';
import React from 'react';
import CompanyLogoDropzone from '../CompanyLogoDropzone';

function Logo({formik, isLoading}) {
  const existing = formik.values.logo ? formik.values.logo : formik.values.logoFile;

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="h1"
          sx={{
            width: '100%',
            height: '80px',
          }}
        />
      ) : (
        <Box>
          <CompanyLogoDropzone
            previewSrc={existing}
            onDropAccepted={file => {
              formik.setFieldValue('logoFile', {file});
            }}
            onDropRejected={(file, errors) => {
              formik
                .setFieldValue('logoFile', {
                  file,
                  error: errors?.[0]?.message || 'logoFile is invalid',
                })
                .then(() => {
                  formik.setFieldTouched('logoFile', true);
                });
            }}
          />
          <FormHelperText sx={{mt: 1, ml: 1}} error>
            {formik.touched.logoFile && formik.errors.logoFile}
          </FormHelperText>
        </Box>
      )}
    </>
  );
}

export default Logo;
