import * as Yup from 'yup';

const newsFeedSchemaProps = {
  title: Yup.string().required('title is required'),
  description: Yup.string(),
  icon: Yup.mixed()
    .required('Icon is required')
    .test('icon-valid', (value, {createError, path}) => {
      if (value?.error) {
        return createError({
          path,
          message: value.error,
        });
      }
      return true;
    }),
  url: Yup.string(),
};
export const newsFeedInitialValues = {
  title: '',
  description: '',
  url: '',
  icon: null,
};
export const newsFeedValidationSchema = Yup.object(newsFeedSchemaProps);
