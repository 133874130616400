import {Paper, Typography, Divider, Button, CircularProgress, Stack, Skeleton} from '@mui/material';
import {useFormik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {useSelector, useDispatch} from 'react-redux';
import {updateHomePageLayout} from 'redux/slices/homePageLayout';
import {
  useGetFeaturedProductsListsQuery,
  useGetBannersQuery,
  useGetCompaniesQuery,
  useAddHomeLayoutMutation,
  useGetHomeLayoutQuery,
} from 'api';
import {useSnackbar} from 'notistack';
import ComponentsList from './ComponentsList';
import Widgets from './Widgets';
import FLModal from './FLModal';
import BannersModal from './BannersModal';
import CompaniesModal from './CompaniesModal';

const validationSchema = Yup.object({
  layout: Yup.array().of(
    Yup.object({
      type: Yup.string().required('type is required'),
      ListDetails: Yup.object().when('type', {
        is: 'FPList',
        then: Yup.object({
          listId: Yup.string().required('List Id is required'),
          title: Yup.string().required('Title is required'),
          boxColor: Yup.string().required('Color is required'),
          description: Yup.string().required('Description is required'),
          icon: Yup.string().required('Icon is required'),
          products: Yup.array()
            .of(
              Yup.object({
                slug: Yup.string().required('slug is required'),
                HeroImage: Yup.string().required('Image is required'),
                title: Yup.string().required('Title is required'),
                productId: Yup.string().required('id is required'),
              })
            )
            .min(1)
            .max(10),
        }).required(),
      }),
      banners: Yup.object().when('type', {
        is: 'Banners',
        then: Yup.object({
          size: Yup.string().oneOf(['Half', 'Full']).required('Size is required'),
          bannersDetail: Yup.object().when('size', size => {
            if (size === 'Half') {
              return Yup.object({
                banner1Id: Yup.string().required('Id is required'),
                image1: Yup.string().required('image is required'),
                redirectUrl1: Yup.string(),
                alt1: Yup.string(),
                banner2Id: Yup.string(),
                image2: Yup.string(),
                redirectUrl2: Yup.string(),
                alt2: Yup.string(),
              });
            }
            return Yup.object({
              banner1Id: Yup.string().required('Id is required'),
              image1: Yup.string().required('image is required'),
              redirectUrl1: Yup.string(),
              alt1: Yup.string(),
              banner2Id: Yup.string().required('Id is required'),
              image2: Yup.string().required('image is required'),
              redirectUrl2: Yup.string(),
              alt2: Yup.string(),
            });
          }),
        }).required(),
      }),
      companies: Yup.array().when('type', {
        is: 'Companies',
        then: Yup.array()
          .of(
            Yup.object({
              slug: Yup.string().required('slug is required'),
              logo: Yup.string().required('logo is required'),
              name: Yup.string().required('name is required'),
            })
          )
          .min(1)
          .required(),
      }),
    })
  ),
});

function HomeLayout() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const {data: featuredLists} = useGetFeaturedProductsListsQuery();
  const {data: companiesList} = useGetCompaniesQuery();
  const {data: BannersList} = useGetBannersQuery();
  const {data: layoutData, isLoading} = useGetHomeLayoutQuery();
  const [addLayout] = useAddHomeLayoutMutation();
  const dispatch = useDispatch();
  const layout = useSelector(state => state.homePageLayout.layout);

  const [componentValue, setComponentValue] = useState('');
  const [isFPList, setIsFPList] = React.useState(false);
  const [isBanners, setIsBanners] = React.useState(false);
  const [isCompanies, setIsCompanies] = React.useState(false);
  const [widgetIndex, setWidgetIndex] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      layout: [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      try {
        setIsSubmitting(true);
        await addLayout(values);
        dispatch(
          updateHomePageLayout({
            layout: [],
          })
        );
        enqueueSnackbar('Layout Saved successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar('Failed to Save Layout', {
          variant: 'error',
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  React.useEffect(
    () => () => {
      dispatch(
        updateHomePageLayout({
          layout: formik?.values?.layout,
        })
      );
    },
    [formik.values]
  );

  React.useEffect(async () => {
    if (layout.length) {
      formik.setFieldValue('layout', layout || []);
    } else {
      formik.setFieldValue('layout', layoutData?.data?.layout || []);
    }
  }, [layoutData]);

  const handleFPListModal = index => {
    setWidgetIndex(index);
    setIsFPList(!isFPList);
  };

  const handleBannersModal = index => {
    setWidgetIndex(index);
    setIsBanners(!isBanners);
  };

  const handleCompaniesModal = index => {
    setWidgetIndex(index);
    setIsCompanies(!isCompanies);
  };

  const changeDropDown = value => {
    setComponentValue(value);
  };

  const addComponent = () => {
    if (componentValue === 'FPList') {
      const list = [
        ...formik.values.layout,
        {
          type: 'FPList',
          ListDetails: {
            // listId: '',
            // title: '',
            // boxColor: '',
            // description: '',
            // icon: '',
            // products: [],
          },
        },
      ];
      formik.setFieldValue('layout', list);
    } else if (componentValue === 'Banners') {
      const list = [
        ...formik.values.layout,
        {
          type: 'Banners',
          banners: {
            size: '',
            bannersDetail: {
              banner1Id: '',
              image1: '',
              redirectUrl1: '',
              alt1: '',
              banner2Id: '',
              image2: '',
              redirectUrl2: '',
              alt2: '',
            },
          },
        },
      ];
      formik.setFieldValue('layout', list);
    } else if (componentValue === 'Companies') {
      const list = [
        ...formik.values.layout,
        {
          type: 'Companies',
          companies: [],
        },
      ];
      formik.setFieldValue('layout', list);
    }
  };

  const deleteWidget = index => {
    const newArray = formik.values.layout.filter((_, ind) => ind !== index);
    formik.setFieldValue('layout', newArray);
  };

  return (
    <>
      <FLModal
        open={isFPList}
        widgetIndex={widgetIndex}
        formik={formik}
        featuredLists={featuredLists}
        onClose={() => {
          handleFPListModal();
        }}
      />
      <BannersModal
        open={isBanners}
        BannersList={BannersList}
        widgetIndex={widgetIndex}
        formik={formik}
        onClose={() => {
          handleBannersModal();
        }}
      />
      <CompaniesModal
        open={isCompanies}
        companiesList={companiesList}
        widgetIndex={widgetIndex}
        formik={formik}
        onClose={() => {
          handleCompaniesModal();
        }}
      />
      <Typography variant="h6" sx={{mb: 1}}>
        Home Page Layout
      </Typography>
      {isLoading ? (
        <>
          <Stack spacing={2}>
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '70px',
              }}
            />
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '70px',
              }}
            />{' '}
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '70px',
              }}
            />{' '}
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '70px',
              }}
            />{' '}
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '70px',
              }}
            />
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '70px',
              }}
            />
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '70px',
              }}
            />
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '70px',
              }}
            />
          </Stack>
        </>
      ) : (
        <>
          <Paper sx={{p: 2, minHeight: '700px'}}>
            <ComponentsList
              isLoading={false}
              addComponent={addComponent}
              changeDropDown={changeDropDown}
              componentValue={componentValue}
            />
            <Divider
              sx={{
                m: 2,
              }}
            />
            <Widgets
              formik={formik}
              deleteWidget={deleteWidget}
              handleFPListModal={handleFPListModal}
              handleBannersModal={handleBannersModal}
              handleCompaniesModal={handleCompaniesModal}
              errors={formik.errors.layout}
            />
            <>
              <Stack spacing={1} p={2}>
                <Button
                  endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
                  disabled={isSubmitting || !formik.dirty}
                  color="primary"
                  variant="contained"
                  sx={{
                    minWidth: '100px',
                  }}
                  onClick={() => formik.handleSubmit()}
                >
                  Save
                </Button>
                <Button
                  disabled={isSubmitting || !formik.dirty}
                  variant="outlined"
                  color="inherit"
                  sx={{
                    minWidth: '100px',
                  }}
                  onClick={() => {
                    if (layoutData?.data) {
                      formik.setFieldValue('layout', layoutData?.data?.layout || []);
                      dispatch(
                        updateHomePageLayout({
                          layout: layoutData?.data?.layout,
                        })
                      );
                    }
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </>
            {/* 
        <form onSubmit={formik.handleSubmit}>
          
        </form> */}
          </Paper>
        </>
      )}
    </>
  );
}
export default HomeLayout;
