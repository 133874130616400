import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {
  useAddFeaturedProductsListMutation,
  useLazyGetUploadUrlQuery,
  useGetFeaturedProductsListsQuery,
} from 'api';
import FeaturedProductsListForm from './FeaturedProductsListForm';

function CreateFeaturedProductsListModal({open, onClose}) {
  const {isLoading, isError} = useGetFeaturedProductsListsQuery();
  const formRef = React.useRef();
  const [addFeaturedProductsList] = useAddFeaturedProductsListMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="md" open={open}>
      <DialogTitle mb={2} variant="h6">
        Create Featured Products List
      </DialogTitle>
      <DialogContent dividers>
        <FeaturedProductsListForm
          disabled={isLoading || isError}
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const formData = new FormData();
              formData.append(`file`, values.icon.file);
              const {url} = await getUploadUrl().unwrap();
              const response = await fetch(url, {
                method: 'POST',
                body: formData,
              });
              if (!response.ok) {
                throw new Error('Failed to upload icon.');
              }
              const uploadResult = await response.json();
              const newObj = {
                ...values,
                icon: uploadResult.result.id,
              };
              await addFeaturedProductsList(newObj).unwrap();
              actions.resetForm();
              enqueueSnackbar('FeaturedProductsList created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message = e?.data?.status || undefined;
              enqueueSnackbar(message || 'Failed to create FeaturedProductsList', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateFeaturedProductsListModal;
