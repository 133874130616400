import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplaceCityMutation, useGetCountriesQuery, useGetProvincesQuery} from 'api';
import CityForm from './CityForm';

function CityEditIconButton({city}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceCity] = useReplaceCityMutation();
  const {data: countriesList} = useGetCountriesQuery();
  const {data: provincesList} = useGetProvincesQuery();

  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isModalOpen}>
        <DialogTitle>Update City</DialogTitle>
        <DialogContent dividers>
          <CityForm
            initialValues={{
              _id: city._id,
              name: city.name,
              countryId: city.countryId,
              provinceId: city.provinceId,
            }}
            countriesList={countriesList || []}
            provincesList={provincesList || []}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const newObj = {
                  ...values,
                };
                await replaceCity(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('City updated successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.cityResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to update City', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default CityEditIconButton;
