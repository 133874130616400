import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Stack,
} from '@mui/material';
import React from 'react';
import ProductsModal from 'components/ProductsModal';

function BannersModal({open, formik, onClose, BannersList, widgetIndex}) {
  const handleChange = event => {
    formik.setFieldValue(`layout.[${widgetIndex}].banners.size`, event.target.value);
  };

  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle variant="h6">Banners</DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl fullWidth size="small">
          <InputLabel id="bannerSize">Banner Size</InputLabel>
          <Select
            labelId="bannerSize"
            id="bannerSize"
            value={formik?.values?.layout?.[widgetIndex]?.banners?.size}
            label="Banner Size"
            onChange={handleChange}
            error={formik?.errors?.layout?.[widgetIndex]?.banners?.size}
          >
            <MenuItem value="Full">Half Width</MenuItem>
            <MenuItem value="Half">Full Width</MenuItem>
          </Select>
        </FormControl>

        {formik?.values?.layout?.[widgetIndex]?.banners?.size && (
          <>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                mt: 2,
              }}
            >
              Banner Details
            </Typography>
            <Typography
              sx={{mb: 1, display: 'flex', 'justify-content': 'center', 'align-items': 'center'}}
            >
              {' '}
              {formik?.values?.layout?.[widgetIndex]?.banners?.size === 'Full' ? 'Banner 1' : null}
            </Typography>
            <FormControl
              fullWidth
              size="small"
              error={formik?.errors?.layout?.[widgetIndex]?.banners?.bannersDetail?.banner1Id}
            >
              <ProductsModal
                imagesList={BannersList.data || []}
                selectedId={
                  formik?.values?.layout?.[widgetIndex]?.banners?.bannersDetail?.banner1Id
                }
                onClick={banner => {
                  const newObj = {
                    type: 'Banners',
                    banners: {
                      size: formik?.values?.layout?.[widgetIndex]?.banners?.size,
                      bannersDetail: {
                        ...formik.values.layout[widgetIndex].banners.bannersDetail,
                        banner1Id: banner._id,
                        image1: banner?.image,
                        redirectUrl1: banner?.url,
                        alt1: banner?.alt,
                      },
                    },
                  };
                  formik.setFieldValue(`layout[${widgetIndex}]`, newObj);
                }}
                id="banner1Id"
              />
            </FormControl>
            <Stack spacing={1} mt={1}>
              <TextField
                fullWidth
                size="small"
                label="Text"
                name="alt"
                type="alt"
                sx={{
                  '& label.Mui-focused': {
                    color: theme =>
                      formik.errors[widgetIndex]?.banners?.bannersDetail?.alt1
                        ? theme.palette.error.main
                        : undefined,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: theme =>
                      formik.errors[widgetIndex]?.banners?.bannersDetail?.alt1
                        ? theme.palette.error.main
                        : undefined,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.alt1
                          ? theme.palette.error.main
                          : undefined,
                    },
                    '&:hover fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.alt1
                          ? theme.palette.error.main
                          : undefined,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.alt1
                          ? theme.palette.error.main
                          : undefined,
                    },
                  },
                }}
                value={formik?.values?.layout?.[widgetIndex]?.banners?.bannersDetail?.alt1}
                onChange={e => {
                  formik.setFieldValue(
                    `layout.[${widgetIndex}].banners.bannersDetail.alt1`,
                    e.target.value
                  );
                }}
              />
              <TextField
                fullWidth
                size="small"
                label="Redirect URL"
                name="url"
                type="url"
                sx={{
                  '& label.Mui-focused': {
                    color: theme =>
                      formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl1
                        ? theme.palette.error.main
                        : undefined,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: theme =>
                      formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl1
                        ? theme.palette.error.main
                        : undefined,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl1
                          ? theme.palette.error.main
                          : undefined,
                    },
                    '&:hover fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl1
                          ? theme.palette.error.main
                          : undefined,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl1
                          ? theme.palette.error.main
                          : undefined,
                    },
                  },
                }}
                value={formik?.values?.layout?.[widgetIndex]?.banners?.bannersDetail?.redirectUrl1}
                onChange={e => {
                  formik.setFieldValue(
                    `layout.[${widgetIndex}].banners.bannersDetail.redirectUrl1`,
                    e.target.value
                  );
                }}
              />
            </Stack>
          </>
        )}
        {formik?.values?.layout?.[widgetIndex]?.banners?.size === 'Full' && (
          <>
            <Typography
              sx={{
                mt: 1,
                mb: 1,
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
              }}
            >
              Banner 2
            </Typography>
            <ProductsModal
              imagesList={BannersList.data || []}
              selectedId={formik?.values?.layout?.[widgetIndex]?.banners?.bannersDetail?.banner2Id}
              onClick={banner => {
                const newObj = {
                  type: 'Banners',
                  banners: {
                    size: formik?.values?.layout?.[widgetIndex]?.banners?.size,
                    bannersDetail: {
                      ...formik.values.layout[widgetIndex].banners.bannersDetail,
                      banner2Id: banner._id,
                      image2: banner?.image,
                      redirectUrl2: banner?.url,
                      alt2: banner?.alt,
                    },
                  },
                };
                formik.setFieldValue(`layout[${widgetIndex}]`, newObj);
              }}
              id="banner1Id"
            />

            <Stack mt={1} spacing={1}>
              <TextField
                fullWidth
                size="small"
                label="Text"
                name="alt"
                type="alt"
                sx={{
                  '& label.Mui-focused': {
                    color: theme =>
                      formik.errors[widgetIndex]?.banners?.bannersDetail?.alt2
                        ? theme.palette.error.main
                        : undefined,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: theme =>
                      formik.errors[widgetIndex]?.banners?.bannersDetail?.alt2
                        ? theme.palette.error.main
                        : undefined,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.alt2
                          ? theme.palette.error.main
                          : undefined,
                    },
                    '&:hover fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.alt2
                          ? theme.palette.error.main
                          : undefined,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.alt2
                          ? theme.palette.error.main
                          : undefined,
                    },
                  },
                }}
                value={formik?.values?.layout?.[widgetIndex]?.banners?.bannersDetail?.alt2}
                onChange={e => {
                  formik.setFieldValue(
                    `layout.[${widgetIndex}].banners.bannersDetail.alt2`,
                    e.target.value
                  );
                }}
              />
              <TextField
                fullWidth
                size="small"
                label="Redirect URL"
                name="url"
                type="url"
                sx={{
                  '& label.Mui-focused': {
                    color: theme =>
                      formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl2
                        ? theme.palette.error.main
                        : undefined,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: theme =>
                      formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl2
                        ? theme.palette.error.main
                        : undefined,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl2
                          ? theme.palette.error.main
                          : undefined,
                    },
                    '&:hover fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl2
                          ? theme.palette.error.main
                          : undefined,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme =>
                        formik.errors[widgetIndex]?.banners?.bannersDetail?.redirectUrl2
                          ? theme.palette.error.main
                          : undefined,
                    },
                  },
                }}
                value={formik?.values?.layout?.[widgetIndex]?.banners?.bannersDetail?.redirectUrl2}
                onChange={e => {
                  formik.setFieldValue(
                    `layout.[${widgetIndex}].banners.bannersDetail.redirectUrl2`,
                    e.target.value
                  );
                }}
              />
            </Stack>
          </>
        )}
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BannersModal;
