import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplaceTradeTermMutation} from 'api';
import TradeTermForm from './TradeTermForm';

function TradeTermEditIconButton({tradeTerm}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceTradeTerm] = useReplaceTradeTermMutation();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isModalOpen}>
        <DialogTitle>Update TradeTerm</DialogTitle>
        <DialogContent dividers>
          <TradeTermForm
            initialValues={{
              id: tradeTerm._id,
              name: tradeTerm.name,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const newObj = {
                  ...values,
                };
                await replaceTradeTerm(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('TradeTerm updated successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.tradeTermResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to update TradeTerm', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default TradeTermEditIconButton;
