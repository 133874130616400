import {Typography, Stack} from '@mui/material';
import React from 'react';
import {ReactSortable} from 'react-sortablejs';

import SlideBanner from './SlideBanner';

function SlidersTable({
  sliders = [],
  handleEditSlide,
  tab,
  index,
  formik,
  delSlideChip,
  errors = {},
}) {
  return (
    <>
      {sliders.length ? (
        <Stack spacing={2}>
          <ReactSortable
            style={{width: '100%'}}
            list={JSON.parse(JSON.stringify([...formik.values[tab][index]?.slider]))}
            setList={newOrder => {
              const newSortedTypes = newOrder.map(data => {
                const newData = {...data};
                delete newData.chosen;
                delete newData.selected;
                return newData;
              });
              formik.setFieldValue(`${tab}[${index}].slider`, [...newSortedTypes]);
            }}
          >
            {sliders?.map((item, i) => (
              <SlideBanner
                errors={errors}
                index={index}
                i={i}
                item={item}
                handleEditSlide={handleEditSlide}
                tab={tab}
                formik={formik}
                delSlideChip={delSlideChip}
              />
            ))}
          </ReactSortable>
        </Stack>
      ) : (
        <Typography variant="body2" color="text.secondary">
          Please Select Sliders from Gallery
        </Typography>
      )}
    </>
  );
}

export default SlidersTable;
