import {IconButton, Slide} from '@mui/material';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

export function CustomSnackbarProvider(props) {
  const notistackRef = React.createRef();

  const onClickDismiss = key => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      TransitionComponent={Slide}
      {...props}
      ref={notistackRef}
      action={key => (
        <IconButton color="inherit" onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      )}
    />
  );
}
