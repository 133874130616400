import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Skeleton,
  TableFooter,
  TablePagination,
  Button,
} from '@mui/material';
import React from 'react';
import {useGetBannersQuery} from 'api';
import AddIcon from '@mui/icons-material/Add';
import BannerDeleteIconButton from './BannerDeleteIconButton';
import BannerEditIconButton from './BannerEditIconButton';
import CreateBannerModal from './CreateBannerModal';
import ViewerModel from './ViewerModel';
import {getImageURL} from '../../../utils/imageUrl';

function BannersTable() {
  const {data, isLoading, isError, isSuccess} = useGetBannersQuery();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isImageOpen, setIsImageOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState('');
  const [currentAlt, setCurrentAlt] = React.useState('');

  return (
    <>
      <CreateBannerModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <ViewerModel
        open={isImageOpen}
        onClose={() => {
          setIsImageOpen(false);
        }}
        image={currentImage}
        alt={currentAlt}
      />

      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="Banners Table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>Banners</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center"> Text</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              Boolean(data.data?.length) &&
              data.data.map(row => (
                <TableRow key={row._id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell align="center">{row.alt || 'N/A'}</TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      setCurrentImage(row.image);
                      setIsImageOpen(true);
                      setCurrentAlt(row.alt);
                    }}
                  >
                    <Box
                      component="img"
                      sx={{width: 40, height: 40, objectFit: 'contain'}}
                      src={getImageURL(row.image)}
                      alt={row.alt}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <BannerEditIconButton banner={row} />
                    <BannerDeleteIconButton bannerId={row._id} />
                  </TableCell>
                </TableRow>
              ))}
            {Boolean(isSuccess && !data.data?.length) && (
              <TableRow>
                <TableCell colSpan={4}>No Banner found</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography color="error">Failed to fetch Banners.</Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={100}
                page={0}
                onPageChange={() => {
                  // console.log('onPageChange', rest);
                }}
                rowsPerPage={10}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default BannersTable;
