import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Box,
  Autocomplete,
  Chip,
} from '@mui/material';
import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {Controller} from 'react-hook-form';
import {slugify} from 'utils/slugify';
import CategoryIconDropzone from './CategoryIconDropzone';

function CategoryForm({setValue, control, watch, errors, onSubmit, disabled}) {
  const {name, attributes, icon} = watch();
  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="name"
        control={control}
        render={({field}) => (
          <TextField
            fullWidth
            size="small"
            name="name"
            label="name"
            sx={{mb: 2, mt: 1}}
            {...field}
            helperText={errors.name?.message}
            error={errors.name?.message}
          />
        )}
      />
      <Controller
        name="slug"
        control={control}
        render={({field}) => (
          <TextField
            fullWidth
            size="small"
            id="slug"
            disabled={!name}
            name="slug"
            label="Slug"
            sx={{mb: 2}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip component="div" title="Generate Slug">
                    <IconButton
                      disabled={!name}
                      onClick={() => {
                        const slugValue = slugify(name);
                        setValue('slug', slugValue, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                      aria-label="Generate Slug"
                    >
                      <AutorenewIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            {...field}
            // onBlur={onBlur}
            helperText={errors.slug?.message}
            error={errors.slug?.message}
          />
        )}
      />
      <FormControl sx={{mb: 2}} disabled={disabled} fullWidth>
        <Autocomplete
          onChange={(e, values) => {
            setValue('attributes', values);
          }}
          fullWidth
          multiple
          freeSolo
          size="small"
          name="attributes"
          defaultValue={attributes}
          options={[]}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                color="primary"
                label={option}
                size="small"
                {...getTagProps({index})}
              />
            ))
          }
          getOptionLabel={option => option.title}
          filterSelectedOptions
          renderInput={params => (
            <TextField {...params} label="Attributes" placeholder="Attributes here ..." />
          )}
        />
      </FormControl>

      <Box>
        <CategoryIconDropzone
          previewSrc={icon}
          onDropAccepted={file => {
            setValue('icon', {file});
          }}
          onDropRejected={(file, error) => {
            setValue('icon', {file, error: error?.[0]?.message || 'Icon is invalid'});
          }}
        />
        <FormHelperText sx={{mt: 1, ml: 1}} error>
          {errors.icon?.message}
        </FormHelperText>
      </Box>
    </form>
  );
}

const ForwardedRefCategoryForm = React.forwardRef(CategoryForm);

export default ForwardedRefCategoryForm;
