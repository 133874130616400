import {TextField} from '@mui/material';

import React from 'react';

function SlideForm({formik, index, tab, slideIndex, errors}) {
  const handleChange = e => {
    formik.setFieldValue(`${tab}[${index}].slider[${slideIndex}].${e.target.name}`, e.target.value);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        size="small"
        label="Text"
        name="alt"
        type="alt"
        value={formik.values[tab][index].slider[slideIndex].alt}
        onChange={e => handleChange(e)}
        sx={{
          mb: 2,
          mt: 2,
          '& label.Mui-focused': {
            color: theme => (errors?.alt ? theme.palette.error.main : undefined),
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: theme => (errors?.alt ? theme.palette.error.main : undefined),
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme => (errors?.alt ? theme.palette.error.main : undefined),
            },
            '&:hover fieldset': {
              borderColor: theme => (errors?.alt ? theme.palette.error.main : undefined),
            },
            '&.Mui-focused fieldset': {
              borderColor: theme => (errors?.alt ? theme.palette.error.main : undefined),
            },
          },
        }}
      />
      <TextField
        fullWidth
        size="small"
        label="Redirect URL"
        name="url"
        type="url"
        value={formik.values[tab][index].slider[slideIndex].url}
        onChange={e => handleChange(e)}
        sx={{
          '& label.Mui-focused': {
            color: theme => (errors?.url ? theme.palette.error.main : undefined),
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: theme => (errors?.url ? theme.palette.error.main : undefined),
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme => (errors?.url ? theme.palette.error.main : undefined),
            },
            '&:hover fieldset': {
              borderColor: theme => (errors?.url ? theme.palette.error.main : undefined),
            },
            '&.Mui-focused fieldset': {
              borderColor: theme => (errors?.url ? theme.palette.error.main : undefined),
            },
          },
        }}
      />
    </form>
  );
}

const ForwardedRefSlideForm = React.forwardRef(SlideForm);

export default ForwardedRefSlideForm;
