import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {useDeleteCategoryMutation} from 'api';
import {useSnackbar} from 'notistack';

function CategoryDeleteIconButton({categoryId}) {
  const [deleteCategory, deleteCategoryResult] = useDeleteCategoryMutation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleDelete = async () => {
    try {
      await deleteCategory(categoryId).unwrap();
      enqueueSnackbar('Category deleted', {variant: 'success'});
      setIsModalOpen(false);
    } catch (error) {
      const message = error?.data?.status || undefined;
      enqueueSnackbar(message || `Error deleting Category`, {variant: 'error'});
    }
  };

  return (
    <>
      <Dialog open={isModalOpen} maxWidth="xs">
        <DialogTitle>Delete Category</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this Category?</DialogContent>
        <DialogActions>
          <Button
            disabled={deleteCategoryResult.isLoading}
            autoFocus
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={deleteCategoryResult.isLoading}
            color="error"
            endIcon={deleteCategoryResult.isLoading ? <CircularProgress size={16} /> : null}
            onClick={handleDelete}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        disabled={deleteCategoryResult.isLoading}
        aria-label="delete"
        color="error"
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
}

export default CategoryDeleteIconButton;
