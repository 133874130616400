import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  companyId: '',
  title: '',
  subHeading: '',
  logo: null,
  logoFile: null,
  isCoverBgColor: '',
  coverBgColor: '',
  coverBgImage: null,
  coverBgImageFile: null,
  mainPage: [],
  profile: [],
  currentTabValue: 0,
};

export const miniSiteSlice = createSlice({
  name: 'miniSite',
  initialState,
  reducers: {
    updateMiniSite: (state, action) => {
      state.title = action.payload.title || state.title;
      state.subHeading = action.payload.subHeading || state.subHeading;
      state.isCoverBgColor = action.payload.isCoverBgColor || state.isCoverBgColor;
      state.coverBgColor = action.payload.coverBgColor || state.coverBgColor;
      state.logo = action.payload.logo || state.logo;
      state.logoFile = action.payload.logoFile || state.logoFile;
      state.coverBgImage = action.payload.coverBgImage || state.coverBgImage;
      state.coverBgImageFile = action.payload.coverBgImageFile || state.coverBgImageFile;
      state.mainPage = action.payload.mainPage || state.mainPage;
      state.profile = action.payload.profile || state.profile;
      state.companyId = action.payload.companyId || state.companyId;
      state.currentTabValue = action.payload.currentTabValue || state.currentTabValue;
    },
    resetMiniSite: state => {
      state.title = '';
      state.subHeading = '';
      state.logo = null;
      state.isCoverBgColor = '';
      state.coverBgColor = '';
      state.logoFile = null;
      state.coverBgImage = null;
      state.coverBgImageFile = null;
      state.mainPage = [];
      state.profile = [];
      state.companyId = '';
      state.currentTabValue = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {updateMiniSite, resetMiniSite} = miniSiteSlice.actions;

export default miniSiteSlice.reducer;
