import * as React from 'react';
import {emphasize, styled} from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';

const StyledBreadcrumb = styled(Chip)(({theme}) => {
  const backgroundColor =
    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.typography.pxToRem(),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

export default function CustomizedBreadcrumbs({parents, handleParentChange, category = false}) {
  return (
    <div role="presentation">
      <Breadcrumbs
        sx={{
          pl: 2,
        }}
        aria-label="breadcrumb"
      >
        <StyledBreadcrumb
          component="a"
          onClick={() => {
            handleParentChange(null);
          }}
          label="Home"
          icon={<HomeIcon fontSize="small" />}
        />
        {parents?.map(parent => (
          <StyledBreadcrumb
            onClick={() => {
              handleParentChange(parent._id);
            }}
            component="a"
            label={parent.name}
          />
        ))}
        {category && (
          <StyledBreadcrumb
            onClick={() => {
              handleParentChange(category._id);
            }}
            component="a"
            label={category.name}
          />
        )}
      </Breadcrumbs>
    </div>
  );
}
