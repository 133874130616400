import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  useReplaceIndustrialProcessMutation,
  useGetIndustrialProcessesQuery,
  useLazyGetUploadUrlQuery,
} from 'api';
import IndustrialProcessForm from './IndustrialProcessForm';

function IndustrialProcessEditIconButton({industrialProcess}) {
  const {data} = useGetIndustrialProcessesQuery();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceIndustrialProcess] = useReplaceIndustrialProcessMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const parentsList = React.useMemo(() => {
    if (data) {
      return data.map(industrialProcessesList => industrialProcessesList.process);
    }
    return [];
  }, [data]);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  let slug = [];
  slug = industrialProcess.process.split('/');
  slug = slug[slug.length - 1];
  return (
    <>
      <Dialog open={isModalOpen}>
        <DialogTitle>Update Process</DialogTitle>
        <DialogContent dividers>
          <IndustrialProcessForm
            parentsList={parentsList}
            initialValues={{
              _id: industrialProcess._id,
              name: industrialProcess.name,
              slug,
              parent: industrialProcess.parent,
              icon: industrialProcess.icon,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const formData = new FormData();
                let uploadResult = null;
                if (values.icon.file) {
                  formData.append(`file`, values.icon.file);
                  const {url} = await getUploadUrl().unwrap();
                  const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                  });
                  if (!response.ok) {
                    throw new Error('Failed to upload icon.');
                  }
                  uploadResult = await response.json();
                }
                const newObj = {
                  ...values,
                  icon: uploadResult ? uploadResult.result.id : values.icon,
                  slug: `${values.slug}`,
                };
                if (!newObj.icon) {
                  delete newObj.icon;
                }
                await replaceIndustrialProcess(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('industrialProcess created successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.industrialProcessResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to create industrialProcess', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default IndustrialProcessEditIconButton;
