import {Box, FormHelperText, Skeleton} from '@mui/material';
import React from 'react';
import CompanyLogoDropzone from '../CompanyLogoDropzone';

function coverBgImage({formik, isLoading}) {
  const existing = formik.values.coverBgImage
    ? formik.values.coverBgImage
    : formik.values.coverBgImageFile;

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="h1"
          sx={{
            width: '100%',
            height: '80px',
          }}
        />
      ) : (
        <Box>
          <CompanyLogoDropzone
            text="Drag n Drop the Cover here, or click to select."
            previewSrc={existing}
            onDropAccepted={file => {
              formik.setFieldValue('coverBgImageFile', {file});
            }}
            onDropRejected={(file, errors) => {
              formik
                .setFieldValue('coverBgImageFile', {
                  file,
                  error: errors?.[0]?.message || 'coverBgImageFile is invalid',
                })
                .then(() => {
                  formik.setFieldTouched('coverBgImageFile', true);
                });
            }}
          />
          <FormHelperText sx={{mt: 1, ml: 1}} error>
            {formik.touched.coverBgImageFile && formik.errors.coverBgImageFile}
          </FormHelperText>
        </Box>
      )}
    </>
  );
}

export default coverBgImage;
