import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  layout: [],
};

export const homePageLayoutSlice = createSlice({
  name: 'homePageLayout',
  initialState,
  reducers: {
    updateHomePageLayout: (state, action) => {
      state.layout = action.payload.layout || state.layout;
    },
    resetHomePageLayout: state => {
      state.layout = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {updateHomePageLayout, resetHomePageLayout} = homePageLayoutSlice.actions;

export default homePageLayoutSlice.reducer;
