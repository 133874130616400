import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import React from 'react';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ImageSelectionModal from 'components/ImagesSelectionModal';
import SlidersTable from './SlidersTable';
import EditSlide from './EditSlide';

function SlidersModal({
  open,
  onClose,
  formik,
  index,
  sliders,
  tab,
  images,
  handleSelectedImages,
  delSlideChip,
  errors = {},
}) {
  const [isChild, setIsChild] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [slideIndex, setSlideIndex] = React.useState(null);
  const handleEditSlide = SliIndex => {
    setIsEdit(true);
    setSlideIndex(SliIndex);
  };
  return (
    <>
      <EditSlide
        formik={formik}
        slideIndex={slideIndex}
        open={isEdit}
        tab={tab}
        index={index}
        sliders={sliders}
        errors={errors}
        handleSelectedImages={handleSelectedImages}
        onClose={() => {
          setIsEdit(false);
        }}
      />
      <ImageSelectionModal
        open={isChild}
        images={images}
        handleSelectedImages={handleSelectedImages}
        onClose={() => {
          setIsChild(false);
        }}
      />
      <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle variant="h6">
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Add Slider</Typography>
            <IconButton
              onClick={() => {
                setIsChild(true);
              }}
              color="secondary"
            >
              <DriveFolderUploadIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            {' '}
            <FormControl variant="outlined">
              <FormHelperText
                sx={{
                  color: theme =>
                    errors[index]?.transitionTime ? theme.palette.error.main : undefined,
                }}
                id="outlined-weight-helper-text"
              >
                Transition Time / 0-500 sec
              </FormHelperText>
              <OutlinedInput
                sx={{
                  width: '50%',
                }}
                size="small"
                id="outlined-adornment-weight"
                value={formik.values[tab][index]?.transitionTime}
                type="number"
                onChange={time => {
                  formik.setFieldValue(`${tab}[${index}].transitionTime`, time.target.value);
                }}
                endAdornment={<InputAdornment position="end">sec</InputAdornment>}
                inputProps={{
                  inputProps: {min: 0, max: 10},
                }}
              />
            </FormControl>
            <SlidersTable
              sliders={sliders}
              handleEditSlide={handleEditSlide}
              formik={formik}
              index={index}
              tab={tab}
              delSlideChip={delSlideChip}
              errors={errors}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" startIcon={<KeyboardTabIcon />} onClick={onClose}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SlidersModal;
