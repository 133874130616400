import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplaceBannerMutation, useLazyGetUploadUrlQuery} from 'api';
import BannerForm from './BannerForm';
import {getImageURL} from '../../../utils/imageUrl';

function BannerEditIconButton({banner}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceBanner] = useReplaceBannerMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Dialog open={isModalOpen}>
        <DialogTitle>Update banner</DialogTitle>
        <DialogContent dividers>
          <BannerForm
            initialValues={{
              _id: banner._id,
              isFeatured: banner.isFeatured || false,
              alt: banner.alt,
              image: getImageURL(banner.image),
              url: banner.url,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                let uploadResult = banner.image;
                if (values.image.file) {
                  const formData = new FormData();
                  formData.append(`file`, values.image.file);
                  const {url} = await getUploadUrl().unwrap();
                  const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                  });
                  if (!response.ok) {
                    throw new Error('Failed to upload image.');
                  }
                  const imageObj = await response.json();
                  uploadResult = imageObj.result.id;
                }
                const newObj = {
                  ...values,
                  image: uploadResult,
                };
                await replaceBanner(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('banner created successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.bannerResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to create banner', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default BannerEditIconButton;
