import React from 'react';
import {useDropzone} from 'react-dropzone';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ImageTracer from 'imagetracerjs';
import {Typography, styled, Stack, Box, FormHelperText, Link} from '@mui/material';
import {useSnackbar} from 'notistack';

const Dropzone = styled('div')({
  border: ' 2px dashed rgb(219, 219, 219)',
  padding: '1rem',
  transition: 'background-color 0.3s',
  cursor: 'pointer',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'rgb(243, 243, 243)',
  },
});

function convertImageUrlToSvg(objectUrl, options) {
  return new Promise((resolve, reject) => {
    try {
      ImageTracer.loadImage(objectUrl, canvas => {
        try {
          // Getting ImageData from canvas with the helper function getImgdata().
          const imageData = ImageTracer.getImgdata(canvas);

          // Synchronous tracing to SVG string
          const svg = ImageTracer.imagedataToSVG(imageData, options);

          resolve(svg);
        } catch (error) {
          reject(new Error('Failed to convert image to SVG!'));
        }
      });
    } catch (error) {
      reject(new Error('Failed to convert image to SVG!'));
    }
  });
}

function convertSvgColors(svgString) {
  return svgString
    .replaceAll('rgb(0,0,0)', 'currentColor')
    .replaceAll('rgb(255,255,255)', 'rgba(255,255,255, 0)');
}

export default function CategoryIconDropzone({
  onDropAccepted,
  onDropRejected,
  previewSrc,
  disabled = false,
}) {
  const {enqueueSnackbar} = useSnackbar();
  const [thumbnail, setThumbnail] = React.useState();
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    maxSize: 5 * 1000 * 1000, // in bytes
    multiple: false,
    onDropAccepted: async files => {
      try {
        let file = files[0];

        if (file.type !== 'image/svg+xml') {
          const tempObjectUrl = URL.createObjectURL(file);

          const svgStr = await convertImageUrlToSvg(tempObjectUrl, {
            viewbox: true,
            colorsampling: 0,
            colorquantcycles: 1,
            pal: [
              {r: 255, g: 255, b: 255, a: 255},
              {r: 0, g: 0, b: 0, a: 255},
            ],
          });

          const convertedSvgStr = convertSvgColors(svgStr);

          URL.revokeObjectURL(tempObjectUrl);

          const svgBlob = new Blob([convertedSvgStr], {type: 'image/svg+xml'});

          file = new File([svgBlob], file.name, {
            lastModified: Date.now(),
            type: svgBlob.type,
          });
        }

        const objectUrl = URL.createObjectURL(file);

        setThumbnail(objectUrl);

        if (onDropAccepted) {
          onDropAccepted(file);
        }
      } catch (error) {
        enqueueSnackbar('Failed to convert to svg', {
          variant: 'error',
          persist: true,
        });
      }
    },
    onDropRejected: files => {
      setThumbnail(null);
      // console.log('reject', files);
      if (onDropRejected) {
        onDropRejected(files[0].file, files[0].errors);
      }
    },
    disabled,
  });

  React.useEffect(
    () => () => {
      if (thumbnail) {
        URL.revokeObjectURL(thumbnail);
      }
    },
    [thumbnail]
  );

  return (
    <div>
      <Stack direction="row" spacing={1}>
        {(thumbnail || previewSrc) && (
          <Box
            sx={{
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '4px',
              width: 150,
            }}
            component="img"
            src={thumbnail || previewSrc}
            alt="thumbnail"
          />
        )}
        <Dropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography sx={{mb: 0}} align="center" paragraph>
            <AddAPhotoIcon />
          </Typography>
          {isDragActive ? (
            <Typography align="center" paragraph>
              Drop the icon here ...
            </Typography>
          ) : (
            <Typography align="center" paragraph>
              Drag n Drop the icon here, or click to select.
            </Typography>
          )}
        </Dropzone>
      </Stack>
      <FormHelperText>
        Please use{' '}
        <Link href="https://svgco.de/" target="_blank" rel="noopener noreferrer">
          svgco.de
        </Link>{' '}
        if the auto converter isn&lsquo;t working correctly.
      </FormHelperText>
    </div>
  );
}
