import * as Yup from 'yup';

const productSchemaProps = {
  name: Yup.string().required('Name is required'),
  slug: Yup.string()
    .matches(
      /^(?!id_)[a-zA-Z0-9_-]+$/,
      "Allow Characters letters (a-z 0-9), - or _ in between words and Can't start with id_"
    )
    .required('Slug is required'),
  companyId: Yup.string().required('Company is required'),
  priceType: Yup.string().required('Type is required'),
  categoryId: Yup.string().required('Category is required'),
  parentId: Yup.string().nullable(true),
  description: Yup.string(),
  minOrderQuantity: Yup.string(),
  sourcingTypeId: Yup.string(),
  unitId: Yup.string().required('Unit Type is required'),
  tradeTermId: Yup.string().required('Trade Term Type is required'),
  paymentId: Yup.string(),
  shippingId: Yup.string(),
  currencyId: Yup.string().required('Currency is required'),
  certificates: Yup.array().of(Yup.string().required('Certificate is required')),
  price: Yup.number().when('priceType', {
    is: priceType => priceType === 'fixed',
    then: Yup.number().required('Price is required'),
  }),
  priceRange: Yup.array().when('priceType', {
    is: priceType => priceType === 'range',
    then: Yup.array(
      Yup.object({
        price: Yup.number().required('Price is required'),
        min: Yup.number().required('Min is required'),
        max: Yup.number().required('Max is required'),
      })
    ).required('Price Range is required'),
  }),
  category: Yup.mixed().nullable(true),
  leadTime: Yup.string(),
  imageFiles: Yup.array(
    Yup.mixed()
      .required('images is required')
      .test('images-valid', (value, {createError, path}) => {
        if (value?.error) {
          return createError({
            path,
            message: value.error,
          });
        }
        return true;
      })
  ).required('images is required'),
  imageIds: Yup.array(Yup.string().required('images is required')),
  tags: Yup.array(Yup.string().required('tags is required')),
  productSpecs: Yup.array(
    Yup.object({
      attribute: Yup.string(),
      values: Yup.array(Yup.string()),
    })
  ),
};
export const productInitialValues = {
  name: '',
  slug: '',
  companyId: '',
  categoryId: '',
  parentId: null,
  description: '',
  tradeTermId: '',
  currencyId: '',
  paymentId: '',
  shippingId: '',
  certificates: [],
  minOrderQuantity: 0,
  sourcingTypeId: '',
  subSourcingTypeId: '',
  priceType: 'fixed',
  priceRange: [],
  unitId: '',
  price: '',
  leadTime: '',
  imageFiles: [],
  imageIds: [],
  tags: [],
  productSpecs: [],
  category: false,
};

export const productValidationSchema = Yup.object(productSchemaProps);
