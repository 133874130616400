import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {useAddCategoryMutation, useLazyGetUploadUrlQuery} from 'api';
import CategoryForm from './CategoryForm';
import {categoryInitialValues, categoryValidationSchema} from '../utils';

function CreateCategoryModal({parent, open, onClose}) {
  const [addCategory] = useAddCategoryMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const {enqueueSnackbar} = useSnackbar();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: {errors, isSubmitting},
  } = useForm({
    resolver: yupResolver(categoryValidationSchema),
    defaultValues: categoryInitialValues,
  });

  const onSubmit = async values => {
    try {
      const formData = new FormData();
      formData.append(`file`, values.icon.file);
      const {url} = await getUploadUrl().unwrap();
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to upload icon.');
      }
      const uploadResult = await response.json();
      const newObj = {
        ...values,
        icon: uploadResult.result.id,
        slug: `${values.slug}`,
        parent,
      };
      await addCategory(newObj).unwrap();
      reset();
      enqueueSnackbar('Category created successfully', {
        variant: 'success',
      });
      onClose();
    } catch (e) {
      const message = e.data.status || undefined;
      enqueueSnackbar(message || 'Failed to create category', {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle variant="h6">Create Category</DialogTitle>
      <DialogContent dividers>
        <CategoryForm
          setValue={setValue}
          control={control}
          watch={watch}
          parent={parent}
          onSubmit={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          errors={errors}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCategoryModal;
