import Dummy from 'components/Dummy';
import InnerLayout from 'components/Layout/InnerLayout';
import {Categories as AllCategories} from 'features/categories';
import {
  Countries as AllCountries,
  Cities as AllCities,
  Provinces as AllProvinces,
} from 'features/address-management';
import {IndustrialProcesses as AllIndustrialProcesses} from 'features/industrialProcesses';
import {CompanyTypes as AllCompanyTypes} from 'features/companyTypes';
import {UOMs as AllUOMsTypes} from 'features/uom';
import {ShippingMethods as AllShippingMethodsTypes} from 'features/shipping-methods';
import {PaymentMethods as AllPaymentMethodsTypes} from 'features/payment-methods';
import {Currencies as AllCurrenciesTypes} from 'features/currencies';
import {TradrMembers as AllTradrMembers} from 'features/tradr-members';
import {TradeTerms as AllTradeTerms} from 'features/tradeTerms';
import {Certifications as AllCertifications} from 'features/certifications';
import {Roles} from 'features/roles';
import {Organizations as AllOrganizations} from 'features/organization';
import {STypes as AllSTypes} from 'features/sourcing-types';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

function TradrSettings() {
  return (
    <InnerLayout
      links={[
        {
          to: 'address-management/country',
          title: 'Address Management',
          subLinks: [
            {
              to: 'address-management/country',
              title: 'Country',
            },
            {
              to: 'address-management/province',
              title: 'Province',
            },
            {
              to: 'address-management/city',
              title: 'City',
            },
          ],
        },
        {to: 'product-categories', title: 'Product Categories'},
        {to: 'company-types', title: 'Company Types'},
        {to: 'tradr-members', title: 'Tradr Members'},
        {to: 'industrial-process', title: 'Industrial Process'},
        {to: 'uoms', title: 'Units Of Measurement'},
        {to: 'shippingMethods', title: 'Shipping Methods'},
        {to: 'paymentMethods', title: 'Payment Methods'},
        {to: 'currencies', title: 'Currencies'},
        {to: 'tradeTerms', title: 'Trade Terms'},
        {
          to: 'certifications/organization',
          title: 'Certifications',
          subLinks: [
            {
              to: 'certifications/organization',
              title: 'Organization',
            },
            {
              to: 'certifications/certificate',
              title: 'Certificate',
            },
          ],
        },
        {to: 'sourcing-types', title: 'Sourcing Types'},
        {to: 'roles', title: 'Role Permissions'},
      ]}
    >
      <Routes>
        <Route path="product-categories" element={<AllCategories />} />
        <Route path="address-management/country" element={<AllCountries />} />
        <Route path="address-management/province" element={<AllProvinces />} />
        <Route path="address-management/city" element={<AllCities />} />
        <Route path="company-types" element={<AllCompanyTypes />} />
        <Route path="tradr-members" element={<AllTradrMembers />} />
        <Route path="industrial-process" element={<AllIndustrialProcesses />} />
        <Route path="uoms" element={<AllUOMsTypes />} />
        <Route path="shippingMethods" element={<AllShippingMethodsTypes />} />
        <Route path="paymentMethods" element={<AllPaymentMethodsTypes />} />
        <Route path="currencies" element={<AllCurrenciesTypes />} />
        <Route path="tradeTerms" element={<AllTradeTerms />} />
        <Route path="certifications/certificate" element={<AllCertifications />} />
        <Route path="certifications/organization" element={<AllOrganizations />} />
        <Route path="sourcing-types" element={<AllSTypes />} />
        <Route path="roles" element={<Roles />} />
        <Route index element={<Navigate to="address-management/country" replace />} />
        <Route path="*" element={<Dummy />} />
      </Routes>
    </InnerLayout>
  );
}

export default TradrSettings;
