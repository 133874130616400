import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {SERVER_URL} from '../config';

export const serverApi = createApi({
  reducerPath: 'serverApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    prepareHeaders: (headers, {getState}) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'Roles',
    'Users',
    'Categories',
    'FeaturedCategories',
    'Countries',
    'UOMs',
    'TradeTerms',
    'Cities',
    'Provinces',
    'CompanyTypes',
    'IndustrialProcesses',
    'Companies',
    'FeaturedProductsLists',
    'Products',
    'Banners',
    'MiniSite',
    'SourcingTypes',
    'Certifications',
    'TradrMembers',
    'Organizations',
    'ShippingMethods',
    'PaymentMethods',
    'Currencies',
    'MiniSiteLayout',
    'MiniSiteCategories',
    'HomePageLayout',
    'NewsFeeds',
  ],
  endpoints: builder => ({
    getMyTradrMembership: builder.query({
      query: () => `tradr-members/me`,
    }),
    getRoles: builder.query({
      query: () => `roles`,
      providesTags: result =>
        result
          ? [...result.map(({_id}) => ({type: 'Roles', id: _id})), {type: 'Roles', id: 'LIST'}]
          : [{type: 'Roles', id: 'LIST'}],
    }),
    getUsers: builder.query({
      query: params => ({
        url: `users/?page=${params.page}&per_page=${params.perPage}`,
        method: 'GET',
      }),
      invalidatesTags: [{type: 'Users', id: 'LIST'}],
    }),
    getUploadUrl: builder.query({
      query: () => `/images/upload-url`,
    }),
    deleteRole: builder.mutation({
      query: id => ({
        url: `roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Roles', id: 'LIST'}],
    }),
    addRole: builder.mutation({
      query: body => ({
        url: `roles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Roles', id: 'LIST'}],
    }),
    replaceRole: builder.mutation({
      query: body => ({
        url: `roles`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Roles', id: 'LIST'}],
    }),
    addHomeLayout: builder.mutation({
      query: body => ({
        url: `layout`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'HomePageLayout', id: 'LIST'}],
    }),
    getHomeLayout: builder.query({
      query: () => ({
        url: `/layout`,
        method: 'GET',
      }),
      providesTags: [{type: 'HomePageLayout', id: 'LIST'}],
    }),
    replaceCategory: builder.mutation({
      query: body => ({
        url: `categories`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Categories', id: 'LIST'}],
    }),
    replaceFeaturedCategory: builder.mutation({
      query: body => ({
        url: `featuredCategories`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'FeaturedCategories', id: 'LIST'}],
    }),
    deleteFeaturedCategory: builder.mutation({
      query: id => ({
        url: `featuredCategories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'FeaturedCategories', id: 'LIST'}],
    }),
    getCategories: builder.query({
      query: () => `categories`,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'Categories', id: _id})),
              {type: 'Categories', id: 'LIST'},
            ]
          : [{type: 'Categories', id: 'LIST'}],
    }),
    getRootCategories: builder.query({
      query: () => `categories/root`,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'Categories', id: _id})),
              {type: 'Categories', id: 'LIST'},
            ]
          : [{type: 'Categories', id: 'LIST'}],
    }),
    getCategoriesByParent: builder.query({
      query: ({page, limit, parent, search} = {search: '', parent: null, page: 0, limit: 10}) => ({
        url: '/categoriesByParent',
        params: {id: parent, page, limit, search},
      }),
      providesTags: [{type: 'Categories', id: 'LIST'}],
    }),

    getFeaturedCategories: builder.query({
      query: (
        {page, limit, search, isActive} = {search: '', page: 0, limit: 10, isActive: true}
      ) => ({
        url: '/featuredCategories',
        params: {search, page, limit, isActive},
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'FeaturedCategories', id: _id})),
              {type: 'FeaturedCategories', id: 'LIST'},
            ]
          : [{type: 'FeaturedCategories', id: 'LIST'}],
    }),
    deleteCategory: builder.mutation({
      query: id => ({
        url: `categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Categories', id: 'LIST'}],
    }),
    deleteFeaturedCategories: builder.mutation({
      query: id => ({
        url: `featuredCategories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'FeaturedCategories', id: 'LIST'}],
    }),
    addCategory: builder.mutation({
      query: body => ({
        url: `categories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Categories', id: 'LIST'}],
    }),
    addFeaturedCategory: builder.mutation({
      query: body => ({
        url: `featuredCategories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'FeaturedCategories', id: 'LIST'}],
    }),
    replaceSType: builder.mutation({
      query: body => ({
        url: `sourcing-types`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'SourcingTypes', id: 'LIST'}],
    }),
    getSTypes: builder.query({
      query: () => `sourcing-types`,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'SourcingTypes', id: _id})),
              {type: 'SourcingTypes', id: 'LIST'},
            ]
          : [{type: 'SourcingTypes', id: 'LIST'}],
    }),
    deleteSType: builder.mutation({
      query: id => ({
        url: `sourcing-types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'SourcingTypes', id: 'LIST'}],
    }),
    addSType: builder.mutation({
      query: body => ({
        url: `sourcing-types`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'SourcingTypes', id: 'LIST'}],
    }),
    getCountries: builder.query({
      query: () => `countries`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'Countries', id: _id})),
              {type: 'Countries', id: 'LIST'},
            ]
          : [{type: 'Countries', id: 'LIST'}],
    }),
    addCountry: builder.mutation({
      query: body => ({
        url: `countries`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Countries', id: 'LIST'}],
    }),
    deleteCountry: builder.mutation({
      query: id => ({
        url: `countries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Countries', id: 'LIST'}],
    }),
    replaceCountry: builder.mutation({
      query: body => ({
        url: `countries`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Countries', id: 'LIST'}],
    }),
    getTradeTerms: builder.query({
      query: () => `tradeTerms`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'TradeTerms', id: _id})),
              {type: 'TradeTerms', id: 'LIST'},
            ]
          : [{type: 'TradeTerms', id: 'LIST'}],
    }),
    addTradeTerm: builder.mutation({
      query: body => ({
        url: `tradeTerms`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'TradeTerms', id: 'LIST'}],
    }),
    deleteTradeTerm: builder.mutation({
      query: id => ({
        url: `tradeTerms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'TradeTerms', id: 'LIST'}],
    }),
    replaceTradeTerm: builder.mutation({
      query: body => ({
        url: `tradeTerms`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'TradeTerms', id: 'LIST'}],
    }),
    getUOMs: builder.query({
      query: () => `uom`,
      providesTags: result =>
        result
          ? [...result.map(({_id}) => ({type: 'UOMs', id: _id})), {type: 'UOMs', id: 'LIST'}]
          : [{type: 'UOMs', id: 'LIST'}],
    }),
    addUOM: builder.mutation({
      query: body => ({
        url: `uom`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'UOMs', id: 'LIST'}],
    }),
    deleteUOM: builder.mutation({
      query: id => ({
        url: `uom/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'UOMs', id: 'LIST'}],
    }),
    replaceUOM: builder.mutation({
      query: body => ({
        url: `uom`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'UOMs', id: 'LIST'}],
    }),
    getShippingMethods: builder.query({
      query: () => `shippingMethods`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'ShippingMethods', id: _id})),
              {type: 'ShippingMethods', id: 'LIST'},
            ]
          : [{type: 'ShippingMethods', id: 'LIST'}],
    }),
    addShippingMethod: builder.mutation({
      query: body => ({
        url: `shippingMethods`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'ShippingMethods', id: 'LIST'}],
    }),
    deleteShippingMethod: builder.mutation({
      query: id => ({
        url: `shippingMethods/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'ShippingMethods', id: 'LIST'}],
    }),
    replaceShippingMethod: builder.mutation({
      query: body => ({
        url: `shippingMethods`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'ShippingMethods', id: 'LIST'}],
    }),
    getPaymentMethods: builder.query({
      query: () => `paymentMethods`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'PaymentMethods', id: _id})),
              {type: 'PaymentMethods', id: 'LIST'},
            ]
          : [{type: 'PaymentMethods', id: 'LIST'}],
    }),
    addPaymentMethod: builder.mutation({
      query: body => ({
        url: `paymentMethods`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'PaymentMethods', id: 'LIST'}],
    }),
    deletePaymentMethod: builder.mutation({
      query: id => ({
        url: `paymentMethods/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'PaymentMethods', id: 'LIST'}],
    }),
    replacePaymentMethod: builder.mutation({
      query: body => ({
        url: `paymentMethods`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'PaymentMethods', id: 'LIST'}],
    }),
    getCurrencies: builder.query({
      query: () => `currencies`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'Currencies', id: _id})),
              {type: 'Currencies', id: 'LIST'},
            ]
          : [{type: 'Currencies', id: 'LIST'}],
    }),
    addCurrency: builder.mutation({
      query: body => ({
        url: `currencies`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Currencies', id: 'LIST'}],
    }),
    deleteCurrency: builder.mutation({
      query: id => ({
        url: `currencies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Currencies', id: 'LIST'}],
    }),
    replaceCurrency: builder.mutation({
      query: body => ({
        url: `currencies`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Currencies', id: 'LIST'}],
    }),
    getMiniSiteCategories: builder.query({
      query: id => `miniSiteCategories/${id}`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'MiniSiteCategories', id: _id})),
              {type: 'MiniSiteCategories', id: 'LIST'},
            ]
          : [{type: 'MiniSiteCategories', id: 'LIST'}],
    }),
    addMiniSiteCategory: builder.mutation({
      query: body => ({
        url: `miniSiteCategories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'MiniSiteCategories', id: 'LIST'}],
    }),
    getSearch: builder.query({
      query: query => ({
        url: `search?query=${query.query}&type=${query.type}&page=1&limit=10`,
      }),
    }),
    deleteMiniSiteCategory: builder.mutation({
      query: id => ({
        url: `miniSiteCategories?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'MiniSiteCategories', id: 'LIST'}],
    }),
    replaceMiniSiteCategory: builder.mutation({
      query: body => ({
        url: `miniSiteCategories`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'MiniSiteCategories', id: 'LIST'}],
    }),
    getTradrMembers: builder.query({
      query: () => `tradr-members`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'TradrMembers', id: _id})),
              {type: 'TradrMembers', id: 'LIST'},
            ]
          : [{type: 'TradrMembers', id: 'LIST'}],
    }),
    addTradrMember: builder.mutation({
      query: body => ({
        url: `tradr-members`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'TradrMembers', id: 'LIST'}],
    }),
    deleteTradrMember: builder.mutation({
      query: id => ({
        url: `tradr-members/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'TradrMembers', id: 'LIST'}],
    }),
    replaceTradrMember: builder.mutation({
      query: body => ({
        url: `tradr-members`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'TradrMembers', id: 'LIST'}],
    }),
    getCertifications: builder.query({
      query: () => `certificate`,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'Certifications', id: _id})),
              {type: 'Certifications', id: 'LIST'},
            ]
          : [{type: 'Certifications', id: 'LIST'}],
    }),
    addCertificate: builder.mutation({
      query: body => ({
        url: `certificate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Certifications', id: 'LIST'}],
    }),
    deleteCertificate: builder.mutation({
      query: id => ({
        url: `certificate/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Certifications', id: 'LIST'}],
    }),
    replaceCertificate: builder.mutation({
      query: body => ({
        url: `certificate`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Certifications', id: 'LIST'}],
    }),
    getOrganizations: builder.query({
      query: () => `organization`,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'Organizations', id: _id})),
              {type: 'Organizations', id: 'LIST'},
            ]
          : [{type: 'Organizations', id: 'LIST'}],
    }),
    addOrganization: builder.mutation({
      query: body => ({
        url: `organization`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Organizations', id: 'LIST'}],
    }),
    deleteOrganization: builder.mutation({
      query: id => ({
        url: `organization/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Organizations', id: 'LIST'}],
    }),
    replaceOrganization: builder.mutation({
      query: body => ({
        url: `organization`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Organizations', id: 'LIST'}],
    }),
    getCities: builder.query({
      query: () => `cities`,
      providesTags: result =>
        result
          ? [...result.map(({_id}) => ({type: 'Cities', id: _id})), {type: 'Cities', id: 'LIST'}]
          : [{type: 'Cities', id: 'LIST'}],
    }),
    addCity: builder.mutation({
      query: body => ({
        url: `cities`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Cities', id: 'LIST'}],
    }),
    addCompanyMember: builder.mutation({
      query: body => ({
        url: `companies/member`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Users', id: 'LIST'}],
    }),
    deleteCity: builder.mutation({
      query: id => ({
        url: `cities/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Cities', id: 'LIST'}],
    }),
    replaceCity: builder.mutation({
      query: body => ({
        url: `cities`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Cities', id: 'LIST'}],
    }),
    getProvinces: builder.query({
      query: () => `provinces`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'Provinces', id: _id})),
              {type: 'Provinces', id: 'LIST'},
            ]
          : [{type: 'Provinces', id: 'LIST'}],
    }),
    addProvince: builder.mutation({
      query: body => ({
        url: `provinces`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Provinces', id: 'LIST'}],
    }),
    deleteProvince: builder.mutation({
      query: id => ({
        url: `provinces/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Provinces', id: 'LIST'}],
    }),
    replaceProvince: builder.mutation({
      query: body => ({
        url: `provinces`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Provinces', id: 'LIST'}],
    }),
    getProvincesByCountryId: builder.query({
      query: id => `provinces/${id}`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'Provinces', id: _id})),
              {type: 'Provinces', id: 'LIST'},
            ]
          : [{type: 'Provinces', id: 'LIST'}],
    }),
    getCompanyMember: builder.query({
      query: id => `companies/member/${id}`,
      providesTags: result =>
        result
          ? [...result.map(({_id}) => ({type: 'Users', id: _id})), {type: 'Users', id: 'LIST'}]
          : [{type: 'Users', id: 'LIST'}],
    }),
    getCompanyTypes: builder.query({
      query: () => `companyTypes`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'CompanyTypes', id: _id})),
              {type: 'CompanyTypes', id: 'LIST'},
            ]
          : [{type: 'CompanyTypes', id: 'LIST'}],
    }),
    addCompanyType: builder.mutation({
      query: body => ({
        url: `companyTypes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'CompanyTypes', id: 'LIST'}],
    }),
    deleteCompanyType: builder.mutation({
      query: id => ({
        url: `companyTypes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'CompanyTypes', id: 'LIST'}],
    }),
    replaceCompanyType: builder.mutation({
      query: body => ({
        url: `companyTypes`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'CompanyTypes', id: 'LIST'}],
    }),
    getIndustrialProcesses: builder.query({
      query: () => `industrialProcess`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'IndustrialProcesses', id: _id})),
              {type: 'IndustrialProcesses', id: 'LIST'},
            ]
          : [{type: 'IndustrialProcesses', id: 'LIST'}],
    }),
    addIndustrialProcess: builder.mutation({
      query: body => ({
        url: `industrialProcess`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'IndustrialProcesses', id: 'LIST'}],
    }),
    deleteIndustrialProcess: builder.mutation({
      query: id => ({
        url: `industrialProcess/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'IndustrialProcesses', id: 'LIST'}],
    }),
    replaceIndustrialProcess: builder.mutation({
      query: body => ({
        url: `industrialProcess`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'IndustrialProcesses', id: 'LIST'}],
    }),
    getCompanies: builder.query({
      query: () => `companies`,
      providesTags: result =>
        result
          ? [
              ...result.map(({_id}) => ({type: 'Companies', id: _id})),
              {type: 'Companies', id: 'LIST'},
            ]
          : [{type: 'Companies', id: 'LIST'}],
    }),
    getCompanyProducts: builder.query({
      query: id => `/companies/${id}/products`,
      providesTags: result =>
        result
          ? [
              ...result.data?.map(({_id}) => ({type: 'Products', id: _id})),
              {type: 'Products', id: 'LIST'},
            ]
          : [{type: 'Products', id: 'LIST'}],
    }),
    addCompany: builder.mutation({
      query: body => ({
        url: `companies`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Companies', id: 'LIST'}],
    }),
    addImageIntoGallery: builder.mutation({
      query: body => ({
        url: `companies/gallery`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Companies', id: 'LIST'}],
    }),
    deleteCompany: builder.mutation({
      query: id => ({
        url: `companies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Companies', id: 'LIST'}],
    }),
    replaceCompany: builder.mutation({
      query: body => ({
        url: `companies`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Companies', id: 'LIST'}],
    }),
    getFeaturedProductsLists: builder.query({
      query: () => `/products/features-list`,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'FeaturedProductsLists', id: _id})),
              {type: 'FeaturedProductsLists', id: 'LIST'},
            ]
          : [{type: 'FeaturedProductsLists', id: 'LIST'}],
    }),
    addFeaturedProductsList: builder.mutation({
      query: body => ({
        url: `/products/features-list`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'FeaturedProductsLists', id: 'LIST'}],
    }),
    deleteFeaturedProductsList: builder.mutation({
      query: id => ({
        url: `/products/features-list/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'FeaturedProductsLists', id: 'LIST'}],
    }),
    replaceFeaturedProductsList: builder.mutation({
      query: body => ({
        url: `/products/features-list`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'FeaturedProductsLists', id: 'LIST'}],
    }),
    getProductById: builder.mutation({
      query: id => ({
        url: `/products/${id}`,
        method: 'GET',
      }),
      invalidatesTags: [{type: 'Products', id: 'LIST'}],
    }),
    addProduct: builder.mutation({
      query: body => ({
        url: `products`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Products', id: 'LIST'}],
    }),
    updateProduct: builder.mutation({
      query: body => ({
        url: `products`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Products', id: 'LIST'}],
    }),
    deleteProduct: builder.mutation({
      query: id => ({
        url: `products/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Products', id: 'LIST'}],
    }),
    getCompanyById: builder.query({
      query: id => ({
        url: `/companies/${id}`,
        method: 'GET',
      }),
      providesTags: [{type: 'Companies', id: 'LIST'}],
    }),
    getBanners: builder.query({
      query: () => `banners`,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'Banners', id: _id})),
              {type: 'Banners', id: 'LIST'},
            ]
          : [{type: 'Banners', id: 'LIST'}],
    }),
    addBanner: builder.mutation({
      query: body => ({
        url: `/banners`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Banners', id: 'LIST'}],
    }),
    getNewsFeeds: builder.query({
      query: () => `/newsFeeds`,
      providesTags: result =>
        result
          ? [
              ...result.data.map(({_id}) => ({type: 'NewsFeeds', id: _id})),
              {type: 'NewsFeeds', id: 'LIST'},
            ]
          : [{type: 'NewsFeeds', id: 'LIST'}],
    }),
    addNewsFeed: builder.mutation({
      query: body => ({
        url: `/newsFeeds`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'NewsFeeds', id: 'LIST'}],
    }),
    deleteNewsFeed: builder.mutation({
      query: id => ({
        url: `/newsFeeds/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'NewsFeeds', id: 'LIST'}],
    }),
    replaceNewsFeed: builder.mutation({
      query: body => ({
        url: `/newsFeeds`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'NewsFeeds', id: 'LIST'}],
    }),
    addMiniSiteLayout: builder.mutation({
      query: body => ({
        url: `/miniSite`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Companies', id: 'LIST'}],
    }),
    deleteBanner: builder.mutation({
      query: id => ({
        url: `/banners/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Banners', id: 'LIST'}],
    }),
    replaceBanner: builder.mutation({
      query: body => ({
        url: `/banners`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{type: 'Banners', id: 'LIST'}],
    }),
    getMiniSiteLayoutById: builder.query({
      query: id => ({
        url: `/miniSite/${id}`,
        method: 'GET',
      }),
    }),
    getMiniSiteLayoutByCompanyId: builder.query({
      query: id => ({
        url: `/miniSiteByCompany/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCompanyByIdQuery,
  useGetCompanyMemberQuery,
  useGetMyTradrMembershipQuery,
  useGetRolesQuery,
  useGetUsersQuery,
  useGetCategoriesQuery,
  useGetRootCategoriesQuery,
  useGetFeaturedCategoriesQuery,
  useGetCategoriesByParentQuery,
  useGetCountriesQuery,
  useGetCompanyTypesQuery,
  useDeleteCategoryMutation,
  useDeleteFeaturedCategoryMutation,
  useDeleteCountryMutation,
  useDeleteCompanyTypeMutation,
  useDeleteRoleMutation,
  useDeleteProductMutation,
  useAddRoleMutation,
  useAddCategoryMutation,
  useAddFeaturedCategoryMutation,
  useAddCountryMutation,
  useAddCompanyTypeMutation,
  useReplaceRoleMutation,
  useReplaceCategoryMutation,
  useReplaceFeaturedCategoryMutation,
  useReplaceCountryMutation,
  useReplaceCompanyTypeMutation,
  useGetUploadUrlQuery,
  useLazyGetUploadUrlQuery,
  useGetCitiesQuery,
  useDeleteCityMutation,
  useAddCityMutation,
  useReplaceCityMutation,
  useGetProvincesQuery,
  useGetProvincesByCountryIdQuery,
  useDeleteProvinceMutation,
  useAddCompanyMemberMutation,
  useAddProvinceMutation,
  useAddProductMutation,
  useUpdateProductMutation,
  useReplaceProvinceMutation,
  useGetIndustrialProcessesQuery,
  useDeleteIndustrialProcessMutation,
  useAddIndustrialProcessMutation,
  useReplaceIndustrialProcessMutation,
  useGetCompaniesQuery,
  useDeleteCompanyMutation,
  useAddCompanyMutation,
  useReplaceCompanyMutation,
  useGetFeaturedProductsListsQuery,
  useDeleteFeaturedProductsListMutation,
  useAddFeaturedProductsListMutation,
  useGetProductByIdQuery,
  useGetCompanyProductsQuery,
  useReplaceFeaturedProductsListMutation,
  useGetBannersQuery,
  useDeleteBannerMutation,
  useAddBannerMutation,
  useReplaceBannerMutation,
  useGetNewsFeedsQuery,
  useDeleteNewsFeedMutation,
  useAddNewsFeedMutation,
  useReplaceNewsFeedMutation,
  useGetUOMsQuery,
  useDeleteUOMMutation,
  useAddUOMMutation,
  useReplaceUOMMutation,
  useGetTradrMembersQuery,
  useDeleteTradrMemberMutation,
  useAddTradrMemberMutation,
  useReplaceTradrMemberMutation,
  useGetCertificationsQuery,
  useDeleteCertificateMutation,
  useAddCertificateMutation,
  useReplaceCertificateMutation,
  useGetTradeTermsQuery,
  useDeleteTradeTermMutation,
  useAddTradeTermMutation,
  useReplaceTradeTermMutation,
  useGetSTypesQuery,
  useDeleteSTypeMutation,
  useAddSTypeMutation,
  useReplaceSTypeMutation,
  useGetOrganizationsQuery,
  useDeleteOrganizationMutation,
  useAddOrganizationMutation,
  useReplaceOrganizationMutation,
  useGetShippingMethodsQuery,
  useDeleteShippingMethodMutation,
  useAddShippingMethodMutation,
  useReplaceShippingMethodMutation,
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useAddPaymentMethodMutation,
  useReplacePaymentMethodMutation,
  useGetCurrenciesQuery,
  useDeleteCurrencyMutation,
  useAddCurrencyMutation,
  useReplaceCurrencyMutation,
  useGetMiniSiteCategoriesQuery,
  useDeleteMiniSiteCategoryMutation,
  useAddMiniSiteCategoryMutation,
  useReplaceMiniSiteCategoryMutation,
  useAddMiniSiteLayoutMutation,
  useAddHomeLayoutMutation,
  useGetHomeLayoutQuery,
  useAddImageIntoGalleryMutation,
} = serverApi;
