import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  title: Yup.string().required('title is required'),
  productsIds: Yup.array()
    .of(Yup.string().required())
    .required('Minimum 1 Product is required')
    .min(1),
  notes: Yup.string(),
});

function MiniSiteCategoryForm({initialValues = {}, onSubmit, products}, ref) {
  const formik = useFormik({
    initialValues: {
      title: '',
      productsIds: [],
      notes: '',
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        size="small"
        id="title"
        title="title"
        label="Title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        sx={{mb: 2, mt: 1}}
      />
      <FormControl sx={{mb: 1}} size="small" fullWidth>
        <InputLabel id="unit-label">Products</InputLabel>
        <Select
          labelId="productsIds"
          multiple
          id="productsIds"
          name="productsIds"
          placeholder="Select Products"
          value={formik.values.productsIds}
          label="Products"
          onChange={formik.handleChange}
          error={formik.touched.productsIds && Boolean(formik.errors.productsIds)}
        >
          {products?.map(product => (
            <MenuItem key={product._id} value={product._id}>
              {`${product.name}`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {formik.touched.productsIds && formik.errors.productsIds}
        </FormHelperText>
      </FormControl>
      <TextField
        multiline
        fullWidth
        id="notes"
        name="notes"
        label="Notes"
        value={formik.values.notes}
        onChange={formik.handleChange}
        error={formik.touched.notes && Boolean(formik.errors.notes)}
        helperText={formik.touched.notes && formik.errors.notes}
        rows={4}
      />
    </form>
  );
}

const ForwardedRefMiniSiteCategoryForm = React.forwardRef(MiniSiteCategoryForm);

export default ForwardedRefMiniSiteCategoryForm;
