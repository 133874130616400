import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider} from '@mui/material';
import React from 'react';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ImageSelectionModal from 'components/ImagesSelectionModal';
import SlideForm from './SlideForm';

function SlidersModal({
  open,
  onClose,
  formik,
  index,
  tab,
  images,
  sliders,
  handleSelectedImages,
  slideIndex,
  errors,
}) {
  const [isChild, setIsChild] = React.useState(false);

  return (
    <>
      <ImageSelectionModal
        open={isChild}
        images={images}
        handleSelectedImages={handleSelectedImages}
        onClose={() => {
          setIsChild(false);
        }}
      />
      <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle variant="h6">Update Slide</DialogTitle>
        <DialogContent>
          <Divider />
          <SlideForm
            formik={formik}
            index={index}
            sliders={sliders}
            tab={tab}
            slideIndex={slideIndex}
            errors={errors?.[index]?.slider?.[slideIndex]}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} startIcon={<SaveAsIcon />}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SlidersModal;
