import React, {useState} from 'react';
import {Button, Col, Form, Image, Row, Spinner} from 'react-bootstrap';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import {useGetCompanyByIdQuery, useAddImageIntoGalleryMutation} from 'api';
import {Dialog, DialogActions, DialogContent} from '@mui/material';

const UploadImage = ({show, handleClose, insert}) => {
  const {id} = useParams();
  const {data} = useGetCompanyByIdQuery(id);
  const {enqueueSnackbar} = useSnackbar();
  const [addImage] = useAddImageIntoGalleryMutation();
  const [activeBtn, setActiveBtn] = useState('gallery-img');
  const [imglink, setEmlink] = useState('');
  const [selectedImg, setSelectedImg] = useState('');
  const [uploadImage, setUploadImage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const uploadGalleryImg = async e => {
    try {
      const files = e.target.files[0];
      const formData = new FormData();
      formData.append('companyId', id);
      formData.append('images', files);
      formData.append('tags', JSON.stringify([]));
      formData.append('alt', '');
      formData.append('notes', '');
      const res = await addImage(formData).unwrap();
      setUploadImage(`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${res.urls[0]}/public`);
      setIsLoading(true);
    } catch (error) {
      setIsLoading(true);
      const message = e.status === 400 ? error.bannerResponse?.details?.[0]?.message : undefined;
      enqueueSnackbar(message || 'Failed to Add Image', {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={show} onClose={handleClose} disableEnforceFocus>
      <DialogContent dividers>
        <Row>
          <Col sm={4} className="p-0 ">
            <div className="uploadBtn_Box p-2">
              <Button
                variant="link"
                className={`d-block w-100 mb-2 text-decoration-none ${
                  activeBtn === 'gallery-img' ? 'activeBtn' : null
                }`}
                onClick={() => setActiveBtn('gallery-img')}
              >
                Saved images
              </Button>
              <Button
                variant="link"
                className={`d-block w-100 mb-2 text-decoration-none ${
                  activeBtn === 'upload-img' ? 'activeBtn' : null
                }`}
                onClick={() => setActiveBtn('upload-img')}
              >
                Upload
              </Button>
              <Button
                variant="link"
                className={`d-block w-100 mb-2 text-decoration-none ${
                  activeBtn === 'insert-img' ? 'activeBtn' : null
                }`}
                onClick={() => setActiveBtn('insert-img')}
              >
                From URL
              </Button>
            </div>
          </Col>
          <Col sm={8}>
            <div className="uploadContainer">
              {activeBtn === 'insert-img' && (
                <div className="insertImg">
                  {' '}
                  <h6>Paste an image URL here:</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Group controlId="formBasicEmail" className="mb-0 w-100 mr-3">
                      <Form.Control type="text" onChange={e => setEmlink(e.target.value)} />
                    </Form.Group>
                    <Button
                      variant="primary"
                      onClick={() => {
                        insert(`<img src=${imglink} alt=""/>`);
                        setEmlink('');
                        handleClose();
                      }}
                    >
                      Insert
                    </Button>
                  </div>
                  <div className="w-100 mt-3 text-center upload-imgBox">
                    {imglink ? (
                      <Image src={imglink} alt="" fluid />
                    ) : (
                      <p>
                        If your URL is correct, you will see an image preview here. Large images may
                        take a few minutes to appear.
                      </p>
                    )}
                  </div>
                </div>
              )}
              {activeBtn === 'gallery-img' && (
                <div className="imgGallery">
                  <Row>
                    {data?.data?.gallery?.map((img, i) => (
                      <Col md={4} className="mt-3" key={img?._id}>
                        <Image
                          src={`${`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${img?.image}/public`}?w=164&h=164&fit=crop&auto=format`}
                          alt=""
                          fluid
                          className={`gallery_Img ${
                            selectedImg === `selectedImg${i}` ? 'selectedImg' : null
                          }`}
                          onClick={() => {
                            setSelectedImg(`selectedImg${i}`);
                            handleClose();
                            insert(
                              `<img src=https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${img.image}/public alt=""/>`
                            );
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}{' '}
              {activeBtn === 'upload-img' && (
                <div className="upload-img">
                  <Form className="mb-3">
                    <input
                      type="file"
                      className="w-75"
                      onChange={e => {
                        uploadGalleryImg(e);
                        setIsLoading(false);
                      }}
                    />
                    <Button
                      variant="primary"
                      onClick={() => {
                        if (uploadImage) {
                          insert(`<img src=${uploadImage} alt=""/>`);
                          setUploadImage('');
                          handleClose();
                        }
                      }}
                    >
                      Insert
                    </Button>
                  </Form>
                  <div className="mt-2 w-100">
                    {isLoading ? (
                      <img src={uploadImage} alt="" className="img-fluid" />
                    ) : (
                      <div className="w-100 spinnerBox d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadImage;
