import React from 'react';
import {Button, Paper, Skeleton, CircularProgress} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  useReplaceCompanyMutation,
  useGetCountriesQuery,
  useGetCompanyTypesQuery,
  useGetProvincesQuery,
  useGetCitiesQuery,
  useLazyGetUploadUrlQuery,
  useGetCertificationsQuery,
  useGetCurrenciesQuery,
  useGetCompanyByIdQuery,
} from 'api';

import CompanyForm from 'features/companies/components/CompanyForm';
import {useParams} from 'react-router-dom';

export function CompanyOverview() {
  const {id} = useParams();
  const {data: company, isLoading} = useGetCompanyByIdQuery(id);
  const {data: countriesList} = useGetCountriesQuery();
  const {data: companyTypesList} = useGetCompanyTypesQuery();
  const {data: provincesList} = useGetProvincesQuery();
  const {data: currenciesList} = useGetCurrenciesQuery();
  const {data: certificationsList} = useGetCertificationsQuery();
  const {data: citiesList} = useGetCitiesQuery();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [replaceCompany] = useReplaceCompanyMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();

  const formRef = React.useRef();
  const {enqueueSnackbar} = useSnackbar();

  return (
    <Paper sx={{p: 2}}>
      {!isLoading ? (
        <>
          <CompanyForm
            companyTypesList={companyTypesList}
            countriesList={countriesList}
            currenciesList={currenciesList || []}
            provincesList={provincesList}
            certificationsList={certificationsList?.data || []}
            citiesList={citiesList}
            initialValues={{
              _id: company?.data?._id,
              name: company?.data?.name,
              slug: company?.data?.slug,
              totalEmployees: company?.data?.totalEmployees,
              businessType: company?.data?.businessType,
              logoUrl: company?.data?.logo,
              certificates: company?.data?.certificates || [],
              logo: `https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${company?.data?.logo}/public`,
              // featuredBanner: null,
              yearEstablished: company?.data?.yearEstablished,
              totalAnnualRevenue: {
                currency: company?.data?.totalAnnualRevenue.currency,
                amount: company?.data?.totalAnnualRevenue.amount,
              },
              mainAddress: {
                addressLine1: company?.data?.mainAddress.addressLine1,
                city: company?.data?.mainAddress.city,
                province: company?.data?.mainAddress.province,
                country: company?.data?.mainAddress.country,
              },
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const formData = new FormData();
                let uploadResult = null;
                if (values.logo.file) {
                  formData.append(`file`, values.logo.file);
                  const {url} = await getUploadUrl().unwrap();
                  const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                  });
                  if (!response.ok) {
                    throw new Error('Failed to upload logo.');
                  }
                  uploadResult = await response.json();
                }
                const newObj = {
                  ...values,
                  logo: uploadResult ? uploadResult.result.id : values.logoUrl,
                };
                if (!values.logo.file) {
                  delete newObj.logoUrl;
                }
                await replaceCompany(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('company updated successfully', {
                  variant: 'success',
                });
              } catch (e) {
                const message =
                  e.status === 400 ? e.companyResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to update company', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
          <Button
            endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
            disabled={isSubmitting}
            color="primary"
            variant="contained"
            sx={{
              width: '100%',
            }}
            onClick={() => formRef.current?.submit?.()}
          >
            Save
          </Button>
        </>
      ) : (
        [...Array(10)].map(() => (
          <Skeleton
            variant="h1"
            sx={{
              width: '100%',
              height: '37px',
              borderRadius: '4px',
              marginBottom: '16px',
            }}
          />
        ))
      )}
    </Paper>
  );
}

export default CompanyOverview;
