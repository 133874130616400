import {FormHelperText, TextField, Box, Autocomplete, Chip} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {ColorPicker} from 'mui-color';
import RichEditor from 'components/RichEditor/RichEditor';
import FeaturedProductsListIconDropzone from './FeaturedProductsListIconDropzone';

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  boxColor: Yup.string().required('Color is required'),
  description: Yup.string(),
  productsId: Yup.array().of(Yup.string().trim().required()).min(1).required(),
  icon: Yup.mixed()
    .required('Icon is required')
    .test('icon-valid', (value, {createError, path}) => {
      if (value?.error) {
        return createError({
          path,
          message: value.error,
        });
      }
      return true;
    }),
});

function FeaturedProductsListForm({initialValues = {}, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      icon: null,
      productsId: [],
      boxColor: '#E2A37E',
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });
  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="title"
        name="title"
        label="Title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        sx={{mb: 2}}
      />
      <RichEditor
        height="300px"
        value={formik.values.description}
        onChange={html => {
          formik.setFieldValue('description', html);
          formik.setFieldTouched('description', true);
        }}
      />
      {/* <TextField
        multiline
        fullWidth
        id="description"
        name="description"
        label="Description"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        sx={{mb: 2}}
        rows={4}
      /> */}
      <Autocomplete
        onChange={(e, values) => {
          formik.setFieldValue('productsId', values);
        }}
        onBlur={formik.handleBlur}
        fullWidth
        multiple
        freeSolo
        name="productsId"
        defaultValue={formik.values.productsId}
        options={[]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              color="primary"
              label={option}
              size="small"
              {...getTagProps({index})}
            />
          ))
        }
        getOptionLabel={option => option.title}
        filterSelectedOptions
        sx={{mb: 2, mt: 2}}
        renderInput={params => (
          <TextField {...params} label="Products" placeholder="Type product id here ..." />
        )}
      />
      <FormHelperText error>{formik.touched.productsId && formik.errors.productsId}</FormHelperText>
      <ColorPicker
        fullWidth
        id="boxColor"
        name="boxColor"
        label="boxColor"
        value={formik.values.boxColor}
        onChange={color => {
          formik.setFieldValue('boxColor', `#${color.hex}`);
        }}
        error={formik.touched.boxColor && Boolean(formik.errors.boxColor)}
        helperText={formik.touched.productsId && formik.errors.productsId}
      />
      <Box sx={{mt: 2}}>
        <FeaturedProductsListIconDropzone
          previewSrc={formik.values.icon}
          onDropAccepted={file => {
            formik.setFieldValue('icon', {file});
          }}
          onDropRejected={(file, errors) => {
            formik
              .setFieldValue('icon', {file, error: errors?.[0]?.message || 'Icon is invalid'})
              .then(() => {
                formik.setFieldTouched('icon', true);
              });
          }}
        />
        <FormHelperText sx={{mt: 1, ml: 1}} error>
          {formik.touched.icon && formik.errors.icon}
        </FormHelperText>
      </Box>
    </form>
  );
}

const ForwardedRefFeaturedProductsListForm = React.forwardRef(FeaturedProductsListForm);

export default ForwardedRefFeaturedProductsListForm;
