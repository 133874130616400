import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useAddRoleMutation} from 'api';
import RoleForm from './RoleForm';

function CreateRoleModal({open, onClose}) {
  const formRef = React.useRef();
  const [addRole, addRoleResult] = useAddRoleMutation();

  const {enqueueSnackbar} = useSnackbar();

  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        Create Role
      </DialogTitle>
      <DialogContent>
        <RoleForm
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              const data = await addRole(values).unwrap();
              actions.resetForm();
              enqueueSnackbar(data.successMessage || 'Role created successfully', {
                variant: 'success',
              });
            } catch (e) {
              const message = e.status === 400 ? e.data?.details?.[0]?.message : undefined;
              enqueueSnackbar(message || 'Failed to create a role', {
                variant: 'error',
              });
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={addRoleResult.isLoading}
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          endIcon={addRoleResult.isLoading ? <CircularProgress ml={2} size={16} /> : null}
          disabled={addRoleResult.isLoading}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateRoleModal;
