import {ListItem, Skeleton} from '@mui/material';
import React from 'react';
import {ReactSortable} from 'react-sortablejs';
import WidgetCard from './WidgetCard';

function Chips({
  formik,
  isLoading = false,
  deleteWidget,
  handleFPListModal,
  handleBannersModal,
  handleCompaniesModal,
  errors,
}) {
  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="h1"
          sx={{
            width: '100%',
            height: '37px',
          }}
        />
      ) : (
        <ReactSortable
          style={{width: '100%'}}
          list={JSON.parse(JSON.stringify([...formik?.values?.layout]))}
          setList={newOrder => {
            const newSortedTypes = newOrder?.map(data => {
              const newData = {...data};
              delete newData.chosen;
              delete newData.selected;
              return newData;
            });
            formik.setFieldValue('layout', newSortedTypes);
          }}
        >
          {formik?.values?.layout?.length > 0 &&
            formik?.values?.layout?.map((widget, index) => (
              <ListItem component="div" key={widget}>
                <WidgetCard
                  deleteWidget={deleteWidget}
                  handleFPListModal={handleFPListModal}
                  handleBannersModal={handleBannersModal}
                  handleCompaniesModal={handleCompaniesModal}
                  object={widget}
                  index={index}
                  error={errors?.[index]}
                />
              </ListItem>
            ))}
        </ReactSortable>
      )}
    </>
  );
}

export default Chips;
