import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useAddCountryMutation, useGetCountriesQuery} from 'api';
import CountryForm from './CountryForm';

function CreateCountryModal({open, onClose}) {
  const {isLoading, isError} = useGetCountriesQuery();
  const formRef = React.useRef();
  const [addCountry] = useAddCountryMutation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        Create Country
      </DialogTitle>
      <DialogContent>
        <CountryForm
          disabled={isLoading || isError}
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const formData = new FormData();
              formData.append('flag', values.flag?.file);
              formData.append('name', values.name);
              await addCountry(formData).unwrap();
              actions.resetForm();
              enqueueSnackbar('Country created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message =
                e.status === 400 ? e.countryResponse?.details?.[0]?.message : undefined;
              enqueueSnackbar(message || 'Failed to create Country', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCountryModal;
