import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useAddSTypeMutation, useGetSTypesQuery} from 'api';
import STypeForm from './STypeForm';

function CreateSTypeModal({open, onClose}) {
  const {data, isSuccess, isLoading, isError} = useGetSTypesQuery();
  const formRef = React.useRef();
  const [addSType] = useAddSTypeMutation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const parentsList = React.useMemo(() => {
    if (isSuccess) {
      return data.data.map(sType => sType.title);
    }
    return [];
  }, [data, isSuccess]);
  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        Create Sourcing Type
      </DialogTitle>
      <DialogContent>
        <STypeForm
          disabled={isLoading || isError}
          parentsList={parentsList}
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const newObj = {
                ...values,
              };
              await addSType(newObj).unwrap();
              actions.resetForm();
              enqueueSnackbar('SType created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message = e.status === 400 ? e.sTypeResponse?.details?.[0]?.message : undefined;
              enqueueSnackbar(message || 'Failed to create SType', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateSTypeModal;
