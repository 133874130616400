import {
  Box,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useGetCompanyByIdQuery} from 'api';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageModal from '../components/Gallery/AddImageModal';

export function CompanyGallery() {
  const {id} = useParams();
  const {data} = useGetCompanyByIdQuery(id);
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <ImageModal
        open={isOpen}
        companyId={id}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <Paper>
        <Box sx={{p: 2}}>
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              'justify-content': 'space-between',
            }}
          >
            <Typography variant="h6">Company Gallery</Typography>
            <IconButton
              color="primary"
              aria-label="upload picture"
              onClick={() => {
                setIsOpen(true);
              }}
              component="label"
            >
              <PhotoCamera />
            </IconButton>
          </Stack>
          <Divider sx={{my: 2}} />
          <Grid container spacing={2} pl={3}>
            <ImageList sx={{width: '100%', height: 450}} variant="quilted" cols={4} rowHeight={121}>
              {data?.data?.gallery?.map(item => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${item.image}/public`}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${item.image}/public`}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.alt}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

export default CompanyGallery;
