import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplaceCountryMutation} from 'api';
import {getImageURL} from '../../../utils/imageUrl';
import CountryForm from './CountryForm';

function CountryEditIconButton({country}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceCountry] = useReplaceCountryMutation();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isModalOpen}>
        <DialogTitle>Update Country</DialogTitle>
        <DialogContent dividers>
          <CountryForm
            initialValues={{
              _id: country._id,
              name: country.name,
              flag: getImageURL(country.flag),
              existingFlagId: country.flag,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const formData = new FormData();
                formData.append('_id', values._id);
                if (values.flag?.file) {
                  formData.append('flag', values.flag?.file);
                } else {
                  formData.append('existingFlagId', values.existingFlagId);
                }
                formData.append('name', values.name);
                await replaceCountry(formData).unwrap();
                actions.resetForm();
                enqueueSnackbar('Country updated successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.countryResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to update Country', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default CountryEditIconButton;
