import SettingsIcon from '@mui/icons-material/Settings';
import {GridActionsCellItem} from '@mui/x-data-grid';
import React from 'react';
import UpdateCategoryModal from './UpdateCategoryModal';

function EditQuestionCellItem({row}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <>
      <UpdateCategoryModal
        category={row}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <GridActionsCellItem
        icon={<SettingsIcon />}
        onClick={() => {
          setIsModalOpen(true);
        }}
        label="Update"
      />
    </>
  );
}

export default EditQuestionCellItem;
