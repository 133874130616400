import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {useDeleteBannerMutation} from 'api';
import {useSnackbar} from 'notistack';

function BannerDeleteIconButton({bannerId}) {
  const [deleteBanner, deleteBannerResult] = useDeleteBannerMutation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleDelete = async () => {
    try {
      await deleteBanner(bannerId).unwrap();
      enqueueSnackbar('Banner deleted', {variant: 'success'});
      setIsModalOpen(false);
    } catch (error) {
      const message = error.status === 400 ? error.data?.details?.[0]?.message : undefined;
      enqueueSnackbar(message || `Error deleting Banner`, {variant: 'error'});
    }
  };

  return (
    <>
      <Dialog open={isModalOpen} maxWidth="xs">
        <DialogTitle>Delete Banner</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this Banner?</DialogContent>
        <DialogActions>
          <Button
            disabled={deleteBannerResult.isLoading}
            autoFocus
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={deleteBannerResult.isLoading}
            color="error"
            endIcon={deleteBannerResult.isLoading ? <CircularProgress size={16} /> : null}
            onClick={handleDelete}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        disabled={deleteBannerResult.isLoading}
        aria-label="delete"
        color="error"
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
}

export default BannerDeleteIconButton;
