import {
  Button,
  CircularProgress,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  IconButton,
  styled,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {ReactSortable} from 'react-sortablejs';
import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useReplaceFeaturedCategoryMutation} from 'api';
import {getImageURL} from '../../../utils/imageUrl';

const MuiReactSortable = styled(ReactSortable)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'wrap',
  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
  '& > *': {
    width: '100%',
  },
}));

function BannersView({bannersList = [], open, onClose, isSubmitting, setIsSubmitting}) {
  // const [fullWidth] = React.useState(true);
  const [replaceFeaturedCategoryList] = useReplaceFeaturedCategoryMutation();
  const {enqueueSnackbar} = useSnackbar();
  const [chipBanners, setChipBanners] = useState([]);
  React.useEffect(() => {
    setChipBanners([...(bannersList.banners || [])].map(obj => ({...obj})));
  }, [bannersList]);
  const handleUpdate = async () => {
    setIsSubmitting(true);
    const filteredArray = chipBanners.map(item => ({
      _id: item._id,
      alt: item.alt,
      image: item.image,
      url: item.url,
    }));
    const newObj = {...bannersList};
    newObj.banners = filteredArray;
    delete newObj._rev;
    delete newObj.products;
    try {
      await replaceFeaturedCategoryList(newObj).unwrap();
      enqueueSnackbar('Banners Updated successfully', {
        variant: 'success',
      });
    } catch {
      enqueueSnackbar('Failed to Updated Banners', {
        variant: 'error',
      });
    }

    onClose();
    setIsSubmitting(false);
  };

  const handleClose = () => {
    onClose();
    setChipBanners([...(bannersList.banners || [])].map(obj => ({...obj})));
  };

  return (
    <>
      <Dialog open={open} fullWidth onClose={handleClose} maxWidth="sm">
        <DialogTitle>{`${bannersList.name}'s Banners`}</DialogTitle>
        <DialogContent dividers>
          <MuiReactSortable
            list={chipBanners}
            setList={newBannersList => {
              setChipBanners(newBannersList);
            }}
          >
            {chipBanners.map(item => (
              <div key={item._id}>
                <Chip
                  sx={{
                    width: '100%',
                  }}
                  avatar={<Avatar align="left" alt={item.alt} src={getImageURL(item.image)} />}
                  label={item.alt || ''}
                  variant="outlined"
                  onDelete={() => {
                    setChipBanners(prevBanners => prevBanners.filter(({_id}) => _id !== item._id));
                  }}
                  deleteIcon={
                    <IconButton sx={{ml: 'auto !important'}} aria-label="delete">
                      <ClearIcon color="error" />
                    </IconButton>
                  }
                />
              </div>
            ))}
          </MuiReactSortable>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
            disabled={isSubmitting}
            color="primary"
            variant="contained"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BannersView;
