import {FormHelperText, TextField, Box} from '@mui/material';
import React from 'react';
import {Controller} from 'react-hook-form';
import NewsFeedIconDropzone from './NewsFeedIconDropzone';

function NewsFeedForm({setValue, control, watch, errors, onSubmit}) {
  const {icon} = watch();
  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="title"
        control={control}
        render={({field}) => (
          <TextField
            fullWidth
            size="small"
            name="title"
            label="title"
            sx={{mb: 2, mt: 1}}
            {...field}
            helperText={errors.title?.message}
            error={errors.title?.message}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({field}) => (
          <TextField
            fullWidth
            size="small"
            name="description"
            label="SubTitle"
            sx={{mb: 2}}
            {...field}
            helperText={errors.description?.message}
            error={errors.description?.message}
          />
        )}
      />
      <Controller
        name="url"
        control={control}
        render={({field}) => (
          <TextField
            fullWidth
            size="small"
            name="url"
            label="url"
            sx={{mb: 2}}
            {...field}
            helperText={errors.url?.message}
            error={errors.url?.message}
          />
        )}
      />
      <Box>
        <NewsFeedIconDropzone
          previewSrc={icon}
          onDropAccepted={file => {
            setValue('icon', {file});
          }}
          onDropRejected={(file, error) => {
            setValue('icon', {file, error: error?.[0]?.message || 'Icon is invalid'});
          }}
        />
        <FormHelperText sx={{mt: 1, ml: 1}} error>
          {errors.icon?.message}
        </FormHelperText>
      </Box>
    </form>
  );
}

const ForwardedRefNewsFeedForm = React.forwardRef(NewsFeedForm);

export default ForwardedRefNewsFeedForm;
