import * as React from 'react';
import {Autocomplete, TextField} from '@mui/material';

export default function ProductsChips({products = [], handleChip, productsList = [], errors}) {
  return (
    <Autocomplete
      multiple
      fullWidth
      limitTags={2}
      isOptionEqualToValue={(option, value) => option.slug === value.slug}
      onChange={(e, values) => {
        handleChip(e, values);
      }}
      id="productsList"
      options={productsList}
      getOptionLabel={option => option.title}
      value={products}
      renderInput={params => (
        <TextField
          {...params}
          sx={{
            mb: 2,
            mt: 2,
            '& label.Mui-focused': {
              color: theme => (errors ? theme.palette.error.main : undefined),
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: theme => (errors ? theme.palette.error.main : undefined),
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme => (errors ? theme.palette.error.main : undefined),
              },
              '&:hover fieldset': {
                borderColor: theme => (errors ? theme.palette.error.main : undefined),
              },
              '&.Mui-focused fieldset': {
                borderColor: theme => (errors ? theme.palette.error.main : undefined),
              },
            },
          }}
          label="Products"
        />
      )}
    />
  );
}
