import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplacePaymentMethodMutation} from 'api';
import PaymentMethodForm from './PaymentMethodForm';

function PaymentMethodEditIconButton({PaymentMethod}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replacePaymentMethod] = useReplacePaymentMethodMutation();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isModalOpen}>
        <DialogTitle>Update Payment Method</DialogTitle>
        <DialogContent dividers>
          <PaymentMethodForm
            initialValues={{
              _id: PaymentMethod._id,
              title: PaymentMethod.title,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const newObj = {
                  ...values,
                };
                await replacePaymentMethod(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('PaymentMethod updated successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.PaymentMethodResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to update PaymentMethod', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default PaymentMethodEditIconButton;
