import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LoginButton from 'components/Button/LoginButton';
import {useAuth0} from '@auth0/auth0-react';
import LogoutButton from 'components/Button/LogoutButton';
import Link from 'components/Link/Link';
import {Stack} from '@mui/material';
import MenuLink from 'components/Link/MenuLink';
import tradrLogo from '../../assets/images/Tradr-logo.png';

export default function Navbar() {
  const {isAuthenticated, isLoading} = useAuth0();

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar color="default" position="static">
        <Toolbar>
          <Typography sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}} variant="h6">
            <Link sx={{width: 80, height: 52}} to="/">
              <Box
                sx={{width: '100%', objectFit: 'contain'}}
                component="img"
                src={tradrLogo}
                alt="logo"
              />
            </Link>
          </Typography>
          <Stack direction="row" alignItems="center" spacing={3}>
            {/* <MenuLink to="/authorization">Authorization</MenuLink> */}
            <MenuLink to="/companies">Companies</MenuLink>
            <MenuLink to="/marketing">Marketing</MenuLink>
            <MenuLink to="/finance">Finance</MenuLink>
            <MenuLink to="/support">Support</MenuLink>
            <MenuLink to="/blog">Blog</MenuLink>
            <MenuLink to="/tradr-settings">Tradr Settings</MenuLink>
            {!isAuthenticated && (
              <LoginButton variant="outlined" disableElevation disabled={isLoading} color="inherit">
                Login
              </LoginButton>
            )}
            {isAuthenticated && (
              <LogoutButton
                variant="outlined"
                disableElevation
                disabled={isLoading}
                color="inherit"
              >
                Logout
              </LogoutButton>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
