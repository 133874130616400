import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ProductsChips from './ProductsChips';

function FLModal({open, formik, onClose, featuredLists, widgetIndex}) {
  const handleChip = (e, values) => {
    formik.setFieldValue(`layout[${widgetIndex}].ListDetails.products`, values);
  };
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle variant="h6">Featured Product List</DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl
          sx={{mt: 1}}
          fullWidth
          size="small"
          error={formik?.errors?.layout?.[widgetIndex]?.ListDetails?.listId}
        >
          <InputLabel id="fpList-label">Featured Lists</InputLabel>
          <Select
            labelId="fpList"
            id="fpList"
            name="fpList"
            // value={formik?.values.layout?.[widgetIndex]?.ListDetails?.listId}
            placeholder="Select List"
            label="Featured Lists"
          >
            {featuredLists?.data?.map(list => (
              <MenuItem
                onClick={() => {
                  const newArray = [];
                  list.productsId.forEach(id => {
                    newArray.push(list.products.find(product => product._id === id));
                  });
                  const newObj = {
                    type: 'FPList',
                    ListDetails: {
                      listId: list?._id,
                      title: list?.title,
                      boxColor: list?.boxColor,
                      description: list?.description,
                      icon: list?.icon,
                      products: newArray.map(product => ({
                        slug: product?.slug,
                        productId: product?._id,
                        HeroImage: product?.images[0],
                        title: product?.name,
                      })),
                    },
                  };
                  formik.setFieldValue(`layout[${widgetIndex}]`, newObj);
                }}
                key={list._id}
                value={list._id}
              >
                {list.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack spacing={2} mt={2}>
          <TextField
            fullWidth
            id="title"
            disabled
            name="title"
            label="Title"
            size="small"
            value={formik?.values.layout?.[widgetIndex]?.ListDetails?.title || ''}
          />
          <Typography
            p={1}
            sx={{
              border: '1px solid #ddd',
              borderRadius: '4px',
            }}
          >
            {ReactHtmlParser(
              formik?.values.layout?.[widgetIndex]?.ListDetails?.description || 'Description'
            )}
          </Typography>
          {/* <TextField
            fullWidth
            id="description"
            size="small"
            disabled
            multiline
            name="description"
            label="Description"
            rows={4}
          /> */}
          <ProductsChips
            handleChip={handleChip}
            errors={formik?.errors?.layout?.[widgetIndex]?.ListDetails?.products || null}
            productsList={
              featuredLists?.data
                ?.find(x => x._id === formik?.values.layout?.[widgetIndex]?.ListDetails?.listId)
                ?.products?.map(product => ({
                  productId: product?._id,
                  slug: product?.slug,
                  HeroImage: product?.images[0],
                  title: product?.name,
                })) || []
            }
            products={formik?.values.layout?.[widgetIndex]?.ListDetails?.products || []}
          />
        </Stack>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FLModal;
