import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useReplaceNewsFeedMutation, useLazyGetUploadUrlQuery} from 'api';
import {getImageURL} from '../../../utils/imageUrl';
import NewsFeedForm from './NewsFeedForm';
import {newsFeedValidationSchema} from '../utils';

function NewsFeedEditIconButton({newsFeed}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceNewsFeed] = useReplaceNewsFeedMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: {errors, isSubmitting},
  } = useForm({
    resolver: yupResolver(newsFeedValidationSchema),
    defaultValues: {
      _id: newsFeed?._id,
      title: newsFeed?.title,
      description: newsFeed?.description,
      url: newsFeed?.url,
      icon: getImageURL(newsFeed?.icon),
    },
  });
  const onSubmit = async values => {
    try {
      let uploadResult = newsFeed.icon;
      if (values.icon.file) {
        const formData = new FormData();
        formData.append(`file`, values.icon.file);
        const {url} = await getUploadUrl().unwrap();
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error('Failed to upload icon.');
        }
        const imageObj = await response.json();
        uploadResult = imageObj.result.id;
      }
      const newObj = {
        ...values,
        icon: uploadResult,
      };
      await replaceNewsFeed(newObj).unwrap();
      reset();
      enqueueSnackbar('News Feed updated successfully', {
        variant: 'success',
      });
      setIsModalOpen(false);
    } catch (e) {
      const message = e.data.status || undefined;
      enqueueSnackbar(message || 'Failed to create news Feed', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Dialog open={isModalOpen}>
        <DialogTitle>Update News Feed</DialogTitle>
        <DialogContent dividers>
          <NewsFeedForm setValue={setValue} control={control} watch={watch} errors={errors} />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={handleSubmit(onSubmit)}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default NewsFeedEditIconButton;
