import React from 'react';
import {useParams} from 'react-router-dom';
import {useGetCompanyByIdQuery} from 'api';
import MiniSiteForm from './MiniSieForm';

export function MiniSiteLayout() {
  const {id} = useParams();
  const {data, isLoading} = useGetCompanyByIdQuery(id);
  const newData = JSON.parse(
    JSON.stringify({...data?.data?.miniSite[0], companyId: data?.data?._id})
  );

  return (
    <>
      <MiniSiteForm newData={newData} isLoading={isLoading} gallery={data?.data?.gallery} />
    </>
  );
}

export default MiniSiteLayout;
