import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Skeleton,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetCompaniesQuery} from 'api';
import {useDispatch, useSelector} from 'react-redux';
import {resetMiniSite} from 'redux/slices/miniSite';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import CompanyDeleteIconButton from './CompanyDeleteIconButton';
import CreateCompanyModal from './CreateCompanyModal';

function CompaniesTable() {
  const {data, isLoading, isError, isSuccess} = useGetCompaniesQuery();
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = useSelector(state => state.miniSite.companyId);

  return (
    <>
      <CreateCompanyModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="Companies Table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>Companies</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Business Type</TableCell>
              <TableCell align="center">Country</TableCell>
              <TableCell align="center">Logo</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              Boolean(data?.length) &&
              data.map(row => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.businessType.name}</TableCell>
                  <TableCell align="center">{row.mainAddress.country.name}</TableCell>
                  <TableCell align="center">
                    {row.logo ? (
                      <Box
                        component="img"
                        sx={{width: 40, height: 40, objectFit: 'contain'}}
                        src={`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${row.logo}/public`}
                        alt={row.name}
                      />
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => {
                        if (companyId !== row._id) {
                          dispatch(resetMiniSite());
                        }
                        navigate(`${row._id}`);
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>

                    <CompanyDeleteIconButton companyId={row._id} />
                  </TableCell>
                </TableRow>
              ))}
            {Boolean(isSuccess && !data?.length) && (
              <TableRow>
                <TableCell colSpan={7}>No company found</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography color="error">Failed to fetch company.</Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={100}
                page={0}
                onPageChange={() => {
                  // console.log('onPageChange', rest);
                }}
                rowsPerPage={10}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default CompaniesTable;
