import {
  Button,
  CircularProgress,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  IconButton,
  styled,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {ReactSortable} from 'react-sortablejs';
import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useReplaceFeaturedCategoryMutation} from 'api';
import {getImageURL} from '../../../utils/imageUrl';

const MuiReactSortable = styled(ReactSortable)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'wrap',
  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
  '& > *': {
    width: '100%',
  },
}));

function ProductsView({productsList = [], open, onClose, isSubmitting, setIsSubmitting}) {
  const [replaceFeaturedProductsList] = useReplaceFeaturedCategoryMutation();
  const {enqueueSnackbar} = useSnackbar();
  const [chipProducts, setChipProducts] = useState([]);
  React.useEffect(() => {
    const newArray = [];
    productsList.productsIds.forEach(id => {
      newArray.push(productsList.products.find(product => product._id === id));
    });
    setChipProducts([...newArray].map(obj => ({...obj})));
  }, [productsList]);
  const handleUpdate = async () => {
    setIsSubmitting(true);
    const filteredArray = chipProducts.map(item => item._id);
    const newObj = {...productsList};
    newObj.productsIds = filteredArray;
    delete newObj._rev;
    delete newObj.category;
    delete newObj.products;
    delete newObj.featuredProductsId;
    try {
      await replaceFeaturedProductsList(newObj).unwrap();
      enqueueSnackbar('Products Updated successfully', {
        variant: 'success',
      });
    } catch {
      enqueueSnackbar('Failed to Updated Products', {
        variant: 'error',
      });
    }

    onClose();
    setIsSubmitting(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth onClose={onClose} maxWidth="sm">
        <DialogTitle>{`${productsList.name}'s Products`}</DialogTitle>
        <DialogContent dividers>
          <MuiReactSortable
            list={chipProducts}
            setList={newProductsList => {
              setChipProducts(newProductsList);
            }}
          >
            {chipProducts.map(item => (
              <div key={item._id}>
                <Chip
                  sx={{
                    width: '100%',
                  }}
                  avatar={<Avatar align="left" alt={item.name} src={getImageURL(item.images[0])} />}
                  label={item.name}
                  variant="outlined"
                  onDelete={() => {
                    setChipProducts(prevProducts =>
                      prevProducts.filter(({_id}) => _id !== item._id)
                    );
                  }}
                  deleteIcon={
                    <IconButton sx={{ml: 'auto !important'}} aria-label="delete">
                      <ClearIcon color="error" />
                    </IconButton>
                  }
                />
              </div>
            ))}
          </MuiReactSortable>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
            disabled={isSubmitting}
            color="primary"
            variant="contained"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProductsView;
