import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Skeleton,
  TableFooter,
  TablePagination,
  Button,
} from '@mui/material';
import React from 'react';
import {useGetCertificationsQuery} from 'api';
import AddIcon from '@mui/icons-material/Add';
import CertificateDeleteIconButton from './CertificateDeleteIconButton';
import CertificateEditIconButton from './CertificateEditIconButton';
import CreateCertificateModal from './CreateCertificateModal';

function CertificationsTable() {
  const {data, isLoading, isError, isSuccess} = useGetCertificationsQuery();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <CreateCertificateModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="Certification Table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>Certifications</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Short Name</TableCell>
              <TableCell align="center">Full Title</TableCell>
              <TableCell align="center">Organization</TableCell>
              <TableCell align="center">Icon</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              Boolean(data?.data?.length) &&
              data.data.map(row => (
                <TableRow key={row._id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell align="center">{row.shortName}</TableCell>
                  <TableCell align="center">{row.fullTitle}</TableCell>
                  <TableCell align="center">{row.organization.name}</TableCell>
                  <TableCell align="center">
                    <Box
                      component="img"
                      sx={{width: 40, height: 40, objectFit: 'contain'}}
                      src={`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${row.icon}/public`}
                      alt={row.name}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <CertificateEditIconButton certificate={row} />
                    <CertificateDeleteIconButton certificateId={row._id} />
                  </TableCell>
                </TableRow>
              ))}
            {Boolean(isSuccess && !data?.data?.length) && (
              <TableRow>
                <TableCell colSpan={5}>No Certificate found</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography color="error">Failed to fetch certificate.</Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={100}
                page={0}
                onPageChange={() => {
                  // console.log('onPageChange', rest);
                }}
                rowsPerPage={10}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default CertificationsTable;
