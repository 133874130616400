import {Container} from '@mui/material';
import Navbar from 'components/Navbar';
import React from 'react';

function Layout({children}) {
  return (
    <div>
      <Navbar />
      <Container sx={{my: 3}}>{children}</Container>
    </div>
  );
}

export default Layout;
