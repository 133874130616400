import {
  FormHelperText,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import CertificateIconDropzone from './CertificateIconDropzone';

const validationSchema = Yup.object({
  shortName: Yup.string().required('Name is required'),
  fullTitle: Yup.string().required('Title is required'),
  organizationId: Yup.string().required('Organization is required'),
  companies: Yup.boolean().required('Companies is required'),
  products: Yup.boolean().required('Products is required'),
  description: Yup.string(),
  icon: Yup.mixed()
    .required('Icon is required')
    .test('icon-valid', (value, {createError, path}) => {
      if (value?.error) {
        return createError({
          path,
          message: value.error,
        });
      }
      return true;
    }),
});

function CertificateForm({initialValues = {}, organizationList, disabled, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      shortName: '',
      fullTitle: '',
      description: '',
      organizationId: '',
      companies: false,
      products: false,
      icon: null,
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl sx={{mb: 2}} disabled={disabled} fullWidth>
        <InputLabel id="organizationId-label">Organization</InputLabel>
        <Select
          labelId="organizationId-label"
          id="organizationId"
          name="organizationId"
          placeholder="Select Organization Type"
          value={formik.values.organizationId}
          label="Group"
          onChange={formik.handleChange}
          error={formik.touched.organizationId && Boolean(formik.errors.organizationId)}
        >
          <MenuItem value="">Root (/)</MenuItem>
          {organizationList?.data?.map(organization => (
            <MenuItem key={organization?._id} value={organization?._id}>
              {organization?.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {formik.touched.organizationId && formik.errors.organizationId}
        </FormHelperText>
      </FormControl>
      <TextField
        fullWidth
        id="shortName"
        name="shortName"
        label="Short Name"
        value={formik.values.shortName}
        onChange={formik.handleChange}
        error={formik.touched.shortName && Boolean(formik.errors.shortName)}
        helperText={formik.touched.shortName && formik.errors.shortName}
        sx={{mb: 2}}
      />
      <TextField
        fullWidth
        id="fullTitle"
        name="fullTitle"
        label="Full Title"
        value={formik.values.fullTitle}
        onChange={formik.handleChange}
        error={formik.touched.fullTitle && Boolean(formik.errors.fullTitle)}
        helperText={formik.touched.fullTitle && formik.errors.fullTitle}
        sx={{mb: 2}}
      />
      <TextField
        fullWidth
        multiline
        rows={4}
        id="description"
        name="description"
        label="Description"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        sx={{mb: 2}}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.companies}
              onChange={formik.handleChange}
              name="companies"
            />
          }
          label="For Companies"
        />
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.products}
              onChange={formik.handleChange}
              name="products"
            />
          }
          label="For Products"
        />
      </FormGroup>
      <Box>
        <CertificateIconDropzone
          previewSrc={formik.values.icon}
          onDropAccepted={file => {
            formik.setFieldValue('icon', {file});
          }}
          onDropRejected={(file, errors) => {
            formik
              .setFieldValue('icon', {file, error: errors?.[0]?.message || 'Icon is invalid'})
              .then(() => {
                formik.setFieldTouched('icon', true);
              });
          }}
        />
        <FormHelperText sx={{mt: 1, ml: 1}} error>
          {formik.touched.icon && formik.errors.icon}
        </FormHelperText>
      </Box>
    </form>
  );
}

const ForwardedRefCertificateForm = React.forwardRef(CertificateForm);

export default ForwardedRefCertificateForm;
