import {
  TextField,
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
  Box,
  FormControlLabel,
  Switch,
  OutlinedInput,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {useGetBannersQuery} from 'api';
import {getImageURL} from 'utils/imageUrl';
import CategoryModal from 'components/Category/CategoryModal';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is Required'),
  categoryId: Yup.string().required(),
  icon: Yup.string().required('icon is Required'),
  productsIds: Yup.array().of(Yup.string().trim()),
  isActive: Yup.boolean().required(),
  banners: Yup.array().of(
    Yup.object({
      image: Yup.string().required('image is required'),
      alt: Yup.string(),
      url: Yup.string(),
    })
  ),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function FeaturedCateForm({initialValues = {}, onSubmit}, ref) {
  const {data: banners} = useGetBannersQuery();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      name: '',
      icon: '',
      isActive: true,
      productsIds: [],
      banners: [],
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });
  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);
  const addBanner = event => {
    const alreadyExist = formik?.values.banners?.find(banner => banner._id === event._id);
    if (alreadyExist) {
      const newArray = formik?.values.banners?.filter(banner => banner._id !== event._id);
      formik.setFieldValue(`banners`, [...newArray]);
    } else {
      formik.setFieldValue(`banners`, [...formik?.values.banners, event]);
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl size="small" fullWidth>
        <Stack>
          <CategoryModal
            formik={formik}
            setValue={formik.setFieldValue}
            icon
            activeBreadCrumbs={false}
            setParentVal={false}
            setCategoryVal={false}
          />
          <FormHelperText error>
            {formik.touched.categoryId && formik.errors.categoryId}
          </FormHelperText>
        </Stack>
      </FormControl>
      <Stack direction="row" mt={1} spacing={2}>
        <TextField
          size="small"
          fullWidth
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        {formik.values.icon && (
          <Box
            component="img"
            src={getImageURL(formik.values.icon)}
            sx={{width: '77px', height: '40px', objectFit: 'contain'}}
          />
        )}
      </Stack>

      <Autocomplete
        sx={{
          mt: 2,
        }}
        onChange={(e, values) => {
          formik.setFieldValue('productsIds', values);
        }}
        onBlur={formik.handleBlur}
        fullWidth
        size="small"
        multiple
        freeSolo
        name="productsIds"
        defaultValue={formik.values.productsIds}
        options={[]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              color="primary"
              label={option}
              size="small"
              {...getTagProps({index})}
            />
          ))
        }
        getOptionLabel={option => option.category}
        filterSelectedOptions
        renderInput={params => (
          <TextField {...params} label="Products" placeholder="Type product id here ..." />
        )}
      />
      <FormControl
        size="small"
        sx={{
          mt: 2,
        }}
        fullWidth
      >
        <InputLabel id="banners">Banners</InputLabel>
        <Select
          labelId="banners"
          id="banners"
          multiple
          value={formik?.values.banners?.map(banner => banner?.alt) || []}
          input={<OutlinedInput id="banners" label="Banners" />}
          renderValue={() => (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
              {formik?.values.banners?.map(value => (
                <Chip key={value._id} label={value.alt || 'N/A'} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {banners?.data?.map(banner => (
            <MenuItem
              key={banner._id}
              onClick={() => {
                addBanner({
                  _id: banner._id,
                  alt: banner.alt,
                  image: banner.image,
                  url: banner.url,
                });
              }}
              value={banner.alt}
              style={getStyles(banner.alt || 'N/A', banner.alt || 'N/A', theme)}
            >
              {banner.alt || 'N/A'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        sx={{
          mt: 1,
        }}
        onChange={() => formik.setFieldValue('isActive', !formik.values.isActive)}
        control={<Switch color="primary" checked={formik.values.isActive} />}
        label="Active"
        labelPlacement="start"
      />
    </form>
  );
}
const ForwardedRefFeaturedCateForm = React.forwardRef(FeaturedCateForm);

export default ForwardedRefFeaturedCateForm;
