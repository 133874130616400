import {Paper, Box, IconButton, Stack, TextField} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function SlidersTable({errors, index, i, delSlideChip, tab, item, formik}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = () => setIsOpen(prev => !prev);
  const handleChange = e => {
    formik.setFieldValue(`${tab}[${index}].slider[${i}].${e.target.name}`, e.target.value);
  };
  return (
    <>
      <Paper
        sx={{
          px: 1,
          py: 2,
          width: '100%',
          marginBottom: 1,
          borderColor: theme =>
            errors?.[index]?.slider?.[i] ? theme.palette.error.main : undefined,
        }}
        variant="outlined"
        // key={key}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton onClick={toggleOpen}>
            {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
          {!isOpen && (
            <Box
              component="img"
              sx={{width: 40, height: 40, objectFit: 'contain'}}
              src={`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${item.image}/public`}
              alt={item.alt}
            />
          )}
          {isOpen && (
            <Stack spacing={2} flex={1} mx={2}>
              <TextField
                fullWidth
                size="small"
                label="Text"
                name="alt"
                type="alt"
                value={formik.values[tab][index].slider[i].alt}
                onChange={e => handleChange(e)}
                sx={{
                  '& label.Mui-focused': {
                    color: theme =>
                      errors?.[index]?.slider?.[i]?.alt ? theme.palette.error.main : undefined,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: theme =>
                      errors?.[index]?.slider?.[i]?.alt ? theme.palette.error.main : undefined,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme =>
                        errors?.[index]?.slider?.[i]?.alt ? theme.palette.error.main : undefined,
                    },
                    '&:hover fieldset': {
                      borderColor: theme =>
                        errors?.[index]?.slider?.[i]?.alt ? theme.palette.error.main : undefined,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme =>
                        errors?.[index]?.slider?.[i]?.alt ? theme.palette.error.main : undefined,
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                size="small"
                label="Redirect URL"
                name="url"
                type="url"
                value={formik.values[tab][index].slider[i].url}
                onChange={e => handleChange(e)}
                sx={{
                  '& label.Mui-focused': {
                    color: theme =>
                      errors?.[index]?.slider?.[i]?.url ? theme.palette.error.main : undefined,
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: theme =>
                      errors?.[index]?.slider?.[i]?.url ? theme.palette.error.main : undefined,
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme =>
                        errors?.[index]?.slider?.[i]?.url ? theme.palette.error.main : undefined,
                    },
                    '&:hover fieldset': {
                      borderColor: theme =>
                        errors?.[index]?.slider?.[i]?.url ? theme.palette.error.main : undefined,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme =>
                        errors?.[index]?.slider?.[i]?.url ? theme.palette.error.main : undefined,
                    },
                  },
                }}
              />
            </Stack>
          )}
          <IconButton
            onClick={() => {
              delSlideChip(tab, index, i);
            }}
            color="error"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Paper>
    </>
  );
}

export default SlidersTable;
