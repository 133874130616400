import {FormHelperText, TextField, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';

const validationSchema = Yup.object({
  email: Yup.string().email().required('Email is required'),
  roleId: Yup.string().trim().required('Role is required'),
  companyId: Yup.string().trim().required('Company is required'),
});

function CertificateForm({initialValues = {}, roles, onSubmit}, ref) {
  const {id} = useParams();
  const formik = useFormik({
    initialValues: {
      email: '',
      roleId: '',
      companyId: id,
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        size="small"
        label="Email"
        name="email"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
        sx={{mb: 2}}
      />
      <FormControl size="small" sx={{mb: 2}} fullWidth>
        <InputLabel id="user-roleId-label">Role</InputLabel>
        <Select
          size="small"
          labelId="user-roleId-label"
          id="roleId"
          name="roleId"
          value={formik.values.roleId}
          label="Role"
          onChange={formik.handleChange}
          error={formik.touched.roleId && Boolean(formik.errors.roleId)}
        >
          {roles
            ?.filter(role => role.group === 'COMPANY')
            ?.map(role => (
              <MenuItem key={role._id} value={role._id}>
                {role.name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText error>{formik.touched.roleId && formik.errors.roleId}</FormHelperText>
      </FormControl>
    </form>
  );
}

const ForwardedRefCertificateForm = React.forwardRef(CertificateForm);

export default ForwardedRefCertificateForm;
