import * as React from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography, Stack} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoriesDataGrid from './CategoriesDataGrid';

export default function MiniSiteCover() {
  return (
    <>
      <Stack>
        <CategoriesDataGrid />
        <Accordion
          sx={{
            marginTop: 2,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Archive</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CategoriesDataGrid addButton={false} isActive={false} />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </>
  );
}
