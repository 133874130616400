import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {useDeleteTradrMemberMutation} from 'api';
import {useSnackbar} from 'notistack';

function TradrMemberDeleteIconButton({TradrMemberId}) {
  const [deleteTradrMember, deleteTradrMemberResult] = useDeleteTradrMemberMutation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleDelete = async () => {
    try {
      await deleteTradrMember(TradrMemberId).unwrap();
      enqueueSnackbar('TradrMember deleted', {variant: 'success'});
      setIsModalOpen(false);
    } catch (error) {
      const message = error.status === 400 ? error.data?.details?.[0]?.message : undefined;
      enqueueSnackbar(message || `Error deleting TradrMember`, {variant: 'error'});
    }
  };

  return (
    <>
      <Dialog open={isModalOpen} maxWidth="xs">
        <DialogTitle>Delete TradrMember</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this TradrMember?</DialogContent>
        <DialogActions>
          <Button
            disabled={deleteTradrMemberResult.isLoading}
            autoFocus
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={deleteTradrMemberResult.isLoading}
            color="error"
            endIcon={deleteTradrMemberResult.isLoading ? <CircularProgress size={16} /> : null}
            onClick={handleDelete}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        disabled={deleteTradrMemberResult.isLoading}
        aria-label="delete"
        color="error"
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
}

export default TradrMemberDeleteIconButton;
