import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  Button,
} from '@mui/material';
import React from 'react';
import {useGetUOMsQuery} from 'api';
import AddIcon from '@mui/icons-material/Add';
import UOMDeleteIconButton from './UomDeleteIconButton';
import CreateUOMModal from './UomCreateModal';
import UOMEditIconButton from './UomEditIconButton';

const totalColumns = 3;

function UOMsTable() {
  const {data, isLoading, isError, isSuccess} = useGetUOMsQuery();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <CreateUOMModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="UOM Table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={totalColumns - 1}>UOM</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              Boolean(data?.length) &&
              data.map(row => (
                <TableRow key={row._id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="right">
                    <UOMEditIconButton uom={row} />
                    <UOMDeleteIconButton uomId={row._id} />
                  </TableCell>
                </TableRow>
              ))}
            {Boolean(isSuccess && !data?.length) && (
              <TableRow>
                <TableCell colSpan={totalColumns}>No UOM found</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={totalColumns}>
                  <Typography color="error">Failed to fetch UOMs.</Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                {Array.from(Array(totalColumns).keys()).map(key => (
                  <TableCell key={key}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default UOMsTable;
