import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useAddBannerMutation, useLazyGetUploadUrlQuery} from 'api';
import BannerForm from './BannerForm';

function CreateBannerModal({open, onClose}) {
  const formRef = React.useRef();
  const [addBanner] = useAddBannerMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle variant="h6">Create Banner</DialogTitle>
      <Divider />
      <DialogContent>
        <BannerForm
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const formData = new FormData();
              formData.append(`file`, values.image.file);
              const {url} = await getUploadUrl().unwrap();
              const response = await fetch(url, {
                method: 'POST',
                body: formData,
              });
              if (!response.ok) {
                throw new Error('Failed to upload image.');
              }
              const uploadResult = await response.json();
              const newObj = {
                ...values,
                image: uploadResult.result.id,
              };
              await addBanner(newObj).unwrap();
              actions.resetForm();
              enqueueSnackbar('Banner created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message =
                e.status === 400 ? e.bannerResponse?.details?.[0]?.message : undefined;
              enqueueSnackbar(message || 'Failed to create banner', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateBannerModal;
