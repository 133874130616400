import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {
  useUpdateProductMutation,
  useGetUOMsQuery,
  useGetTradeTermsQuery,
  useGetSTypesQuery,
  useGetCurrenciesQuery,
  useGetPaymentMethodsQuery,
  useGetCertificationsQuery,
  useGetShippingMethodsQuery,
} from 'api';
import {yupResolver} from '@hookform/resolvers/yup';
import ProductFoam from './ProductForm';

import {productValidationSchema} from '../utils';

function EditProductIcon({product}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [replaceProduct] = useUpdateProductMutation();
  const {data: unitsList} = useGetUOMsQuery();
  const {data: tradeTermsList} = useGetTradeTermsQuery();
  const {data: sourcingTypeList} = useGetSTypesQuery();
  const {data: currenciesList} = useGetCurrenciesQuery();
  const {data: paymentMethodsList} = useGetPaymentMethodsQuery();
  const {data: certificationsList} = useGetCertificationsQuery();
  const {data: shippingMethodsList} = useGetShippingMethodsQuery();
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: {errors, isSubmitting},
  } = useForm({
    resolver: yupResolver(productValidationSchema),
    defaultValues: {
      id: product._id,
      name: product.name,
      slug: product.slug,
      category: product.category,
      companyId: product.companyId,
      categoryId: product.categoryId,
      parentId: product.parentId,
      tradeTermId: product.tradeTermId,
      description: product.description,
      minOrderQuantity: product.minOrderQuantity,
      sourcingTypeId: product.sourcingTypeId,
      unitId: product.unitId,
      price: product.price,
      currency: product.currency,
      imageIds: product.images,
      subCategoryId: product.subCategoryId,
      subSourcingTypeId: product.subSourcingTypeId,
      tags: product.tags,
      leadTime: product.leadTime,
      productSpecs: product.productSpecs || [],
      priceType: product.priceType,
      priceRange: product.priceRange,
      paymentId: product.paymentId,
      shippingId: product.shippingId,
      certificates: product.certificates || [],
      currencyId: product.currencyId,
      imageFiles: [],
    },
  });
  const onSubmit = async values => {
    try {
      const formData = new FormData();
      formData.append('id', values.id);
      formData.append('name', values.name);
      formData.append('slug', values.slug);
      formData.append('categoryId', values.categoryId);
      formData.append('parentId', values.parentId);
      formData.append('description', values.description);
      formData.append('companyId', values.companyId);
      formData.append('unitId', values.unitId);
      formData.append('tradeTermId', values.tradeTermId);
      formData.append('sourcingTypeId', values.sourcingTypeId);
      formData.append('description', values.description);
      formData.append('quantity', values.quantity);
      formData.append('minOrderQuantity', values.minOrderQuantity);
      formData.append('leadTime', values.leadTime);
      formData.append('paymentId', values.paymentId);
      formData.append('shippingId', values.shippingId);
      formData.append('certificates', JSON.stringify(values.certificates));
      formData.append('currencyId', values.currencyId);
      formData.append('imageIds', JSON.stringify(values.imageIds));
      formData.append('tags', JSON.stringify(values.tags));
      formData.append('subSourcingTypeId', values.subSourcingTypeId);
      formData.append('productSpecs', JSON.stringify(values.productSpecs));
      formData.append('priceType', values.priceType);
      if (values.priceType === 'fixed') {
        formData.append('price', values.price);
        formData.append('priceRange', JSON.stringify([]));
      } else {
        formData.append('priceRange', JSON.stringify(values.priceRange));
        formData.append('price', 0);
      }
      values.imageFiles.forEach(image => {
        formData.append('images', image);
      });
      await replaceProduct(formData).unwrap();
      reset();
      enqueueSnackbar('Product Updated successfully', {
        variant: 'success',
      });
      setIsModalOpen(false);
    } catch (e) {
      const message = e?.data?.data?.details?.[0].message || e?.data?.data || undefined;
      enqueueSnackbar(message || 'Failed to Updated product', {
        variant: 'error',
      });
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleOpen = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Dialog fullScreen open={isModalOpen} disableEnforceFocus>
        <DialogTitle>Update Product</DialogTitle>
        <DialogContent dividers>
          <ProductFoam
            unitsList={unitsList}
            tradeTermsList={tradeTermsList}
            sourcingTypeList={sourcingTypeList}
            currenciesList={currenciesList}
            paymentMethodsList={paymentMethodsList}
            certificationsList={certificationsList}
            shippingMethodsList={shippingMethodsList}
            onSubmit={handleSubmit(onSubmit)}
            control={control}
            watch={watch}
            setValue={setValue}
            errors={errors}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={handleSubmit(onSubmit)}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default EditProductIcon;
