import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Stack,
  Autocomplete,
  Chip,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CategoryModal from 'components/Category/CategoryModal';
import {useParams} from 'react-router-dom';
import React, {useEffect, useMemo} from 'react';
import {getImageURL} from 'utils/imageUrl';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {slugify} from 'utils/slugify';
import {useSnackbar} from 'notistack';
import {Controller} from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import RichEditor from '../../../components/RichEditor/RichEditor';
import DropZoneMultiple from '../../../components/DropZoneMultiple';
import Tabs from '../../../components/Tabs';
import DataGrid from '../../../components/DataGrid';
import AttributeNameDialog from './AttributeNameDialog';

function ProductFoam({
  control,
  watch,
  setValue,
  errors,
  disabled,
  onSubmit = false,
  tradeTermsList,
  unitsList,
  sourcingTypeList,
  currenciesList,
  paymentMethodsList,
  certificationsList,
  shippingMethodsList,
}) {
  const {id} = useParams();

  // const [subSourcingTypes, setSubSourcingTypes] = React.useState([]);
  const {enqueueSnackbar} = useSnackbar();
  const [sortImageArray, setSortImageArray] = React.useState([]);

  const {
    imageFiles,
    subSourcingTypeId,
    sourcingTypeId,
    name,
    tags,
    imageIds,
    tradeTermId,
    unitId,
    priceType,
    description,
    productSpecs,
    certificates,
    currencyId,
    paymentId,
    shippingId,
    priceRange,
  } = watch();

  const addNewAttribute = attributeName => {
    if (attributeName) {
      setValue(`productSpecs`, [
        ...productSpecs,
        {
          attribute: attributeName,
          values: [],
        },
      ]);
    }
  };

  const subSourcingTypes = useMemo(() => {
    if (subSourcingTypeId) {
      const sourcingTypeName = sourcingTypeList?.data?.find(
        sourcingType => sourcingType._id === sourcingTypeId
      ).title;

      return (
        sourcingTypeList?.data?.filter(sourcingType => sourcingType.parent === sourcingTypeName) ||
        []
      );
    }
    return [];
  }, [sourcingTypeList?.data, sourcingTypeId, subSourcingTypeId]);

  const filePreviews = React.useMemo(() =>
    imageFiles?.map((file, index) => ({
      preview: URL.createObjectURL(file?.image),
      index,
      edit: false,
    }))
  );

  const idPreviews = imageIds?.map((file, index) => ({
    preview: getImageURL(file),
    index,
    edit: true,
    file,
  }));

  const delImage = obj => {
    if (obj.edit) {
      setValue(
        'imageIds',
        imageIds.filter((_, ind) => ind !== obj.index)
      );
    } else {
      setValue(
        'imageFiles',
        imageFiles.filter((_, ind) => ind !== obj.index)
      );
    }
  };

  const handleChangeSort = v => {
    if (!v.length) return;
    setValue(
      'imageIds',
      v.filter(e => e.edit).map(e => e.file)
    );
  };

  useEffect(() => {
    setValue('companyId', id);
    setSortImageArray([...filePreviews, ...idPreviews]);
  }, [imageIds, imageFiles]);

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Stack>
            <CategoryModal setValue={setValue} watch={watch} productSpecs />
            <FormHelperText
              sx={{
                display: 'flex',
                'justify-content': 'right',
                'align-items': 'right',
              }}
              error
            >
              {errors.categoryId?.message}
            </FormHelperText>
          </Stack>
          <Stack direction="row">
            <Controller
              name="name"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  helperText={errors.name?.message}
                  error={errors.name?.message}
                  fullWidth
                  xs={8}
                  size="small"
                  id="name"
                  name="name"
                  label="Title"
                  sx={{m: 1}}
                />
              )}
            />
          </Stack>
          <Stack direction="row">
            <Controller
              name="slug"
              control={control}
              render={({field}) => (
                <TextField
                  fullWidth
                  size="small"
                  id="slug"
                  disabled={!name}
                  name="slug"
                  label="Slug"
                  sx={{m: 1}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip component="div" title="Generate Slug">
                          <IconButton
                            disabled={!name}
                            onClick={() => {
                              const slug = slugify(name);
                              setValue(
                                'slug',
                                `${slug}-${(Math.random() + 1)
                                  .toString(36)
                                  .substring(6)
                                  .toUpperCase()}`,
                                {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                }
                              );
                            }}
                            aria-label="Generate Slug"
                          >
                            <AutorenewIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                  helperText={errors.slug?.message}
                  error={errors.slug?.message}
                />
              )}
            />
          </Stack>
          <Stack direction="row">
            <Autocomplete
              onChange={(e, values) => {
                setValue('tags', values);
              }}
              fullWidth
              multiple
              size="small"
              freeSolo
              name="tags"
              defaultValue={tags}
              options={[]}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={option}
                    size="small"
                    {...getTagProps({index})}
                  />
                ))
              }
              getOptionLabel={option => option.title}
              filterSelectedOptions
              sx={{m: 1}}
              renderInput={params => (
                <TextField {...params} label="Tags" placeholder="Type tags here ..." />
              )}
            />
          </Stack>

          <Stack direction="row">
            <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
              <InputLabel id="trade-term-label">Trade Term</InputLabel>
              <Select
                labelId="tradeTermId"
                id="tradeTermId"
                name="tradeTermId"
                placeholder="Select Trade Term"
                value={tradeTermId}
                label="Trade Term"
                onChange={e => {
                  setValue('tradeTermId', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                error={errors.tradeTermId?.message}
              >
                {tradeTermsList?.map(tradeTerm => (
                  <MenuItem key={tradeTerm._id} value={tradeTerm._id}>
                    {tradeTerm.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.tradeTermId?.message}</FormHelperText>
            </FormControl>
            <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
              <InputLabel id="unit-label">UOM</InputLabel>
              <Select
                labelId="unitId"
                id="unitId"
                name="unitId"
                placeholder="Select Unit of Measure"
                value={unitId}
                label="UOM"
                onChange={e => {
                  setValue('unitId', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                error={errors.unitId?.message}
              >
                {unitsList?.map(unit => (
                  <MenuItem key={unit._id} value={unit._id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.unitId?.message}</FormHelperText>
            </FormControl>
            <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
              <InputLabel id="unit-label">Currency</InputLabel>
              <Select
                labelId="currencyId"
                id="currencyId"
                name="currencyId"
                placeholder="Select Currency"
                value={currencyId}
                label="Currency"
                onChange={e => {
                  setValue('currencyId', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                error={errors.currencyId?.message}
              >
                {currenciesList?.map(currency => (
                  <MenuItem key={currency._id} value={currency._id}>
                    {currency.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.currencyId?.message}</FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction="row">
            <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
              <InputLabel id="unit-label">Payment Method</InputLabel>
              <Select
                labelId="paymentId"
                id="paymentId"
                name="paymentId"
                placeholder="Select Payment Method"
                value={paymentId}
                label="Payment Method"
                onChange={e => {
                  setValue('paymentId', e.target.value);
                }}
                error={errors.paymentId?.message}
              >
                {paymentMethodsList?.map(method => (
                  <MenuItem key={method._id} value={method._id}>
                    {method.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.paymentId?.message}</FormHelperText>
            </FormControl>
            <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
              <InputLabel id="unit-label">Shipping Method</InputLabel>
              <Select
                labelId="shippingId"
                id="shippingId"
                name="shippingId"
                placeholder="Select Shipping Method"
                value={shippingId}
                label="Shipping Method"
                onChange={e => {
                  setValue('shippingId', e.target.value);
                }}
                error={errors.shippingId?.message}
              >
                {shippingMethodsList?.map(method => (
                  <MenuItem key={method._id} value={method._id}>
                    {method.title}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.shippingId?.message}</FormHelperText>
            </FormControl>
            <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
              <InputLabel id="unit-label">Product Certifications</InputLabel>
              <Select
                labelId="certificates"
                multiple
                id="certificates"
                name="certificates"
                placeholder="Select Certifications"
                value={certificates}
                label="Product Certifications"
                onChange={e => {
                  setValue('certificates', e.target.value);
                }}
                error={errors.certificates?.message}
              >
                {certificationsList?.data
                  ?.filter(certification => certification.products === true)
                  .map(certificate => (
                    <MenuItem key={certificate._id} value={certificate._id}>
                      {`${certificate.fullTitle} (${certificate.organization.name})`}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error>{errors.certificates?.message}</FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction="row">
            <Controller
              name="minOrderQuantity"
              control={control}
              render={({field}) => (
                <TextField
                  fullWidth
                  size="small"
                  name="minOrderQuantity"
                  label="minOrderQuantity"
                  sx={{m: 1}}
                  {...field}
                  // onBlur={onBlur}
                  helperText={errors.minOrderQuantity?.message}
                  error={errors.minOrderQuantity?.message}
                />
              )}
            />
            <Controller
              name="leadTime"
              control={control}
              render={({field}) => (
                <TextField
                  fullWidth
                  size="small"
                  name="leadTime"
                  label="leadTime"
                  sx={{m: 1}}
                  {...field}
                  // onBlur={onBlur}
                  helperText={errors.leadTime?.message}
                  error={errors.minOrderQuantity?.message}
                />
              )}
            />
            <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
              <InputLabel id="source-type-label">Sourcing Type</InputLabel>
              <Select
                labelId="sourcingTypeId"
                id="sourcingTypeId"
                name="sourcingTypeId"
                placeholder="Select Sourcing Type"
                value={sourcingTypeId}
                label="Sourcing Type"
                onChange={e => {
                  setValue('sourcingTypeId', e.target.value);
                  setValue('sourceId', '');
                }}
                error={errors.sourcingTypeId?.message}
              >
                {sourcingTypeList?.data
                  ?.filter(type => type.parent === '/')
                  .map(sourcingType => (
                    <MenuItem key={sourcingType._id} value={sourcingType._id}>
                      {sourcingType.title}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error>{errors.sourcingTypeId?.message}</FormHelperText>
            </FormControl>
            {subSourcingTypes.length ? (
              <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
                <InputLabel id="sub-source-type-label">Sub Sourcing Type</InputLabel>
                <Select
                  labelId="subSourcingTypeId"
                  id="subSourcingTypeId"
                  name="subSourcingTypeId"
                  placeholder="Select Sub Sourcing Type"
                  value={subSourcingTypeId}
                  label="Sub Sourcing Type"
                  onChange={e => {
                    setValue('subSourcingTypeId', e.target.value);
                  }}
                  error={errors.subSourcingTypeId?.message}
                >
                  {subSourcingTypes?.map(sourcingType => (
                    <MenuItem key={sourcingType._id} value={sourcingType._id}>
                      {sourcingType.title}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors.subSourcingTypeId?.message}</FormHelperText>
              </FormControl>
            ) : null}
            <FormControl sx={{m: 1}} size="small" disabled={disabled} fullWidth>
              <InputLabel id="source-type-label">Price Type</InputLabel>
              <Select
                labelId="priceType"
                id="priceType"
                name="priceType"
                placeholder="Price  Type"
                value={priceType}
                label="Price Type"
                onChange={e => {
                  setValue('priceType', e.target.value);
                  const PriceRange = priceRange.length;
                  if (e.target.value === 'range' && !PriceRange) {
                    setValue('priceRange', [
                      {
                        min: '',
                        max: '',
                        price: '',
                      },
                    ]);
                  }
                }}
                error={errors.priceType?.message}
              >
                <MenuItem key="fixed" value="fixed">
                  Fixed
                </MenuItem>
                <MenuItem key="range" value="range">
                  Range
                </MenuItem>
              </Select>
              <FormHelperText error>{errors.priceType?.message}</FormHelperText>
            </FormControl>
          </Stack>

          {priceType === 'range' ? (
            priceRange.map((value, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Stack direction="row" key={value}>
                <Controller
                  name={`priceRange[${index}].price`}
                  control={control}
                  render={({field}) => (
                    <TextField
                      fullWidth
                      size="small"
                      name={`priceRange[${index}].price`}
                      label="price"
                      onChange={e => {
                        setValue(`priceRange[${index}].price`, e.target.value);
                      }}
                      sx={{m: 1}}
                      {...field}
                      helperText={errors.priceRange?.[index]?.price?.message}
                      error={errors.priceRange?.[index]?.price?.message}
                    />
                  )}
                />
                <Controller
                  name={`priceRange[${index}].min`}
                  control={control}
                  render={({field}) => (
                    <TextField
                      fullWidth
                      size="small"
                      name={`priceRange[${index}].min`}
                      label="priceRange"
                      sx={{m: 1}}
                      {...field}
                      helperText={errors?.priceRange?.[index]?.min?.message}
                      error={errors?.priceRange?.[index]?.min?.message}
                    />
                  )}
                />
                <Controller
                  name={`priceRange[${index}].max`}
                  control={control}
                  render={({field}) => (
                    <TextField
                      fullWidth
                      size="small"
                      name={`priceRange[${index}].max`}
                      label="priceRange"
                      sx={{m: 1}}
                      {...field}
                      helperText={errors?.priceRange?.[index]?.max?.message}
                      error={errors?.priceRange?.[index]?.max?.message}
                    />
                  )}
                />
                <IconButton
                  color="primary"
                  aria-label="add price row"
                  onClick={() => {
                    setValue(`priceRange`, [
                      ...priceRange,
                      {
                        min: '',
                        max: '',
                        price: '',
                      },
                    ]);
                  }}
                >
                  <AddIcon />
                </IconButton>
                {index !== 0 && (
                  <IconButton
                    color="error"
                    aria-label="remove price row"
                    onClick={() => {
                      const newPriceRange = priceRange.filter(
                        (valueInA, indexInx) => indexInx !== index
                      );
                      setValue(`priceRange`, newPriceRange);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Stack>
            ))
          ) : (
            <Stack direction="row">
              <Controller
                name="price"
                control={control}
                render={({field}) => (
                  <TextField
                    fullWidth
                    size="small"
                    name="price"
                    label="Price"
                    sx={{m: 1}}
                    {...field}
                    helperText={errors.price?.message}
                    error={errors.price?.message}
                  />
                )}
              />
            </Stack>
          )}

          <Stack direction="row">
            <FormControl sx={{m: 1}} disabled={disabled} fullWidth>
              <DropZoneMultiple
                previewSrc={sortImageArray}
                setSortImageArray={setSortImageArray}
                handleChangeSort={handleChangeSort}
                onDropAccepted={files => {
                  const newUploadedFiles = files?.map((file, index) => ({
                    image: file,
                    orderNumber: filePreviews?.length + imageIds?.length + index + 1,
                  }));
                  const newArray = [...imageFiles, ...newUploadedFiles];
                  setValue('imageFiles', newArray, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                delImage={delImage}
                onDropRejected={(file, error) => {
                  enqueueSnackbar('rejected', {file, error});
                }}
              />
              <FormHelperText error>{errors.imageFiles?.message}</FormHelperText>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Tabs
            description={
              <RichEditor
                value={description}
                onChange={html => {
                  setValue('description', html);
                }}
              />
            }
            specs={
              <DataGrid
                columns={[
                  {
                    field: 'attribute',
                    headerName: 'attributes',
                    width: 150,
                  },
                  {
                    field: 'values',
                    headerName: 'Values',
                    width: 500,
                    renderCell: parameters => (
                      <Autocomplete
                        onChange={(e, values) => {
                          setValue(`productSpecs[${parameters.value.index}].values`, values);
                        }}
                        fullWidth
                        multiple
                        freeSolo
                        name="productSpecs"
                        defaultValue={productSpecs[parameters.value.index].values || []}
                        options={[]}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              label={option}
                              size="small"
                              {...getTagProps({index})}
                            />
                          ))
                        }
                        getOptionLabel={option => option.title}
                        filterSelectedOptions
                        sx={{m: 2}}
                        renderInput={params => <TextField variant="standard" {...params} />}
                      />
                    ),
                  },
                  {
                    field: 'Actions',
                    headerName: 'actions',
                    width: 150,
                    renderCell: parameters => {
                      if (parameters.row.index === productSpecs.length) {
                        return (
                          <>
                            <IconButton
                              onClick={() => {
                                const newArray = [...productSpecs];
                                newArray.splice(parameters.row.index - 1, 1);
                                setValue('productSpecs', newArray, {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                });
                              }}
                              color="error"
                              aria-label="add price row"
                            >
                              <DeleteIcon />
                            </IconButton>
                            <AttributeNameDialog addNewAttribute={addNewAttribute} />
                          </>
                        );
                      }
                      return (
                        <IconButton
                          onClick={() => {
                            const newArray = [...productSpecs];
                            newArray.splice(parameters.row.index - 1, 1);
                            setValue('productSpecs', newArray, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          }}
                          color="error"
                          aria-label="add price row"
                        >
                          <DeleteIcon />
                        </IconButton>
                      );
                    },
                  },
                ]}
                rows={[
                  ...productSpecs.map((attribute, index) => {
                    const obj = {
                      id: `${index}-${attribute.attribute}-${attribute.values?.join?.(',')}`,
                      index: index + 1,
                      attribute: attribute.attribute,
                      values: {
                        index,
                        values: attribute.values,
                      },
                    };
                    return obj;
                  }),
                ]}
              />
            }
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default ProductFoam;
