import React, {useRef, useState, useCallback} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {REACT_APP_URL} from 'config/index';

import './editor.css';
import {Editor} from '@tinymce/tinymce-react';
import UploadImage from './imageModal';

function RichEditor({value, onChange, height = '500px'}) {
  const editorRef = useRef(null);

  const onEditorInit = useCallback((_evt, editor) => {
    editorRef.current = editor;
  }, []);
  const [activeModal, setActiveModal] = useState(false);
  const insertImage = image => {
    editorRef.current.insertContent(image);
  };

  return (
    <>
      <Editor
        tinymceScriptSrc={`${REACT_APP_URL}tinymce/tinymce.min.js`}
        onInit={onEditorInit}
        init={{
          height,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'wordcount',
            'emoticons',
            'hr',
            'quickbars',
            'editimage',
          ],
          toolbar:
            ' undo redo | blocks | ' +
            'formatselect ' +
            'bold italic backcolor forecolor underline strikethrough subscript superscript |  link  emoticons upload media table  | alignleft aligncenter ' +
            'alignright alignjustify   | bullist numlist outdent indent  | hr ' +
            'removeformat',
          quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright ',
          quickbars_insert_toolbar: ' quicktable',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          setup: editor => {
            editor.ui.registry.addToggleButton('upload', {
              icon: 'image',
              onAction() {
                setActiveModal('upload-image');
              },
            });
          },
        }}
        value={value}
        onEditorChange={onChange}
      />
      <UploadImage
        show={activeModal}
        handleClose={() => setActiveModal(false)}
        insert={insertImage}
      />
    </>
  );
}

export default RichEditor;
