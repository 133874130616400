import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import {Stack, Typography} from '@mui/material';

export default function searchBox({onSearchChange, isSearch, handleSearch}) {
  const [search, setSearch] = React.useState('');
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearchChange(search);
    }
  };
  return (
    <>
      {isSearch && (
        <Stack>
          <Paper component="form" sx={{p: '2px 4px', ml: 1, display: 'flex', alignItems: 'center'}}>
            <InputBase
              sx={{ml: 1, flex: 1}}
              placeholder="Search Category"
              inputProps={{'aria-label': 'Search Category'}}
              value={search}
              onKeyPress={handleKeyPress}
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
            <Divider sx={{height: 28, m: 0.5}} orientation="vertical" />
            <SearchIcon
              sx={{
                ml: 1,
              }}
              onClick={() => {
                onSearchChange(search);
              }}
            />
            <Divider sx={{height: 28, m: 0.5}} orientation="vertical" />
            <IconButton type="button" sx={{p: '10px'}} aria-label="search">
              <CloseIcon
                onClick={() => {
                  setSearch('');
                  handleSearch();
                }}
              />
            </IconButton>
          </Paper>
          <Typography
            sx={{p: '2px 4px', ml: 1, display: 'flex', alignItems: 'center'}}
            variant="caption"
            display="block"
            gutterBottom
          >
            Note! this would not work like a regular search
          </Typography>
        </Stack>
      )}
      {!isSearch && (
        <SearchIcon
          sx={{
            ml: 1,
          }}
          onClick={handleSearch}
        />
      )}
    </>
  );
}
