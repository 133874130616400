import * as Yup from 'yup';

const categorySchemaProps = {
  name: Yup.string().required('Name is required'),
  slug: Yup.string()
    .matches(
      /^(([a-z])+|([a-z]+-[a-z]+)+)$/,
      'Slug should only contain lowercase letters (a-z) and - in between words'
    )
    .required('Slug is required'),
  parent: Yup.string().nullable(true),
  icon: Yup.mixed()
    .required('Icon is required')
    .test('icon-valid', (value, {createError, path}) => {
      if (value?.error) {
        return createError({
          path,
          message: value.error,
        });
      }
      return true;
    }),
  attributes: Yup.array(Yup.string().required('attributes is required')),
};
export const categoryInitialValues = {
  name: '',
  slug: '',
  parent: '',
  icon: null,
  attributes: [],
};
export const categoryValidationSchema = Yup.object(categorySchemaProps);
