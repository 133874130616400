import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Typography,
  Skeleton,
  TablePagination,
  Divider,
  Stack,
  Grid,
  TableHead,
} from '@mui/material';
import React from 'react';
import {useGetCategoriesByParentQuery} from 'api';
import BreadCrumbs from 'components/BreadCrumbs';
import SearchBox from './SearchBox';

function CategoriesTable({handleNewParentList, handleCategory, handleParent, parent, categoryId}) {
  const [isSearch, setIsSearch] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const {data, isError, isSuccess, isFetching} = useGetCategoriesByParentQuery({
    page,
    limit,
    parent: parent || null,
    search,
  });

  // On componentDidMount set the timer
  const count = data?.data[0]?.totalCount;
  React.useEffect(() => {
    handleNewParentList(data?.parents);
  }, [data?.parents]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSearchChange = newSearch => {
    setSearch(newSearch);
  };

  const handleSearch = () => {
    setSearch('');
    setIsSearch(!isSearch);
  };

  return (
    <>
      <Paper sx={{width: '100%', mb: 2, mt: 2}}>
        <Box sx={{flexGrow: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Stack direction="row" pt={1}>
                <SearchBox
                  handleSearch={handleSearch}
                  isSearch={isSearch}
                  value={search}
                  onSearchChange={onSearchChange}
                />
                {!isFetching && !isSearch && (
                  <BreadCrumbs parents={data?.parents || []} handleParentChange={handleParent} />
                )}
              </Stack>{' '}
            </Grid>
          </Grid>
        </Box>
        {/* <Stack direction="row" p={2}>
            <SearchBox
              handleSearch={handleSearch}
              isSearch={isSearch}
              value={search}
              onSearchChange={onSearchChange}
            />{' '}
            <BreadCrumbs parents={data?.parents} handleParentChange={handleParentChange} />
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
              disableElevation
              variant="contained"
              endIcon={<AddIcon />}
            >
              Add
            </Button>
          </Stack> */}
        <TableContainer sx={{mt: 2}}>
          <Divider />
          <Table sx={{minWidth: 650}} aria-label="Categories Table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Icon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSuccess &&
                !isFetching &&
                Boolean(data?.data[0]?.data?.length) &&
                data?.data[0]?.data?.map(row => (
                  <TableRow
                    key={row._id}
                    sx={{
                      background: row._id === categoryId ? '#E6E6E6' : null,
                      '&:last-child td, &:last-child th': {border: 0},
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{cursor: 'pointer'}}
                      onClick={() => {
                        handleCategory(row);
                        handleParent(row.parent);
                      }}
                    >
                      {row._id}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={() => {
                        handleParent(row._id);
                        if (isSearch) {
                          handleSearch();
                        }
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        component="img"
                        sx={{width: 40, height: 40, objectFit: 'contain'}}
                        src={`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${row.icon}/public`}
                        alt={row.name}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {Boolean(isSuccess && !data?.data[0]?.data?.length) && (
                <TableRow>
                  <TableCell colSpan={4}>No category found</TableCell>
                </TableRow>
              )}
              {isError && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="error">Failed to fetch categories.</Typography>
                  </TableCell>
                </TableRow>
              )}
              {isFetching && (
                <>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          sx={{
            '& .MuiToolbar-root p': {
              mb: 0,
            },
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default CategoriesTable;
