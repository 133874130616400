import {Collapse, List, ListItem, MenuItem, Stack} from '@mui/material';
import NavLink from 'components/Link/NavLink';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import {useNavigate} from 'react-router-dom';

function NavLinkMenuItem({to, children}) {
  return (
    <MenuItem
      component={NavLink}
      sx={{
        typography: 'subtitle2',
        '&.active': {
          color: 'primary.main',
        },
        width: '100%',
      }}
      to={to}
      key={to}
    >
      {children}
    </MenuItem>
  );
}
function NestedMenuItem({to, title, subLinks}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const isWithSubLinks = !!subLinks?.length;
  React.useEffect(() => {
    if (isWithSubLinks) {
      const matchLink = subLinks.find(link => window.location.pathname.endsWith(link.to));
      if (matchLink && !isOpen) {
        setIsOpen(true);
      } else if (!matchLink && isOpen) {
        setIsOpen(false);
      }
    }
  }, [subLinks, isWithSubLinks]);
  const toggleOpen = () => {
    navigate(to);
    setIsOpen(open => !open);
  };
  return (
    <>
      {!isWithSubLinks && <NavLinkMenuItem to={to}>{title}</NavLinkMenuItem>}
      {isWithSubLinks && (
        <MenuItem
          sx={{
            typography: 'subtitle2',
            width: '100%',
          }}
          onClick={() => {
            toggleOpen();
          }}
        >
          <Stack sx={{width: '100%'}} direction="row" justifyContent="space-between">
            <span>{title}</span>
            {isWithSubLinks && (
              <>
                {!isOpen && <KeyboardArrowDownIcon />}
                {isOpen && <KeyboardArrowUpIcon />}
              </>
            )}
          </Stack>
        </MenuItem>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subLinks?.map(({to: subTo, title: subTitle}) => (
            <ListItem dense sx={{w: '100%'}} key={subTo}>
              <NavLinkMenuItem to={subTo}>{subTitle}</NavLinkMenuItem>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
export default NestedMenuItem;
