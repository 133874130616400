import InnerLayout from 'components/Layout/InnerLayout';
import {
  CompanyOverview,
  CompanyUsers,
  CompanyProducts,
  MiniSiteLayout,
  CompanyGallery,
} from 'features/companies';
import {MiniSiteCategories as AllMiniSiteCategories} from 'features/miniSiteCategories/';

import React from 'react';
import {Routes, Route} from 'react-router-dom';

function Company() {
  return (
    <InnerLayout
      links={[
        {to: 'overview', title: 'Overview'},
        {to: 'users', title: 'Users Management'},
        {to: 'products', title: 'Products Management'},
        {to: 'minisite', title: 'Mini Site Layout'},
        {to: 'categories', title: 'Mini Site Products Categories'},
        {to: 'gallery', title: 'Gallery'},
        // {to: 'profile', title: 'Profile Management'},
      ]}
    >
      <Routes>
        <Route index element={<CompanyOverview />} />
        <Route path="overview" element={<CompanyOverview />} />
        <Route path="users" element={<CompanyUsers />} />
        <Route path="products" element={<CompanyProducts />} />
        <Route path="minisite" element={<MiniSiteLayout />} />
        <Route path="gallery" element={<CompanyGallery />} />
        <Route path="categories" element={<AllMiniSiteCategories />} />
      </Routes>
    </InnerLayout>
  );
}

export default Company;
