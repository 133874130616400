import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Skeleton, Stack} from '@mui/material';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LayoutTabs({mainPage, profile, value, handleTabChange, isLoading}) {
  const handleChange = (event, newValue) => {
    handleTabChange(newValue);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Stack direction="row" spacing={2}>
            <Skeleton
              variant="h1"
              sx={{
                minWidth: '15%',
                maxWidth: '20%',
                height: '30px',
              }}
            />{' '}
            <Skeleton
              variant="h1"
              sx={{
                minWidth: '15%',
                maxWidth: '20%',
                height: '30px',
              }}
            />{' '}
            <Skeleton
              variant="h1"
              sx={{
                minWidth: '15%',
                maxWidth: '20%',
                height: '30px',
              }}
            />
          </Stack>
          <Skeleton
            variant="h1"
            sx={{
              width: '100%',
              height: '180px',
            }}
          />
        </>
      ) : (
        <Box sx={{width: '100%'}}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={value} onChange={handleChange} aria-label="Minisite layout tabs">
              <Tab label="Main Page" {...a11yProps(0)} />
              <Tab label="Profile" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {mainPage}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {profile}
          </TabPanel>
        </Box>
      )}
    </>
  );
}
