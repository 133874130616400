import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Typography,
  Skeleton,
  TablePagination,
  Button,
  Divider,
  Stack,
  Grid,
  TableHead,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import React from 'react';
import {useGetCategoriesByParentQuery} from 'api';
import AddIcon from '@mui/icons-material/Add';
import BreadCrumbs from 'components/BreadCrumbs';
import {getImageURL} from 'utils/imageUrl';
import CategoryDeleteIconButton from './CategoryDeleteIconButton';
import CategoryEditIconButton from './CategoryEditIconButton';
import CreateCategoryModal from './CreateCategoryModal';
import SearchBox from './SearchBox';

function CategoriesTable() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);
  const [parent, setParent] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);

  const {data, isError, isSuccess, isFetching} = useGetCategoriesByParentQuery({
    page,
    limit,
    parent,
    search,
  });
  React.useEffect(() => {
    if (data?.data[0]?.totalCount) {
      setRowCount(data?.data[0]?.totalCount);
    }
  }, [data?.data[0]?.totalCount]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleParentChange = parentValue => {
    setParent(parentValue);
  };

  const onSearchChange = newSearch => {
    setSearch(newSearch);
  };

  const handleSearch = () => {
    setSearch('');
    setIsSearch(!isSearch);
  };

  const {enqueueSnackbar} = useSnackbar();
  return (
    <>
      <CreateCategoryModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        parent={parent}
      />
      <Typography variant="h6" gutterBottom>
        Categories
      </Typography>
      <Paper sx={{width: '100%', mb: 2, mt: 2}}>
        <Box sx={{flexGrow: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Stack direction="row" pt={1}>
                <SearchBox
                  handleSearch={handleSearch}
                  isSearch={isSearch}
                  value={search}
                  onSearchChange={onSearchChange}
                />
                {!isSearch && (
                  <BreadCrumbs
                    parents={data?.parents || []}
                    handleParentChange={handleParentChange}
                  />
                )}
              </Stack>{' '}
            </Grid>

            <Grid item xs={2}>
              {!isSearch && (
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Add
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        {/* <Stack direction="row" p={2}>
          <SearchBox
            handleSearch={handleSearch}
            isSearch={isSearch}
            value={search}
            onSearchChange={onSearchChange}
          />{' '}
          <BreadCrumbs parents={data?.parents} handleParentChange={handleParentChange} />
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            disableElevation
            variant="contained"
            endIcon={<AddIcon />}
          >
            Add
          </Button>
        </Stack> */}
        <TableContainer sx={{mt: 2}}>
          <Divider />
          <Table sx={{minWidth: 650}} aria-label="Categories Table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="right">Icon</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSuccess &&
                !isFetching &&
                Boolean(data?.data[0]?.data?.length) &&
                data?.data[0]?.data?.map(row => (
                  <TableRow key={row._id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(row._id);
                        enqueueSnackbar('Copied To Clipboard');
                      }}
                    >
                      {row._id}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={() => {
                        setParent(row._id);
                        if (isSearch) {
                          handleSearch();
                        }
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      <Box
                        component="object"
                        sx={{
                          width: 70,
                          height: 40,
                        }}
                        type="image/svg+xml"
                        data={getImageURL(row.icon, 'icon')}
                        width="70"
                        height="40"
                      >
                        <Box
                          component="img"
                          sx={{width: 70, height: 40, objectFit: 'contain'}}
                          width="70"
                          height="40"
                          src={getImageURL(row.icon, 'icon')}
                          alt={row.name}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {!isSearch && <CategoryEditIconButton category={row} parent={parent} />}

                      <CategoryDeleteIconButton categoryId={row._id} />
                    </TableCell>
                  </TableRow>
                ))}
              {Boolean(isSuccess && !isFetching && !data?.data[0]?.data?.length) && (
                <TableRow>
                  <TableCell colSpan={4}>No category found</TableCell>
                </TableRow>
              )}
              {isError && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography color="error">Failed to fetch categories.</Typography>
                  </TableCell>
                </TableRow>
              )}
              {isFetching && (
                <>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          sx={{
            '& .MuiToolbar-root p': {
              mb: 0,
            },
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={rowCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default CategoriesTable;
