import {
  Button,
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Avatar,
  Skeleton,
  Stack,
} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useGetCompanyMemberQuery} from 'api';
import AddIcon from '@mui/icons-material/Add';
import CreateMemberModal from './CreateMemberModal';

function MemberListItemSkeleton() {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText primary={<Skeleton variant="text" />} secondary={<Skeleton variant="text" />} />
    </ListItem>
  );
}
function CompanyUsersTable() {
  const [isOpen, setIsOpen] = React.useState(false);
  const {id} = useParams();
  const {data, isLoading, isError, isSuccess} = useGetCompanyMemberQuery(id);

  return (
    <>
      <CreateMemberModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <Box p={2} component={Paper}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography variant="h6">Company Members</Typography>
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            disableElevation
            variant="contained"
            endIcon={<AddIcon />}
          >
            Add
          </Button>
        </Stack>
        <List>
          {isLoading && <MemberListItemSkeleton />}
          {!isLoading && isError && (
            <ListItem>
              <ListItemText
                color="error"
                primary="Error"
                secondary="An error occurred while loading the members."
              />
            </ListItem>
          )}
          {!isLoading && isSuccess && !data?.length && (
            <ListItem>
              <ListItemText
                color="textSecondary"
                primary="No members found"
                secondary="This Company has no members."
              />
            </ListItem>
          )}
          {!isLoading &&
            isSuccess &&
            data?.map((member, index) => (
              <React.Fragment key={member.user_id}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar src={member.picture} alt={member.name} />
                  </ListItemAvatar>
                  <ListItemText primary={member.name} secondary={member.email} />
                </ListItem>
                {index < data.length - 1 && <Divider variant="inset" component="li" />}
              </React.Fragment>
            ))}
        </List>
      </Box>
    </>
  );
}

export default CompanyUsersTable;
