import * as React from 'react';
import {
  Stack,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Skeleton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ColorPicker} from 'utils/ColorPicker';
import CoverImage from './CoverImage';

export default function MiniSiteCover({formik, isLoading}) {
  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="h1"
          sx={{
            width: '100%',
            height: '80px',
          }}
        />
      ) : (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Cover Background</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>image</Typography>
              <Switch
                checked={formik?.values?.isCoverBgColor === 'color'}
                inputProps={{'aria-label': 'ant design'}}
                onChange={e => {
                  if (e.target.checked) {
                    formik.setFieldValue('isCoverBgColor', 'color');
                  } else {
                    formik.setFieldValue('isCoverBgColor', 'image');
                  }
                }}
              />
              <Typography>color</Typography>
            </Stack>
            {formik?.values?.isCoverBgColor === 'color' && <ColorPicker formik={formik} />}
            {formik?.values?.isCoverBgColor === 'image' && (
              <CoverImage formik={formik} isLoading={isLoading} />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
