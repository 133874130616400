import React from 'react';
import {Provider} from 'react-redux';
import {setupListeners} from '@reduxjs/toolkit/query';
import {store} from 'redux/store';

setupListeners(store.dispatch);

function ReduxProvider({children}) {
  return <Provider store={store}>{children}</Provider>;
}

export default ReduxProvider;
