import React from 'react';
import CountriesTable from '../components/CountriesTable';
import ProvincesTable from '../components/ProvincesTable';
import CitiesTable from '../components/CitiesTable';

export function Countries() {
  return <CountriesTable />;
}
export function Provinces() {
  return <ProvincesTable />;
}
export function Cities() {
  return <CitiesTable />;
}
