import {Box} from '@mui/material';
import React from 'react';

function Home() {
  return (
    <div>
      <Box
        component="img"
        src="/assets/images/analytics.png"
        sx={{width: '100%', objectFit: 'contain'}}
      />
    </div>
  );
}

export default Home;
