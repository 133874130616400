import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';

export default function DataGridDemo({columns, rows}) {
  return (
    <Box sx={{height: 520, width: '100%'}}>
      <DataGrid rows={rows} columns={columns} rowHeight={38} disableSelectionOnClick />
    </Box>
  );
}
