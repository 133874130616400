import {Link, Typography} from '@mui/material';
import React from 'react';

function Blog() {
  return (
    <div>
      <Typography variant="h6">Blog</Typography>
      <Typography variant="body">
        Manage Tradr blog at{' '}
        <Link target="_blank" rel="noopener" href="https://tronblogs.sanity.studio/">
          https://tronblogs.sanity.studio/
        </Link>
      </Typography>
    </div>
  );
}

export default Blog;
