import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';
import React from 'react';
import CreateCategoryModal from './CreateCategoryModal';

function CreateCategoryButton() {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <CreateCategoryModal open={isOpen} onClose={handleClose} />
      <Button
        size="small"
        onClick={handleOpen}
        endIcon={<AddIcon />}
        variant="contained"
        color="primary"
      >
        Category
      </Button>
    </>
  );
}

export default CreateCategoryButton;
