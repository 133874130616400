import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CompaniesModal({open, formik, onClose, companiesList, widgetIndex}) {
  const theme = useTheme();

  const addCompany = event => {
    const alreadyExist = formik?.values.layout?.[widgetIndex]?.companies?.find(
      company => company.slug === event.slug
    );
    if (alreadyExist) {
      const newArray = formik?.values.layout?.[widgetIndex]?.companies?.filter(
        company => company.slug !== event.slug
      );
      formik.setFieldValue(`layout[${widgetIndex}].companies`, [...newArray]);
    } else {
      formik.setFieldValue(`layout[${widgetIndex}].companies`, [
        ...formik?.values.layout?.[widgetIndex]?.companies,
        event,
      ]);
    }
  };
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle variant="h6">Suppliers</DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl fullWidth error={formik?.errors?.layout?.[widgetIndex]?.companies}>
          <InputLabel id="supplier-label">Suppliers List</InputLabel>
          <Select
            labelId="supplier-label"
            id="supplier-chip"
            multiple
            value={
              formik?.values.layout?.[widgetIndex]?.companies?.map(company => company?.name) || []
            }
            input={<OutlinedInput id="supplier-chip" label="Suppliers List" />}
            renderValue={() => (
              <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                {formik?.values.layout?.[widgetIndex]?.companies?.map(value => (
                  <Chip key={value.slug} label={value.name} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {companiesList?.map(company => (
              <MenuItem
                key={company._id}
                onClick={() => {
                  addCompany({
                    name: company.name,
                    slug: company.slug,
                    logo: company.logo,
                  });
                }}
                value={company.name}
                style={getStyles(company.name, company.name, theme)}
              >
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompaniesModal;
