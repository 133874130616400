import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Checkbox,
} from '@mui/material';
import React from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

function ImageSelectionModal({open, onClose, images, handleSelectedImages}) {
  const [selected, setSelected] = React.useState([]);

  const handleSelect = (e, image) => {
    if (e.target.checked) {
      setSelected([
        ...selected,
        {
          image: image.image,
          alt: image.alt,
        },
      ]);
    } else {
      setSelected(selected.filter(item => item.image !== image.image));
    }
  };
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        Select Files
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pl={3}>
          <ImageList sx={{width: '100%', height: 450}} variant="quilted" cols={4} rowHeight={121}>
            {images?.map(item => (
              <ImageListItem key={item.img}>
                <Checkbox
                  checked={selected.some(image => image.image === item.image)}
                  onChange={e => handleSelect(e, item)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                />
                <img
                  src={`${`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${item.image}/public`}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${`https://imagedelivery.net/rfsrmrTRTTug_lRfZe2CFQ/${item.image}/public`}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.alt}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          startIcon={<SaveAltIcon />}
          onClick={() => {
            handleSelectedImages(selected);
            setSelected([]);
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImageSelectionModal;
