import {FormHelperText, TextField, Box, FormControlLabel, Switch} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import BannerIconDropzone from './BannerIconDropzone';

const validationSchema = Yup.object({
  alt: Yup.string(),
  url: Yup.string(),
  isFeatured: Yup.boolean(),
  image: Yup.mixed()
    .required('image is required')
    .test('image-valid', (value, {createError, path}) => {
      if (value?.error) {
        return createError({
          path,
          message: value.error,
        });
      }
      return true;
    }),
});

function BannerForm({initialValues = {}, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      alt: '',
      isFeatured: false,
      url: '',
      image: null,
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="alt"
        name="alt"
        label="Text"
        size="small"
        value={formik.values.alt}
        onChange={formik.handleChange}
        error={formik.touched.alt && Boolean(formik.errors.alt)}
        helperText={formik.touched.alt && formik.errors.alt}
        sx={{mb: 2}}
      />

      <TextField
        fullWidth
        id="url"
        size="small"
        name="url"
        label="Redirect Url"
        value={formik.values.url}
        onChange={formik.handleChange}
        error={formik.touched.url && Boolean(formik.errors.url)}
        helperText={formik.touched.url && formik.errors.url}
        sx={{mb: 2}}
      />

      <FormControlLabel
        sx={{mb: 2}}
        onChange={() => formik.setFieldValue('isFeatured', !formik.values.isFeatured)}
        control={<Switch color="primary" checked={formik.values.isFeatured} />}
        label="Featured"
        labelPlacement="start"
      />

      <Box>
        <BannerIconDropzone
          previewSrc={formik.values.image}
          onDropAccepted={file => {
            formik.setFieldValue('image', {file});
          }}
          onDropRejected={(file, errors) => {
            formik
              .setFieldValue('image', {file, error: errors?.[0]?.message || 'image is invalid'})
              .then(() => {
                formik.setFieldTouched('image', true);
              });
          }}
        />
        <FormHelperText sx={{mt: 1, ml: 1}} error>
          {formik.touched.image && formik.errors.image}
        </FormHelperText>
      </Box>
    </form>
  );
}

const ForwardedRefBannerForm = React.forwardRef(BannerForm);

export default ForwardedRefBannerForm;
