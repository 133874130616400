import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup
    .string('Enter the role')
    .matches(/^[a-zA-Z_]*$/, 'Role should be letters or underscore only')
    .required('Role is required'),
  group: yup
    .string()
    .oneOf(['TRON', 'COMPANY'], 'Group should be either TRON or COMPANY')
    .required('Group is required'),
});

function RoleForm({initialValues = {}, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      name: '',
      group: '',
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="name"
        name="name"
        label="Role"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        sx={{mb: 2}}
      />
      <FormControl fullWidth>
        <InputLabel id="role-group-label">Group</InputLabel>
        <Select
          labelId="role-group-label"
          id="group"
          name="group"
          value={formik.values.group}
          label="Group"
          onChange={formik.handleChange}
          error={formik.touched.group && Boolean(formik.errors.group)}
        >
          <MenuItem value="TRON">TRON</MenuItem>
          <MenuItem value="COMPANY">COMPANY</MenuItem>
        </Select>
        <FormHelperText error>{formik.touched.group && formik.errors.group}</FormHelperText>
      </FormControl>
    </form>
  );
}

const ForwardedRoleForm = React.forwardRef(RoleForm);

export default ForwardedRoleForm;
