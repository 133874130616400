import DeleteIcon from '@mui/icons-material/Delete';
import {GridActionsCellItem} from '@mui/x-data-grid';
import React from 'react';
import {useDeleteFeaturedCategoryMutation} from 'api';
import {useSnackbar} from 'notistack';
import ConfirmationDialog from './ConfirmationDialog';

function DeleteCategoryCellItem({row}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [deleteCategory, deleteCategoryResult] = useDeleteFeaturedCategoryMutation();

  const handleDelete = React.useCallback(async () => {
    try {
      await deleteCategory(row._id).unwrap();
      enqueueSnackbar('Category deleted successfully', {variant: 'success'});
      setIsModalOpen(false);
    } catch {
      enqueueSnackbar('Failed to delete question', {variant: 'error'});
    }
  }, [row]);

  const handleClose = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <ConfirmationDialog
        open={isModalOpen}
        onClose={handleClose}
        loading={deleteCategoryResult.isLoading}
        onConfirm={handleDelete}
        title="Delete Category"
        message="Are you sure you want to delete this question?"
      />
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        onClick={() => {
          setIsModalOpen(true);
        }}
        label="Delete"
      />
    </>
  );
}

export default DeleteCategoryCellItem;
