import {TextField} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  title: Yup.string().required('title is required'),
});

function ShippingMethodForm({initialValues = {}, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      title: '',
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="title"
        title="title"
        label="Title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        sx={{mb: 2}}
      />
    </form>
  );
}

const ForwardedRefShippingMethodForm = React.forwardRef(ShippingMethodForm);

export default ForwardedRefShippingMethodForm;
