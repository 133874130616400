import {
  Paper,
  PaginationItem,
  Box,
  List,
  ListItem,
  Divider,
  Pagination,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react';
import {useGetUsersQuery} from 'api';
// import {Link} from 'react-router-dom';

function UsersList({handleEmailChange}) {
  const perPage = 10;
  const [page, setPage] = React.useState(0);
  const {data} = useGetUsersQuery({page, perPage});

  return (
    <>
      <Box p={2} component={Paper} variant="outlined">
        <List style={{maxHeight: 200, overflow: 'auto'}}>
          {data?.users?.map((user, index) => (
            <ListItem
              button
              key={user._0}
              divider={index < data?.users?.length - 1}
              onClick={event => handleEmailChange(event)}
            >
              <Typography variant="subtitle2">
                {user.nickname}{' '}
                <Typography fontSize="0.9em" component="span" color="GrayText">
                  ({user.email})
                </Typography>
              </Typography>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box mt={2} display="flex" justifyContent="right">
          <Pagination
            page={page + 1}
            edge="end"
            color="primary"
            onChange={(event, value) => setPage(value - 1)}
            count={Math.ceil(data?.total / data?.limit)}
            renderItem={item => (
              <PaginationItem
                components={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                {...item}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
}

export default UsersList;
