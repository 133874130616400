import React from 'react';
import MuiLink from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';
import {styled} from '@mui/material/styles';

const CustomMuiLink = styled(MuiLink)({
  textDecoration: 'none',
  color: 'inherit',
});

function Link(props) {
  return <CustomMuiLink {...props} component={RouterLink} />;
}

export default Link;
