import {Box, IconButton, Paper, Typography} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

function TypeCard({index, object, delChip, handleRichTextModal, handleSlidersModal, key, error}) {
  const handleClick = type => {
    if (type === 'RichText') {
      handleRichTextModal(index);
    } else if (type === 'Slider') {
      handleSlidersModal(index);
    }
  };
  return (
    <Paper
      sx={{
        px: 1,
        py: 2,
        width: '100%',
        borderColor: theme => (error ? theme.palette.error.main : undefined),
      }}
      variant="outlined"
      key={key}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box flex={1} mx={2} onClick={() => handleClick(object.type)}>
          <Typography variant="h6">{index + 1}</Typography>
          <Typography variant="body1">{object.type}</Typography>
        </Box>
        <IconButton
          onClick={() => {
            delChip(index);
          }}
          color="error"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Paper>
  );
}

export default TypeCard;
