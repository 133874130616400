import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

function ConfirmationDialog({open, loading, onClose, onConfirm, title, message}) {
  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          disabled={loading}
          autoFocus
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          size="small"
          color="error"
          variant="contained"
          endIcon={loading ? <CircularProgress size={14} /> : null}
          onClick={onConfirm}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
