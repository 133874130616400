import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {useAddTradrMemberMutation, useGetTradrMembersQuery, useGetRolesQuery} from 'api';
import TradrMemberForm from './TradrMemberForm';

function CreateTradrMemberModal({open, onClose}) {
  const {isLoading, isError} = useGetTradrMembersQuery();
  const formRef = React.useRef();
  const [addTradrMember] = useAddTradrMemberMutation();
  const {data: roles} = useGetRolesQuery();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle variant="h6">Add Tradr Member</DialogTitle>
      <DialogContent sx={{pb: 1}}>
        <TradrMemberForm
          disabled={isLoading || isError}
          ref={formRef}
          roles={roles || []}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const newObj = {
                ...values,
              };
              const res = await addTradrMember(newObj).unwrap();
              if (res.code !== 200) {
                enqueueSnackbar(res.status || 'Failed to create TradrMember', {
                  variant: 'error',
                });
              } else {
                actions.resetForm();
                enqueueSnackbar('TradrMember created successfully', {
                  variant: 'success',
                });
                onClose();
              }
            } catch (e) {
              const message =
                e.status === 400 ? e.TradrMemberResponse?.details?.[0]?.message : undefined;
              enqueueSnackbar(message || 'Failed to create TradrMember', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateTradrMemberModal;
