import {TextField} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  title: Yup.string().required('title is required'),
  symbol: Yup.string().required('symbol is required'),
});

function CurrencyForm({initialValues = {}, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      title: '',
      symbol: '',
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="title"
        title="title"
        label="Title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        sx={{mb: 2}}
      />
      <TextField
        fullWidth
        id="symbol"
        symbol="symbol"
        label="Symbol"
        value={formik.values.symbol}
        onChange={formik.handleChange}
        error={formik.touched.symbol && Boolean(formik.errors.symbol)}
        helperText={formik.touched.symbol && formik.errors.symbol}
        sx={{mb: 2}}
      />
    </form>
  );
}

const ForwardedRefCurrencyForm = React.forwardRef(CurrencyForm);

export default ForwardedRefCurrencyForm;
