import {Skeleton, TextField} from '@mui/material';
import React from 'react';

function SubHeading({formik, isLoading}) {
  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="h1"
          sx={{
            width: '100%',
            height: '37px',
          }}
        />
      ) : (
        <TextField
          fullWidth
          size="small"
          id="subHeading"
          label="Sub Heading"
          variant="outlined"
          name="subHeading"
          value={formik.values.subHeading}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.subHeading && formik.errors.subHeading)}
          helperText={formik.touched.subHeading && formik.errors.subHeading}
        />
      )}
    </>
  );
}

export default SubHeading;
