import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';

export default function searchBox({onSearchChange}) {
  const [search, setSearch] = React.useState('');
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearchChange(search);
    }
  };
  React.useEffect(() => {
    if (search === '') {
      onSearchChange('');
    }
  }, [search]);
  return (
    <Paper component="form" sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: 300}}>
      <InputBase
        sx={{ml: 1, flex: 1}}
        placeholder="Search Featured List"
        inputProps={{'aria-label': 'search Featured List'}}
        value={search}
        onKeyPress={handleKeyPress}
        onChange={e => {
          setSearch(e.target.value);
        }}
      />
      <Divider sx={{height: 28, m: 0.5}} orientation="vertical" />
      <IconButton
        type="button"
        sx={{p: '10px'}}
        onClick={() => {
          onSearchChange(search);
        }}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
