import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import {Button, IconButton, Stack, TextField} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AttributeNameDialog({addNewAttribute}) {
  const [open, setOpen] = React.useState(false);
  const [attributeName, setAttributeName] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton color="primary" aria-label="add price row" onClick={handleOpen}>
        <AddIcon />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Stack sx={style} spacing={2}>
            <TextField
              size="small"
              fullWidth
              id="outlined-basic"
              label="Attribute Name"
              variant="outlined"
              onChange={e => {
                setAttributeName(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                addNewAttribute(attributeName);
                handleClose();
              }}
              variant="contained"
              endIcon={<SendIcon />}
            >
              Continue
            </Button>
          </Stack>
        </Fade>
      </Modal>
    </div>
  );
}
