import React from 'react';
import {Auth0Provider} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from '../config';

function AuthProvider({children}) {
  const navigate = useNavigate();

  const onRedirectCallback = () => {
    navigate('/', {replace: true});
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}

export default AuthProvider;
