import {GridActionsCellItem} from '@mui/x-data-grid';
import React from 'react';
import CollectionsIcon from '@mui/icons-material/Collections';
import BannersView from './BannersView';

function ViewProductCellItem({row}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  return (
    <>
      <BannersView
        bannersList={row}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
      />
      <GridActionsCellItem
        icon={<CollectionsIcon color="info" />}
        onClick={() => {
          setIsModalOpen(true);
        }}
        label="products"
      />
    </>
  );
}

export default ViewProductCellItem;
