import {
  TextField,
  FormControl,
  Stack,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import UsersList from '../../users/components/UsersList';

const validationSchema = Yup.object({
  email: Yup.string().required('Email is required'),
  roleId: Yup.string().required('Please select a role'),
});

function TradrMemberForm({initialValues = {}, onSubmit, roles}, ref) {
  const [email, setEmail] = React.useState('');
  const formik = useFormik({
    initialValues: {
      email: '',
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });
  const handleEmailChange = event => {
    setEmail(event.target.innerText);
    formik.setFieldValue('email', event.target.innerText);
  };

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <FormControl fullWidth>
          <UsersList handleEmailChange={handleEmailChange} />
        </FormControl>
        <TextField
          fullWidth
          id="email"
          disabled
          name="email"
          label="Email"
          size="small"
          value={email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <FormControl size="small" fullWidth>
          <InputLabel id="user-roleId-label">Role</InputLabel>
          <Select
            labelId="user-roleId-label"
            id="roleId"
            name="roleId"
            label="Role"
            value={formik.values.roleId}
            onChange={formik.handleChange}
            error={formik.touched.roleId && Boolean(formik.errors.roleId)}
          >
            {roles
              ?.filter(role => role.group === 'TRON')
              ?.map(role => (
                <MenuItem key={role._id} value={role._id}>
                  {role.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText error>{formik.touched.roleId && formik.errors.roleId}</FormHelperText>
        </FormControl>
      </Stack>
    </form>
  );
}

const ForwardedRefTradrMemberForm = React.forwardRef(TradrMemberForm);

export default ForwardedRefTradrMemberForm;
