import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  Button,
} from '@mui/material';
import React from 'react';
import {useGetMiniSiteCategoriesQuery} from 'api';
import {useParams} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import MiniSiteCategoryDeleteIconButton from './MiniSiteCategoryDeleteIconButton';
import CreateMiniSiteCategoryModal from './MiniSiteCategoriesModal';
import MiniSiteCategoryEditIconButton from './MiniSiteCategoryEditIconButton';

const totalColumns = 4;

function MiniSiteCategoriesTable() {
  const {id} = useParams();
  const {data, isLoading, isError, isSuccess} = useGetMiniSiteCategoriesQuery(id);
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <CreateMiniSiteCategoryModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="MiniSiteCategory Table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={totalColumns - 1}>Categories</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Total Products</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              Boolean(data?.length) &&
              data.map(row => (
                <TableRow key={row._id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell align="center">{row.title}</TableCell>
                  <TableCell align="center">{row.productsIds.length}</TableCell>
                  <TableCell align="center">
                    <MiniSiteCategoryEditIconButton MiniSiteCategory={row} />
                    <MiniSiteCategoryDeleteIconButton MiniSiteCategoryId={row._id} companyId={id} />
                  </TableCell>
                </TableRow>
              ))}
            {Boolean(isSuccess && !data?.length) && (
              <TableRow>
                <TableCell colSpan={totalColumns}>No Category found</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={totalColumns}>
                  <Typography color="error">Failed to fetch Categories.</Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                {Array.from(Array(totalColumns).keys()).map(key => (
                  <TableCell key={key}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default MiniSiteCategoriesTable;
