import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  title: Yup.string().required('title is required'),
  parent: Yup.string(),
});

function STypeForm({initialValues = {}, onSubmit, parentsList, disabled}, ref) {
  const formik = useFormik({
    initialValues: {
      title: '',
      parent: '/',
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="title"
        name="title"
        label="Title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        sx={{mb: 2}}
      />

      <FormControl sx={{mb: 2}} disabled={disabled} fullWidth>
        <InputLabel id="parent-category-label">Parent</InputLabel>
        <Select
          labelId="parent-category-label"
          id="parent"
          name="parent"
          placeholder="Select Parent Type"
          value={formik.values.parent}
          label="Group"
          onChange={formik.handleChange}
          error={formik.touched.parent && Boolean(formik.errors.parent)}
        >
          <MenuItem value="/">Root (/)</MenuItem>
          {parentsList.sort().map(sType => (
            <MenuItem key={sType} value={sType}>
              {sType}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{formik.touched.parent && formik.errors.parent}</FormHelperText>
      </FormControl>
    </form>
  );
}

const ForwardedRefSTypeFormForm = React.forwardRef(STypeForm);

export default ForwardedRefSTypeFormForm;
