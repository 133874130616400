import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';
import {
  useAddIndustrialProcessMutation,
  useLazyGetUploadUrlQuery,
  useGetIndustrialProcessesQuery,
} from 'api';
import IndustrialProcessForm from './IndustrialProcessForm';

function CreateIndustrialProcessModal({open, onClose}) {
  const {data, isLoading, isError} = useGetIndustrialProcessesQuery();
  const formRef = React.useRef();
  const [addIndustrialProcess] = useAddIndustrialProcessMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const parentsList = React.useMemo(() => {
    if (data) {
      return data.map(industrialProcess => industrialProcess.process);
    }
    return [];
  }, [data]);
  const {enqueueSnackbar} = useSnackbar();
  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle mb={2} variant="h6">
        Create Process
      </DialogTitle>
      <DialogContent>
        <IndustrialProcessForm
          disabled={isLoading || isError}
          parentsList={parentsList}
          ref={formRef}
          onSubmit={async (values, actions) => {
            try {
              setIsSubmitting(true);
              const formData = new FormData();
              let uploadResult = '';
              if (values.icon) {
                formData.append(`file`, values.icon.file);
                const {url} = await getUploadUrl().unwrap();
                const response = await fetch(url, {
                  method: 'POST',
                  body: formData,
                });
                if (!response.ok) {
                  throw new Error('Failed to upload icon.');
                }
                uploadResult = await response.json();
              }
              const newObj = {
                ...values,
                icon: uploadResult ? uploadResult.result.id : getUploadUrl,
                slug: `${values.slug}`,
              };
              await addIndustrialProcess(newObj).unwrap();
              actions.resetForm();
              enqueueSnackbar('IndustrialProcess created successfully', {
                variant: 'success',
              });
              onClose();
            } catch (e) {
              const message =
                e.status === 400 ? e.industrialProcessResponse?.details?.[0]?.message : undefined;
              enqueueSnackbar(message || 'Failed to create IndustrialProcess', {
                variant: 'error',
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
          disabled={isSubmitting}
          color="primary"
          variant="contained"
          onClick={() => formRef.current?.submit?.()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateIndustrialProcessModal;
