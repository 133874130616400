import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {CustomSnackbarProvider} from 'providers/snackbar';
import ReduxProvider from 'providers/redux';
import App from './App';
import AuthProvider from './providers/auth';

ReactDOM.render(
  <React.StrictMode>
    <CustomSnackbarProvider>
      <Router>
        <AuthProvider>
          <ReduxProvider>
            <CssBaseline />
            <App />
          </ReduxProvider>
        </AuthProvider>
      </Router>
    </CustomSnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
