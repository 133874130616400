import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplaceSTypeMutation, useGetSTypesQuery} from 'api';
import STypeForm from './STypeForm';

function STypeEditIconButton({sType}) {
  const {data, isSuccess} = useGetSTypesQuery();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceSType] = useReplaceSTypeMutation();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const parentsList = React.useMemo(() => {
    if (isSuccess) {
      return data.data.map(STypes => STypes.title);
    }
    return [];
  }, [data, isSuccess]);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Dialog fullWidth open={isModalOpen}>
        <DialogTitle>Update Type</DialogTitle>
        <DialogContent dividers>
          <STypeForm
            parentsList={parentsList}
            initialValues={{
              _id: sType._id,
              title: sType.title,
              parent: sType.parent,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                const newObj = {
                  ...values,
                };
                await replaceSType(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('Type created successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400 ? e.sTypeResponse?.details?.[0]?.message : undefined;
                enqueueSnackbar(message || 'Failed to create sType', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isSubmitting} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="success"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default STypeEditIconButton;
