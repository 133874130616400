import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useReplaceFeaturedProductsListMutation, useLazyGetUploadUrlQuery} from 'api';
import FeaturedProductsListForm from './FeaturedProductsListForm';
import {getImageURL} from '../../../utils/imageUrl';

function FeaturedProductsListEditIconButton({featuredProductsList}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [replaceFeaturedProductsList] = useReplaceFeaturedProductsListMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const formRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Dialog fullWidth scroll="body" maxWidth="md" open={isModalOpen}>
        <DialogTitle>Update List</DialogTitle>
        <DialogContent dividers>
          <FeaturedProductsListForm
            initialValues={{
              _id: featuredProductsList._id,
              title: featuredProductsList.title,
              description: featuredProductsList.description,
              icon: getImageURL(featuredProductsList.icon),
              productsId: featuredProductsList.productsId,
              boxColor: featuredProductsList.boxColor,
            }}
            ref={formRef}
            onSubmit={async (values, actions) => {
              setIsSubmitting(true);
              try {
                let uploadResult = featuredProductsList.icon;
                if (values.icon.file) {
                  const formData = new FormData();
                  formData.append(`file`, values.icon.file);
                  const {url} = await getUploadUrl().unwrap();
                  const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                  });
                  if (!response.ok) {
                    throw new Error('Failed to upload icon.');
                  }
                  const imageObj = await response.json();
                  uploadResult = imageObj.result.id;
                }
                const newObj = {
                  ...values,
                  icon: uploadResult,
                };
                await replaceFeaturedProductsList(newObj).unwrap();
                actions.resetForm();
                enqueueSnackbar('List Updated successfully', {
                  variant: 'success',
                });
                setIsModalOpen(false);
              } catch (e) {
                const message =
                  e.status === 400
                    ? e.featuredProductsListResponse?.details?.[0]?.message
                    : undefined;
                enqueueSnackbar(message || 'Failed to Updated List', {
                  variant: 'error',
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            variant="outlined"
            disabled={isSubmitting}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="primary"
            variant="contained"
            endIcon={isSubmitting ? <CircularProgress size={16} /> : null}
            onClick={() => formRef.current?.submit?.()}
          >
            update
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={isSubmitting} onClick={handleOpen} aria-label="edit" color="primary">
        <EditIcon />
      </IconButton>
    </>
  );
}

export default FeaturedProductsListEditIconButton;
