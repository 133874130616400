import {TextField} from '@mui/material';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
});

function OrganizationForm({initialValues = {}, onSubmit}, ref) {
  const formik = useFormik({
    initialValues: {
      name: '',
      ...initialValues,
    },
    validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({submit: () => formik.submitForm()}), [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="name"
        name="name"
        label="Name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        sx={{mb: 2}}
      />
    </form>
  );
}

const ForwardedRefOrganizationForm = React.forwardRef(OrganizationForm);

export default ForwardedRefOrganizationForm;
