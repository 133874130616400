import {Paper, Stack, Typography, Button, CircularProgress, Box, Skeleton} from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import {useFormik} from 'formik';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useAddMiniSiteLayoutMutation} from 'api';
import {getImageURL} from 'utils/imageUrl';
import {updateMiniSite} from 'redux/slices/miniSite';
import Title from '../components/layout/Title';
import SubHeading from '../components/layout/SubHeading';
import Dropdown from '../components/layout/Type';
import SortableTypes from '../components/layout/SortableTypes';
import RichTextModal from '../components/layout/RichTextModal';
import SlidersModal from '../components/layout/SlidersModal';
import Logo from '../components/layout/Logo';
import MiniSiteCover from '../components/layout/MiniSiteCover';
import Tabs from '../components/layout/Tabs';

const validationSchema = Yup.object({
  title: Yup.string().required('Name is required'),
  subHeading: Yup.string().required('subHeading is required'),
  logo: Yup.mixed(),
  isCoverBgColor: Yup.string().required(),
  coverBgColor: Yup.string().when('isCoverBgColor', {
    is: val => val === true,
    then: Yup.string().required('Color is required'),
    otherwise: Yup.string().notRequired(),
  }),
  coverBgImage: Yup.mixed(),
  coverBgImageFile: Yup.mixed()
    .notRequired()
    .when('isCoverBgColor', {
      is: val => val === false,
      then: Yup.mixed().required('Image is required'),
      otherwise: Yup.mixed().notRequired(),
    }),

  logoFile: Yup.mixed()
    .notRequired()
    .when('logo', {
      is: val => val === '',
      then: Yup.mixed().required('Logo is required'),
      otherwise: Yup.mixed().notRequired(),
    }),
  mainPage: Yup.array()
    .of(
      Yup.object({
        type: Yup.string().required('type is required'),
        text: Yup.string()
          .optional()
          .when('type', {
            is: 'RichText',
            then: Yup.string().required('text is required'),
          }),
        transitionTime: Yup.number()
          .optional()
          .when('type', {
            is: 'Slider',
            then: Yup.number().min(0).max(500).required('Time is required'),
          }),

        slider: Yup.array().when('type', {
          is: 'Slider',
          then: Yup.array()
            .of(
              Yup.object({
                alt: Yup.string(),
                image: Yup.string().required('image is required'),
                url: Yup.string(),
              })
            )
            .required('slider is required'),
        }),
      })
    )
    .required('mainPage is required'),
  profile: Yup.array()
    .of(
      Yup.object({
        type: Yup.string().required('type is required'),
        text: Yup.string()
          .optional()
          .when('type', {
            is: 'RichText',
            then: Yup.string().required('text is required'),
          }),
        transitionTime: Yup.number()
          .optional()
          .when('type', {
            is: 'Slider',
            then: Yup.number().min(0).max(500).required('Time is required'),
          }),

        slider: Yup.array().when('type', {
          is: 'Slider',
          then: Yup.array()
            .of(
              Yup.object({
                alt: Yup.string(),
                image: Yup.string().required('image is required'),
                url: Yup.string(),
              })
            )
            .required('slider is required'),
        }),
      })
    )
    .required('profile is required'),
});

function MiniSieForm({newData, isLoading, gallery}) {
  const {id} = useParams();
  const [addLayout] = useAddMiniSiteLayoutMutation();
  const [typeValue, setTypeValue] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSliders, setIsOpenSliders] = React.useState(false);
  const [typeIndex, setTypeIndex] = React.useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);
  const [currentTabValue, setCurrentTabValue] = React.useState('mainPage');
  const dispatch = useDispatch();
  const title = useSelector(state => state.miniSite.title);
  const subHeading = useSelector(state => state.miniSite.subHeading);
  const coverBgImage = useSelector(state => state.miniSite.coverBgImage);
  const isCoverBgColor = useSelector(state => state.miniSite.isCoverBgColor);
  const coverBgColor = useSelector(state => state.miniSite.coverBgColor);
  const coverBgImageFile = useSelector(state => state.miniSite.coverBgImageFile);
  const logo = useSelector(state => state.miniSite.logo);
  const mainPage = useSelector(state => state.miniSite.mainPage);
  const profile = useSelector(state => state.miniSite.profile);
  const logoFile = useSelector(state => state.miniSite.logoFile);
  const currentTabValueData = useSelector(state => state.miniSite.currentTabValue);
  const companyId = useSelector(state => state.miniSite.companyId);

  const formik = useFormik({
    initialValues: {
      title: '',
      subHeading: '',
      isCoverBgColor: '',
      coverBgColor: '',
      coverBgImage: null,
      coverBgImageFile: null,
      logo: null,
      logoFile: null,
      mainPage: [],
      profile: [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async () => {
      try {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append('companyId', id);
        formData.append('title', formik.values.title);
        formData.append('subHeading', formik.values.subHeading);
        formData.append('isCoverBgColor', formik.values.isCoverBgColor);
        formData.append('coverBgColor', formik.values.coverBgColor);
        formData.append('mainPage', JSON.stringify(formik.values.mainPage));
        formData.append('profile', JSON.stringify(formik.values.profile));
        if (formik.values.logoFile?.file) {
          formData.append('logo', formik.values.logoFile?.file);
        } else {
          formData.append('existingLogo', newData?.logo);
        }
        if (formik.values.coverBgImageFile?.file) {
          formData.append('coverBgImage', formik.values.coverBgImageFile?.file);
        } else {
          formData.append('existingCoverBgImage', newData?.coverBgImage);
        }
        await addLayout(formData);
        enqueueSnackbar('Layout Saved successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar('Failed to Save Layout', {
          variant: 'error',
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });
  const handleChange = type => {
    setTypeValue(type.target.value);
  };
  const buttonClick = () => {
    if (typeValue === 'RichText') {
      const newMainPage = [...formik.values[currentTabValue], {type: typeValue, text: ''}];
      formik.setFieldValue(currentTabValue, newMainPage);
    }
    if (typeValue === 'Slider') {
      const newMainPage = [
        ...formik.values[currentTabValue],
        {type: typeValue, slider: [], transitionTime: 0},
      ];
      formik.setFieldValue(currentTabValue, newMainPage);
    }
  };
  const delChip = index => {
    formik.setFieldValue(
      currentTabValue,
      formik.values[currentTabValue].filter((_, ind) => ind !== index)
    );
  };
  const delSlideChip = (tab, index, slideIndex) => {
    formik.setFieldValue(
      currentTabValue,
      formik.values[currentTabValue].map((item, ind) => {
        if (ind === index) {
          return {
            ...item,
            slider: item.slider.filter((_, slideInd) => slideInd !== slideIndex),
          };
        }
        return item;
      })
    );
  };
  const handleRichTextModal = index => {
    setTypeIndex(index);
    setIsOpen(!isOpen);
  };
  const handleSlidersModal = index => {
    setTypeIndex(index);
    setIsOpenSliders(!isOpenSliders);
  };
  const handleTabChange = newValue => {
    setCurrentTab(newValue);
    if (newValue === 0) {
      setCurrentTabValue('mainPage');
    } else if (newValue === 1) {
      setCurrentTabValue('profile');
    }
  };
  const handleSelectedImages = images => {
    const newSlider = [...(formik.values[currentTabValue][typeIndex].slider || []), ...images];
    formik.setFieldValue(
      currentTabValue,
      formik.values[currentTabValue].map((item, ind) => {
        if (ind === typeIndex) {
          return {
            ...item,
            slider: newSlider,
          };
        }
        return item;
      })
    );
  };
  React.useEffect(
    () => () => {
      dispatch(
        updateMiniSite({
          title: formik.values.title,
          subHeading: formik.values.subHeading,
          logo: formik.values.logo || null,
          logoFile: formik.values.logoFile?.file
            ? URL.createObjectURL(formik.values.logoFile?.file)
            : null,
          isCoverBgColor: formik.values.isCoverBgColor,
          coverBgColor: formik.values.coverBgColor,
          coverBgImage: formik.values.coverBgImage || null,
          coverBgImageFile: formik.values.coverBgImageFile?.file
            ? URL.createObjectURL(formik.values.coverBgImageFile?.file)
            : null,
          mainPage: [...formik.values.mainPage],
          profile: [...formik.values.profile],
          currentTabValue: currentTab,
        })
      );
    },
    [formik.values]
  );
  React.useEffect(async () => {
    if ((newData && !companyId) || id !== companyId) {
      formik.setFieldValue('title', newData?.title || '');
      formik.setFieldValue('subHeading', newData?.subHeading || '');
      formik.setFieldValue('logo', newData.logo ? getImageURL(newData.logo) : null);
      formik.setFieldValue('isCoverBgColor', newData?.isCoverBgColor || 'color');
      formik.setFieldValue('coverBgColor', newData?.coverBgColor || '#000000');
      formik.setFieldValue(
        'coverBgImage',
        newData.coverBgImage ? getImageURL(newData.coverBgImage) : null
      );
      formik.setFieldValue('mainPage', newData?.mainPage || []);
      formik.setFieldValue('profile', newData?.profile || []);

      dispatch(
        updateMiniSite({
          title: newData?.title,
          subHeading: newData?.subHeading,
          logo: newData?.logo ? getImageURL(newData?.logo) : null,
          isCoverBgColor: newData?.isCoverBgColor || 'color',
          coverBgColor: newData?.coverBgColor || '#000000',
          coverBgImage: newData?.coverBgImage ? getImageURL(newData?.coverBgImage) : null,
          mainPage: newData?.mainPage,
          profile: newData?.profile,
          currentTabValue: currentTab,
          companyId: newData?.companyId,
        })
      );
    } else {
      if (logoFile) {
        const file = await fetch(logoFile)
          .then(r => r.blob())
          .then(blobFile => new File([blobFile], 'LogoFile', {type: 'image/png'}));
        formik.setFieldValue('logoFile', {file});
      }
      if (coverBgImageFile) {
        const file = await fetch(coverBgImageFile)
          .then(r => r.blob())
          .then(blobFile => new File([blobFile], 'coverBgImageFile', {type: 'image/png'}));
        formik.setFieldValue('coverBgImageFile', {file});
      }
      setCurrentTab(currentTabValueData);
      handleTabChange(currentTabValueData);
      formik.setFieldValue('title', title);
      formik.setFieldValue('subHeading', subHeading);
      formik.setFieldValue('isCoverBgColor', isCoverBgColor || 'color');
      formik.setFieldValue('coverBgColor', coverBgColor || '#000000');
      formik.setFieldValue('logo', logoFile || logo || null);
      formik.setFieldValue('coverBgImage', coverBgImageFile || coverBgImage || null);
      formik.setFieldValue('mainPage', JSON.parse(JSON.stringify([...mainPage])));
      formik.setFieldValue('profile', JSON.parse(JSON.stringify([...profile])));
    }
  }, [newData]);

  return (
    <>
      <RichTextModal
        open={isOpen}
        formik={formik}
        index={typeIndex}
        tab={currentTabValue}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <SlidersModal
        open={isOpenSliders}
        formik={formik}
        index={typeIndex}
        delSlideChip={delSlideChip}
        sliders={formik.values[currentTabValue][typeIndex]?.slider}
        tab={currentTabValue}
        images={gallery}
        errors={formik.errors[currentTabValue]}
        handleSelectedImages={handleSelectedImages}
        onClose={() => {
          setIsOpenSliders(false);
        }}
      />
      <Typography variant="h6" sx={{mb: 1}}>
        Company Mini Site Layout
      </Typography>
      <Paper sx={{p: 2, minHeight: '70vh'}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            {' '}
            <Title formik={formik} isLoading={isLoading} />
            <SubHeading formik={formik} isLoading={isLoading} />
          </Stack>
          <Logo formik={formik} isLoading={isLoading} />
          <MiniSiteCover formik={formik} isLoading={isLoading} />

          <Dropdown handleClick={handleChange} buttonClick={buttonClick} isLoading={isLoading} />
          <Tabs
            value={currentTab}
            handleTabChange={handleTabChange}
            isLoading={isLoading}
            mainPage={
              <Box key="mainPage">
                {formik.values?.mainPage?.length ? (
                  <SortableTypes
                    handleRichTextModal={handleRichTextModal}
                    handleSlidersModal={handleSlidersModal}
                    delChip={delChip}
                    formik={formik}
                    key="mainPage"
                    tab="mainPage"
                    errors={formik.errors.mainPage}
                  />
                ) : null}
                {!!formik.errors.mainPage?.length && (
                  <Typography variant="body2" color="error">
                    Fields Are Required
                  </Typography>
                )}
              </Box>
            }
            profile={
              <Box key="profile">
                {formik.values?.profile?.length ? (
                  <SortableTypes
                    handleRichTextModal={handleRichTextModal}
                    handleSlidersModal={handleSlidersModal}
                    delChip={delChip}
                    formik={formik}
                    key="profile"
                    tab="profile"
                    errors={formik.errors.profile}
                  />
                ) : null}
                {!!formik.errors.profile?.length && (
                  <Typography variant="body2" color="error">
                    Fields Are Required
                  </Typography>
                )}
              </Box>
            }
          />
        </Stack>
        <Stack
          p={2}
          spacing={1}
          direction="row"
          sx={{
            justifyContent: 'right',
            alignItems: 'right',
          }}
        >
          {isLoading ? (
            <Skeleton
              variant="h1"
              sx={{
                width: '100%',
                height: '37px',
              }}
            />
          ) : (
            <>
              <Button
                disabled={isSubmitting}
                variant="outlined"
                color="inherit"
                sx={{
                  minWidth: '100px',
                }}
                onClick={() => {
                  if (newData) {
                    formik.setFieldValue('title', newData?.title || '');
                    formik.setFieldValue('subHeading', newData?.subHeading || '');
                    formik.setFieldValue('logo', newData.logo ? getImageURL(newData.logo) : null);
                    formik.setFieldValue('mainPage', newData?.mainPage || []);
                    formik.setFieldValue('profile', newData?.profile || []);
                    dispatch(
                      updateMiniSite({
                        title: newData?.title,
                        subHeading: newData?.subHeading,
                        logo: newData?.logo ? getImageURL(newData?.logo) : null,
                        mainPage: newData?.mainPage,
                        profile: newData?.profile,
                        currentTabValue: currentTab,
                        companyId: newData?.companyId,
                      })
                    );
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                endIcon={isSubmitting ? <CircularProgress ml={2} size={16} /> : null}
                disabled={isSubmitting || !formik.dirty}
                color="primary"
                variant="contained"
                sx={{
                  minWidth: '100px',
                }}
                onClick={() => formik.handleSubmit()}
              >
                Save
              </Button>
            </>
          )}
        </Stack>
      </Paper>
    </>
  );
}

export default MiniSieForm;
