import {ListItem, Skeleton} from '@mui/material';
import React from 'react';
import {ReactSortable} from 'react-sortablejs';
import TypeCard from './TypeCard';

function SortableTypes({
  formik,
  delChip,
  handleRichTextModal,
  handleSlidersModal,
  key,
  tab,
  isLoading,
  errors,
}) {
  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="h1"
          sx={{
            width: '100%',
            height: '37px',
          }}
        />
      ) : (
        <ReactSortable
          style={{width: '100%'}}
          list={JSON.parse(JSON.stringify([...formik.values[tab]]))}
          setList={newOrder => {
            const newSortedTypes = newOrder.map(data => {
              const newData = {...data};
              delete newData.chosen;
              delete newData.selected;
              return newData;
            });
            formik.setFieldValue(tab, newSortedTypes);
          }}
          key={key}
        >
          {formik.values[tab]?.length > 0 &&
            formik.values[tab].map((type, index) => (
              <ListItem component="div" key={type}>
                <TypeCard
                  delChip={delChip}
                  handleRichTextModal={handleRichTextModal}
                  handleSlidersModal={handleSlidersModal}
                  object={type}
                  index={index}
                  key={key}
                  tab={tab}
                  error={errors?.[index]}
                />
              </ListItem>
            ))}
        </ReactSortable>
      )}
    </>
  );
}

export default SortableTypes;
