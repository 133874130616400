import Dummy from 'components/Dummy';
import InnerLayout from 'components/Layout/InnerLayout';
import {FeaturedProductsLists} from 'features/featured-products-lists/routes/FeaturedProductsLists';
import {Banners} from 'features/banners/routes/Banners';
import {NewsFeeds} from 'features/newsFeeds/routes/NewsFeeds';
import {HomePageLayout} from 'features/home-page-layout/routes/HomePageLayout';
import {FeaturedCategories} from 'features/feature-categories/routes/FeaturedCategories';
import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

function Marketing() {
  return (
    <InnerLayout
      links={[
        {to: 'featured-product-list', title: 'Featured Product List'},
        {to: 'featured-banners', title: 'Banner Images'},
        {to: 'promoted-feeds', title: 'Promoted Feeds'},
        {
          to: 'featured-categories',
          title: 'Featured Categories',
        },
        {to: 'home-page-layout', title: 'Home Page Layout'},
        {to: 'news-feeds', title: 'News Feeds'},
      ]}
    >
      <Routes>
        <Route index element={<Navigate to="featured-product-list" replace />} />
        <Route path="featured-product-list" element={<FeaturedProductsLists />} />
        <Route path="featured-banners" element={<Banners />} />
        <Route path="home-page-layout" element={<HomePageLayout />} />
        <Route path="featured-categories" element={<FeaturedCategories />} />
        <Route path="news-feeds" element={<NewsFeeds />} />
        <Route path="*" element={<Dummy />} />
      </Routes>
    </InnerLayout>
  );
}

export default Marketing;
