import {TextField, Skeleton} from '@mui/material';
import React from 'react';

function Title({formik, isLoading}) {
  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="h1"
          sx={{
            width: '100%',
            height: '37px',
          }}
        />
      ) : (
        <TextField
          fullWidth
          size="small"
          id="title"
          label="Title"
          name="title"
          variant="outlined"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.title && formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      )}
    </>
  );
}

export default Title;
